import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, ValidationErrors, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { InterventionsService } from '../../../../pages/purges/interventions/interventions.service';
import { InterventionPurgeWorkTeamStatus, InterventionStatus } from '../../../../shared/enums';
import { ChangeInterventionStatusViewModel, ChangeInterventionStatusWorkTeamViewModel } from '../interventions.models';

@Component({
	selector: 'change-intervention-status-dialog',
	templateUrl: './change-intervention-status-dialog.component.html',
	styleUrls: ["./change-intervention-status-dialog.component.scss"]
})

export class ChangeInterventionStatusDialogComponent
{
	// I/O
	@Output() submitCallback: EventEmitter<any> = new EventEmitter();

	// input data
	public inputData: { id: number };

	// variables
	public interventionData: ChangeInterventionStatusViewModel;

	// form
	public form: FormGroup = this.fb.group(
		{
			id: [null, [Validators.required]],
			interventionStatusId: [null, [Validators.required]],
			cancelNotes: [null],
			offerCode: [null],
			scheduling: [new Date()],

			// work teams
			interventionWorkTeams: this.fb.array([])
		}
	);
	get interventionStatusId() { return this.form.get('interventionStatusId'); }
	get cancelNotes() { return this.form.get('cancelNotes'); }
	get offerCode() { return this.form.get('offerCode'); }
	get scheduling() { return this.form.get('scheduling'); }
	get interventionWorkTeams() { return this.form.get('interventionWorkTeams') as UntypedFormArray; }

	// constructor
	constructor
		(
			private interventionsService: InterventionsService,
			private pcodeServices: PCodeServices,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef,
			private fb: FormBuilder
		)
	{

	}

	// init
	ngOnInit()
	{
		this.loadInterventionData();
	}

	// load intervention data
	loadInterventionData()
	{
		this.interventionsService.getChangeInterventionStatus(this.inputData.id).subscribe(result =>
		{
			// intervention data
			this.interventionData = result;

			// load children activities
			this.interventionWorkTeams.clear();
			this.interventionData.interventionWorkTeams.forEach((item, index) =>
			{
				this.interventionWorkTeams.push(this.createInterventionWorkTeam(item));
			});

			// patching
			this.form.patchValue(result);

			// check: sopralluogo
			if (this.interventionData.interventionTypeId === 3)
				this.offerCode.setValue('Codice offerta: ');

			// trigger intervention status change
			this.interventionStatusChange();
		});
	}

	// change intervention status
	changeInterventionStatus()
	{
		this.form.markAllAsTouched();

		// check: form is valid
		if (this.form.valid)
		{
			this.interventionsService.changeInterventionStatus(this.form.value).subscribe(() =>
			{
				// notification
				this.pcodeServices.notify('success', 'Stato dell\'intervento aggiornato con successo');

				// emit callback
				this.submitCallback.emit();

				// hide modal
				this.hideModal();
			});
		}
		else
		{
			this.getFormValidationErrors();
		}
	}

	// create intervention work team item
	createInterventionWorkTeam(interventionWorkTeam: ChangeInterventionStatusWorkTeamViewModel): FormGroup
	{
		const result = this.fb.group(
			{
				id: [interventionWorkTeam.id, [Validators.required]],
				lead: [interventionWorkTeam.lead],
				interventionPurgeWorkTeamStatusId: [interventionWorkTeam.interventionPurgeWorkTeamStatusId, [Validators.required]],
				cancelNotes: [interventionWorkTeam.cancelNotes],
				teamLeaderOperatorFullName: [interventionWorkTeam.teamLeaderOperatorFullName]
			});

		return result;
	}

	// intervention status change
	interventionStatusChange()
	{
		if (this.interventionStatusId.value === InterventionStatus.Canceled && this.interventionWorkTeams.controls.length === 0)
			this.cancelNotes.setValidators([Validators.required]);
		else
		{
			this.cancelNotes.setValue(null);
			this.cancelNotes.removeValidators([Validators.required]);
		}
		this.cancelNotes.updateValueAndValidity();

		this.interventionWorkTeams.controls.forEach(control =>
		{
			control.get('interventionPurgeWorkTeamStatusId').setValue(null);
		})
	}

	// intervention purge work team status change
	interventionPurgeWorkTeamStatusChange(event: MatSelectChange, index: number)
	{
		const currentPurgeWorkTeam = this.interventionWorkTeams.controls.at(index);

		if (event.value === InterventionPurgeWorkTeamStatus.Canceled)
			currentPurgeWorkTeam.get('cancelNotes').setValidators([Validators.required]);
		else
			currentPurgeWorkTeam.get('cancelNotes').removeValidators([Validators.required]);
	}

	// get form validation errors
	getFormValidationErrors()
	{
		Object.keys(this.form.controls).forEach(key =>
		{
			const controlErrors: ValidationErrors = this.form.get(key).errors;
			if (controlErrors != null)
			{
				Object.keys(controlErrors).forEach(keyError =>
				{
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}