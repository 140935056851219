import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { BuildingAdministratorSelectorInput } from './selector.models';

@Component({
	selector: 'buildingadministrator-selector',
	templateUrl: './selector.component.html'
})

export class BuildingAdministratorsSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('businessName') businessName: ElementRef;

	// I/O
	@Output() buildingAdministratorSelectionCallback: EventEmitter<any> = new EventEmitter();

	// input filter
	public inputFilters: BuildingAdministratorSelectorInput;

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable = false;

	public _inputFilters: any;

	public _searchForm: FormGroup = this.fb.group(
		{
			businessName: ['']
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get building administrators list
	getList()
	{
		this._showTable = false;

		const _filters = $.extend({}, this._searchForm.value, this._inputFilters);

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 10,
			Url: this._baseUrl + 'api/registries/buildingAdministrators/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Ragione&nbsp;sociale', data: 'businessName', className: 'all' },
					{ title: 'Citta\'', data: 'townName', className: 'min-tablet', orderable: false },
					{ title: 'Telefono', data: 'phoneNumber', className: 'min-tablet', orderable: false },
					{ title: 'Cellulare', data: 'mobilePhoneNumber', className: 'min-tablet', orderable: false },
					{ title: 'Fax', data: 'faxNumber', className: 'none', orderable: false },
					{ title: 'E-mail', data: 'email', className: 'none', orderable: false },
					{ title: 'PEC', data: 'pecEmail', className: 'none', orderable: false },
					{
						title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo amministratore" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.buildingAdministratorSelectionCallback.emit({
						id: data['id'],
						businessName: data['businessName'],
						townName: data['townName'],
						phoneNumber: data['phoneNumber'],
						mobilePhoneNumber: data['mobilePhoneNumber'],
						faxNumber: data['faxNumber'],
						email: data['email'],
						pecEmail: data['pecEmail']
					});
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.businessName.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search employee
	search()
	{
		this.getList();
	}
}