import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAlertsCount, UserAlert } from './alerts.models';

@Injectable()
export class AlertsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get user alerts count
	public getUserAlertsCount(): Observable<UserAlertsCount>
	{
		return this.http.get<UserAlertsCount>(this.baseUrl + 'api/shared/alerts/count');
	}

	// get user alerts
	public getUserAlerts(): Observable<UserAlert[]>
	{
		return this.http.get<UserAlert[]>(this.baseUrl + 'api/shared/alerts/list');
	}

	// acknowledgment
	public acknowledgment(alertId: string)
	{
		return this.http.post(this.baseUrl + `api/shared/alerts/acknowledgment/${alertId}`, null);
	}
}