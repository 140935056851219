import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { PurgeNotePurgeWorkTeamStatus } from '../../../../shared/enums';
import { ChangePurgeNoteStatusViewModel, ChangePurgeNoteStatusWorkTeamViewModel } from '../purgenotes.models';
import { PurgeNotesService } from '../purgenotes.service';

@Component({
	selector: 'change-purge-note-status-dialog',
	templateUrl: './change-purge-note-status-dialog.component.html',
	styleUrls: ["./change-purge-note-status-dialog.component.scss"]
})

export class ChangePurgeNoteStatusDialogComponent
{
	// I/O
	@Output() submitCallback: EventEmitter<any> = new EventEmitter();

	// input data
	public inputData: { id: number };

	// variables
	public purgeNoteData: ChangePurgeNoteStatusViewModel;

	// form
	public form: FormGroup = this.fb.group(
		{
			id: [null, [Validators.required]],
			purgeNoteStatusId: [null, [Validators.required]],
			offerCode: [null],
			scheduling: [new Date()],

			// work teams
			purgeNoteWorkTeams: this.fb.array([])
		}
	);
	get purgeNoteStatusId() { return this.form.get('purgeNoteStatusId'); }
	get offerCode() { return this.form.get('offerCode'); }
	get scheduling() { return this.form.get('scheduling'); }
	get purgeNoteWorkTeams() { return this.form.get('purgeNoteWorkTeams') as UntypedFormArray; }

	// constructor
	constructor
		(
			private purgeNotesService: PurgeNotesService,
			private pcodeServices: PCodeServices,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef,
			private fb: FormBuilder,
			private cd: ChangeDetectorRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.loadPurgeNoteData();
	}

	// load purge note data
	loadPurgeNoteData()
	{
		this.purgeNotesService.getChangePurgeNoteStatus(this.inputData.id).subscribe(result =>
		{
			// purge note data
			this.purgeNoteData = result;

			// load children activities
			this.purgeNoteWorkTeams.clear();
			this.purgeNoteData.purgeNoteWorkTeams.forEach((item, index) =>
			{
				this.purgeNoteWorkTeams.push(this.createPurgeNoteWorkTeam(item));
			});

			// patching
			this.form.patchValue(result);
		});
	}

	// change purge note status
	changePurgeNoteStatus()
	{
		this.form.markAllAsTouched();

		// check: form is valid
		if (this.form.valid)
		{
			this.purgeNotesService.changePurgeNoteStatus(this.form.value).subscribe(() =>
			{
				// notification
				this.pcodeServices.notify('success', 'Stato della nota di lavoro aggiornato con successo');

				// emit callback
				this.submitCallback.emit();

				// hide modal
				this.hideModal();
			});
		}
		else
		{
			this.getFormValidationErrors();
		}
	}

	// create purge note work team item
	createPurgeNoteWorkTeam(purgeNoteWorkTeam: ChangePurgeNoteStatusWorkTeamViewModel): FormGroup
	{
		const result = this.fb.group(
			{
				id: [purgeNoteWorkTeam.id, [Validators.required]],
				lead: [purgeNoteWorkTeam.lead],
				purgeNotePurgeWorkTeamStatusId: [purgeNoteWorkTeam.purgeNotePurgeWorkTeamStatusId, [Validators.required]],
				cancelNotes: [purgeNoteWorkTeam.cancelNotes],
				teamLeaderOperatorFullName: [purgeNoteWorkTeam.teamLeaderOperatorFullName]
			});

		return result;
	}

	// purge note status change
	purgeNoteStatusChange()
	{
		this.purgeNoteWorkTeams.controls.forEach(control =>
		{
			control.get('purgeNotePurgeWorkTeamStatusId').setValue(null);
		})
	}

	// purge note purge work team status change
	purgeNotePurgeWorkTeamStatusChange(event: MatSelectChange, index: number)
	{
		const currentPurgeWorkTeam = this.purgeNoteWorkTeams.controls.at(index);

		if (event.value === PurgeNotePurgeWorkTeamStatus.Canceled)
			currentPurgeWorkTeam.get('cancelNotes').setValidators([Validators.required]);
		else
			currentPurgeWorkTeam.get('cancelNotes').removeValidators([Validators.required]);

		this.cd.detectChanges();
	}

	// get form validation errors
	getFormValidationErrors()
	{
		Object.keys(this.form.controls).forEach(key =>
		{
			const controlErrors: ValidationErrors = this.form.get(key).errors;
			if (controlErrors != null)
			{
				Object.keys(controlErrors).forEach(keyError =>
				{
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}