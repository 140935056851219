import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { Article } from '../articletypes.models';
import { ArticleTypesService } from '../articletypes.service';

@Component({
	selector: 'new-article-parts-modal',
	templateUrl: './new-article-parts-modal.component.html',
	styleUrls: ['./new-article-parts-modal.component.scss']
})

export class CatalogNewArticlePartsModalComponent implements OnDestroy
{
	// viewchildren
	@ViewChild('newArticleModal') newArticleModal;
	@ViewChild('rfidInput', { static: false }) rfidInputElement: ElementRef;
	@ViewChild('formStepper', { static: false }) formStepper: CdkStepper;

	// I/O
	@Input() public articleTypeId: number;
	@Input() public articleId?: number;
	@Output() modalCloseCallback: EventEmitter<boolean> = new EventEmitter();

	// variables
	public createModal = false;
	public article: Article;
	public articleConditions: any[];
	public articleStatus: any[];

	// rfid
	public rfidScanning = false;
	private rfidScanInterval;
	private rfidScanPause = false;

	// form
	public form: FormGroup = this.fb.group(
		{
			// step 1
			step1: this.fb.group(
				{
					id: [null],
					location: [null],
					receivedDate: [new Date(), [Validators.required]],
					catalogArticleConditionId: [1, [Validators.required]],
					catalogArticleStatusId: [1, [Validators.required]],
					stock: [null, [Validators.required]],
					notes: [null],

					attributes: this.fb.array([])
				}),

			// step 2
			step2: this.fb.group(
				{
					rfidInput: [null],
					readItems: this.fb.array([])
				}),

			// step 3
			step3: this.fb.group(
				{
					parts: this.fb.array([])
				})
		}
	);
	// step 1
	get formStep1() { return this.form.get('step1'); }
	get formStep1_id() { return this.form.get('step1.id'); }
	get formStep1_location() { return this.form.get('step1.location'); }
	get formStep1_receivedDate() { return this.form.get('step1.receivedDate'); }
	get formStep1_catalogArticleConditionId() { return this.form.get('step1.catalogArticleConditionId'); }
	get formStep1_catalogArticleStatusId() { return this.form.get('step1.catalogArticleStatusId'); }
	get formStep1_stock() { return this.form.get('step1.stock'); }
	get formStep1_notes() { return this.form.get('step1.notes'); }
	get formStep1_attributes() { return this.form.get('step1.attributes') as UntypedFormArray; }
	// step 2
	get formStep2_rfidInput() { return this.form.get('step2.rfidInput'); }
	get formStep2_readItems() { return this.form.get('step2.readItems') as UntypedFormArray; }
	get formStep3_parts() { return this.form.get('step3.parts') as UntypedFormArray; }

	// constructor
	constructor
		(
			private pcodeServices: PCodeServices,
			private articleTypesService: ArticleTypesService,
			private fb: FormBuilder,
			public datepipe: DatePipe,
			private cd: ChangeDetectorRef
		)
	{
	}

	// destroy
	ngOnDestroy(): void
	{
		// destroy modal
		this.createModal = false;

		// stop eventual active read
		if (this.rfidScanning)
			this.stopRfidScan();
	}

	// show modal
	showModal()
	{
		// reset
		this.article = null;

		// show modal content
		this.createModal = true;
		this.cd.detectChanges();

		// get conditions
		const getArticleObs = this.articleTypesService.getArticle(this.articleTypeId, this.articleId ?? 0);
		const getArticleConditionsObs = this.articleTypesService.getAllConditions();
		const getArticleStatusObs = this.articleTypesService.getAllStatus();

		forkJoin([getArticleObs, getArticleConditionsObs, getArticleStatusObs]).subscribe(results =>
		{
			// current/new article
			this.article = results[0];
			this.formStep1.patchValue(this.article);
			this.formStep1_notes.setValue(null);

			// new article: set default values
			if (!this.articleId)
			{
				this.formStep1_receivedDate.setValue(new Date());
				this.formStep1_catalogArticleConditionId.setValue(1);
				this.formStep1_catalogArticleStatusId.setValue(1);
			}

			this.articleConditions = results[1];
			this.articleStatus = results[2];

			this.clearFormArray(this.formStep1_attributes);
			this.article.attributes.forEach(attribute =>
			{
				this.formStep1_attributes.push(
					this.fb.group(
						{
							id: [attribute.id],
							name: [attribute.name],
							catalogAttributeId: [attribute.catalogAttributeId],
							catalogAttributeTypeId: [attribute.catalogAttributeTypeId],

							elementValueId: [attribute.elementValueId],
							stringValue: [attribute.stringValue],
							numericValue: [attribute.numericValue],
							datetimeValue: [attribute.datetimeValue],
							booleanValue: [attribute.booleanValue],
							elements: [attribute.elements]
						})
				);
			});

			// update form validators
			this.updateFormValidators();

			// show modal
			this.newArticleModal.show();
		});
	}

	// close modal
	closeModal()
	{
		// destroy modal
		this.createModal = false;

		// stop eventual active read
		if (this.rfidScanning)
			this.stopRfidScan();

		this.newArticleModal.hide()
	}

	// step 1
	confirmStep1()
	{
		this.formStep1.markAllAsTouched();

		console.log(this.formStep1.value);

		if (this.formStep1.valid)
			this.formStepper.next();
	}

	// step 2
	stepChange(event: StepperSelectionEvent)
	{
		// stop eventual active read
		if (this.rfidScanning)
			this.stopRfidScan();

		switch (event.selectedIndex)
		{
			// basic form
			case 0:
				{

				}
				break;

			// read from external device
			case 1:
				{
					// reset step
					this.form.get('step2').reset();
					this.clearFormArray(this.formStep2_readItems);

					switch (this.article.catalogArticleClassificationTypeId)
					{
						// attrezzatura
						case 1:
							{
								// N parts by step1 stock field
								for (let i = 0; i < this.formStep1_stock.value; i++)
									this.formStep2_readItems.push(this.fb.group({ valid: [true] }));

								// reset step
								this.form.get('step3').reset();
								this.clearFormArray(this.formStep3_parts);

								this.addReadItemsToSummary();
							}
							break;

						// qr code
						case 2:
							{
								// N parts by step1 stock field
								for (let i = 0; i < this.formStep1_stock.value; i++)
									this.formStep2_readItems.push(this.fb.group({ valid: [true] }));

								// reset step
								this.form.get('step3').reset();
								this.clearFormArray(this.formStep3_parts);

								this.addReadItemsToSummary();
							}
							break;

						// rfid code
						case 3:
							{
								// start scanning
								this.startRfidScan();
							}
							break;
					}
				}
				break;

			// summary and confirm
			case 2:
				{
					// reset step
					this.form.get('step3').reset();
					this.clearFormArray(this.formStep3_parts);

					this.addReadItemsToSummary();
				}
				break;
		}
	}

	// add read items to summary
	addReadItemsToSummary()
	{
		// adding read codes to step3 form
		this.formStep2_readItems.controls.forEach(item =>
		{
			// check: only valid codes
			if (item.get('valid').value === true)
			{
				// create new article part item
				var newArticlePartItem = this.fb.group(
					{
						catalogArticleId: [this.articleId, [Validators.required]],
						catalogArticleConditionId: [this.formStep1_catalogArticleConditionId.value, [Validators.required]],
						catalogArticleStatusId: [this.formStep1_catalogArticleStatusId.value, [Validators.required]],
						location: [this.formStep1_location.value],
						rfidCode: [item.get('rfidCode') ? item.get('rfidCode').value : null],
						notes: [this.formStep1_notes.value],
						receivedDate: [this.datepipe.transform(this.formStep1_receivedDate.value, 'yyyy-MM-dd')]
					});

				// adding to step3 formarray
				this.formStep3_parts.push(newArticlePartItem);
			}
		})
	}
	startRfidScan()
	{
		// reset
		this.resetRfidScan();

		this.rfidScanning = true;
		this.cd.detectChanges();

		// start scan
		this.rfidScanInterval = setInterval(() =>
		{
			// focus on input element
			this.rfidInputElement.nativeElement.focus();

			this.readRfidInput();
		}, 500);
	}
	stopRfidScan()
	{
		console.log('Lettura RFID interrotta.');

		clearInterval(this.rfidScanInterval);
		this.rfidScanning = false;

		this.formStepper.next();
	}
	readRfidInput()
	{
		if (!this.rfidScanPause)
		{
			console.log('Lettura RFID in corso...');

			// set rfid read codes
			var readCodes = this.formStep2_rfidInput.value ?
				(
					this.formStep2_rfidInput.value as string)
					.split(/\r?\n|\r|\n/g)
					.filter(x => x !== '' && x.length === 24)
					.filter(this.uniqueFilter)
					.sort((x, y) => (x > y ? 1 : -1)) :
				[];

			this.rfidScanPause = true;

			readCodes.forEach(rfidCode =>
			{
				// check: article item already read
				if (!this.formStep2_readItems.controls.find(x => x.get('rfidCode').value === rfidCode))
				{
					this.formStep2_readItems.push(
						this.fb.group(
							{
								rfidCode: [rfidCode, [Validators.required]],
								valid: [null],
								existingArticleType: [null]
							})
					);
				}
			});

			this.rfidScanPause = false;

			// check: rfid codes validation
			this.formStep2_readItems.controls.forEach(item =>
			{
				if (item.get('valid').value === null)
				{
					// check: rfid code already exists
					this.articleTypesService.rfidCodeAlreadyExists(item.get('rfidCode').value).subscribe(result =>
					{
						item.get('valid').setValue(!result['exists']);
						item.get('existingArticleType').setValue(result['articleTypeName']);
					});
				}
			});
		}
	}
	rfidInputBlur(e)
	{
		if (e.relatedTarget === null)
			e.target.focus();
	}
	resetRfidScan()
	{
		this.rfidScanning = false;
		this.clearFormArray(this.formStep2_readItems);
		this.clearFormArray(this.formStep3_parts);
	}

	// step 3
	articlesBulkInsert()
	{
		this.form.get('step3').markAllAsTouched();

		// check: form validation
		if (this.form.get('step3').valid && confirm('Confermare l\'inserimento di questi articoli?'))
		{
			var _postForm =
			{
				...{ parts: this.formStep3_parts.value }
			};

			this.articleTypesService.articlesPartsBulkInsert(_postForm).subscribe(result =>
			{
				this.pcodeServices.notify('success', 'Parti caricate a magazzino');

				this.modalCloseCallback.emit(true);

				this.closeModal();
			});
		}
	}
	getArticleStatusName(id?: number)
	{
		if (id)
			return this.articleStatus.find(x => x.id === id).name;
		return '';
	}
	getArticleConditionName(id?: number)
	{
		if (id)
			return this.articleConditions.find(x => x.id === id).name;
		return '';
	}

	// utils
	updateFormValidators()
	{
		// reset
		this.formStep1_stock.enable();

		// check: classification type
		switch (this.article.catalogArticleClassificationTypeId)
		{
			// attrezzatura
			case 1:
				{
					this.formStep1_stock.setValue(1);
					this.formStep1_stock.disable();
				}
				break;

			// qr code
			case 2:
				{

				}
				break;

			// rfid code
			case 3:
				{
					this.formStep1_stock.disable();
				}
				break;
		}
	}
	getElementValueName(attributeControl: AbstractControl)
	{
		var result = (attributeControl.get('elements').value as any[]).find(x => x.id === attributeControl.get('elementValueId').value);

		if (result)
			return result.name;

		return '';
	}
	uniqueFilter(value, index, self)
	{
		return self.indexOf(value) === index;
	}
	clearFormArray = (formArray: UntypedFormArray) =>
	{
		while (formArray.length !== 0)
			formArray.removeAt(0)
	}
};