<!--working group list modal-->
<div class="modal fade" bsModal #listModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title float-left">
					<i class="zmdi zmdi-wrench m-r-10"></i>
					Seleziona gruppo di lavoro
				</h4>
				<button type="button" class="close pull-right" aria-label="Close" (click)="listModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">

				<!--filters-->
				<div class="pc-form-filters">
					<form [formGroup]="_searchForm" (ngSubmit)="search()">
						<div class="row">
							<div class="col-lg-3">
								<mat-form-field>
									<mat-label>Operatore / Collaboratore</mat-label>
									<input type="text" matInput formControlName="operator" #autofocus />
								</mat-form-field>
							</div>
							<div class="col-lg-3">
								<mat-form-field>
									<mat-label>Automezzo</mat-label>
									<input type="text" matInput formControlName="vehicle" />
								</mat-form-field>
							</div>
							<div class="col d-flex align-items-center">
								<button type="submit" mat-raised-button color="accent">
									<mat-icon>search</mat-icon>
									<span>{{'Commons.Actions.Search' | translate}}</span>
								</button>
							</div>
						</div>
					</form>
				</div>

				<!--results-->
				<div class="table-responsive">
					<table datatable *ngIf="_showTable" [dtOptions]="_dtOptions" class="table row-border hover"></table>
				</div>

			</div>
		</div>
	</div>
</div>