import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Article, ArticleAssignment, AttributeListItem, CatalogArticlePart, CatalogArticleType, IdentifiedArticle, MultipleAssociationArticleDetails } from './articletypes.models';

@Injectable()
export class ArticleTypesService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get single
	public getSingle(id: number): Observable<CatalogArticleType>
	{
		return this.http.get<CatalogArticleType>(this.baseUrl + 'api/technologicalresources/articlesTypes/getSingle?Id=' + id);
	}

	// update
	public insertUpdate(operation: any)
	{
		return this.http.post(this.baseUrl + 'api/technologicalresources/articlesTypes/insertUpdate', operation);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/technologicalresources/articlesTypes/delete?id=' + id);
	}

	// get all catalog categories
	public getAllCatalogCategories(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllCatalogCategories');
	}

	// get all catalog categories
	public getAllArticleTypes(categoryId?: number): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllArticleTypes?categoryId=' + categoryId);
	}

	// get all catalog maintenance types
	public getAllCatalogMaintenanceTypes(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllCatalogMaintenanceTypes');
	}

	// insert maintenance operator
	public insertMaintenanceOperator(articleId: number, operatorId: number)
	{
		return this.http.post(this.baseUrl + 'api/technologicalresources/articlesTypes/insertMaintenanceOperator', { articleId: articleId, operatorId: operatorId });
	}

	// delete maintainer
	public deleteMaintenanceOperator(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/technologicalresources/articlesTypes/deleteMaintenanceOperator?id=' + id);
	}

	// get operations list
	public getOperationsList(articleId: number)
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getOperations?articleId=' + articleId);
	}

	// get article operations list
	public getArticleOperationsList(articleId: number)
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getArticleOperations?articleId=' + articleId);
	}

	// get article
	public getArticle(articleTypeId: number, articleId: number)
	{
		return this.http.get<Article>(this.baseUrl + 'api/technologicalresources/articlesTypes/getArticle/' + articleTypeId + '/' + articleId);
	}

	// update
	public insertUpdateArticle(articleTypeId: number, articleType: any): Observable<number>
	{
		return this.http.post<number>(this.baseUrl + 'api/technologicalresources/articlesTypes/insertUpdateArticle/' + articleTypeId, articleType);
	}

	// delete article
	public deleteArticle(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/technologicalresources/articlesTypes/deleteArticle?id=' + id);
	}

	// get all catalog categories
	public getAllStatus(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllStatus');
	}

	// get all catalog maintenance types
	public getAllConditions(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllConditions');
	}

	// get attributes list
	public getAttributesList(articleTypeId: number): Observable<AttributeListItem[]>
	{
		return this.http.get<AttributeListItem[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAttributes?articleTypeId=' + articleTypeId);
	}

	// update attributes
	public updateAttributes(attributes: AttributeListItem[])
	{
		return this.http.post(this.baseUrl + 'api/technologicalresources/articlesTypes/updateAttributes', { attributesList: attributes });
	}

	// update attachment
	public updateAttachment(Model: any)
	{
		return this.http.post(this.baseUrl + 'api/technologicalresources/articlesTypes/updateAttachment', Model);
	}

	// get by action alias
	public getByActionAlias(actionAlias: string, alphabeticalOrder: boolean = false): Observable<Array<any>>
	{
		return this.http.get<Array<any>>(this.baseUrl + 'api/configurations/fileTypes/GetByActionAlias?actionAlias=' + actionAlias + '&alphabeticalOrder=' + alphabeticalOrder);
	}

	// export pdf
	public exportPdf(filters)
	{
		return this.http.post(this.baseUrl + `api/technologicalresources/articlesmaintenances/exportPdf`, filters, { responseType: 'blob' });
	}

	// get single maintenance operations
	public getSingleMaintenanceOperations(id: number)
	{
		return this.http.get(this.baseUrl + `api/technologicalresources/articlesmaintenances/getSingleMaintenanceOperations/${id}`);
	}

	// export
	public export(params: any)
	{
		return this.http.post(this.baseUrl + 'api/technologicalresources/articlescalibrations/export', params, { responseType: 'blob' });
	}

	// upload article type photo
	public uploadPhoto(fileToUpload: File, articleTypeId: number): Observable<any>
	{
		const _formData: FormData = new FormData();
		_formData.append('file', fileToUpload, fileToUpload.name);

		let _headers = new HttpHeaders();
		_headers.append('Content-Type', 'multipart/form-data');
		_headers.append('Accept', 'application/json');

		return this.http.post<any>(this.baseUrl + 'api/technologicalresources/articlesTypes/uploadPhoto?articleTypeId=' + articleTypeId, _formData, { headers: _headers });
	}

	// delete photo
	public deletePhoto(articleTypeId: number)
	{
		return this.http.delete(this.baseUrl + 'api/technologicalresources/articlesTypes/deletePhoto?articleTypeId=' + articleTypeId);
	}

	// download QrCode
	public downloadQrCode(articleTypeId: number)
	{
		return this.http.get(this.baseUrl + 'api/technologicalresources/articlesTypes/downloadQrCode?articleId=' + articleTypeId, { responseType: 'blob' });
	}

	// check rfid code already exists
	public rfidCodeAlreadyExists(code: string)
	{
		return this.http.get(this.baseUrl + `api/technologicalresources/articlesTypes/rfidCodeAlreadyExists?rfidCode=${code}`);
	}

	// article parts bulk insert
	public articlesPartsBulkInsert(model: any)
	{
		return this.http.post(this.baseUrl + `api/technologicalresources/articlesTypes/articlesPartsBulkInsert`, model);
	}

	// get article parts count
	public getArticlePartsCount(catalogArticleId: number): Observable<number>
	{
		return this.http.get<number>(this.baseUrl + `api/technologicalresources/articlesTypes/getArticlePartsCount/${catalogArticleId}`);
	}

	// get article part
	public getArticlePart(id: number): Observable<CatalogArticlePart>
	{
		return this.http.get<CatalogArticlePart>(this.baseUrl + `api/technologicalresources/articlesTypes/getArticlePart/${id}`);
	}

	// update article part
	public updateArticlePart(model): Observable<CatalogArticlePart>
	{
		return this.http.post<CatalogArticlePart>(this.baseUrl + `api/technologicalresources/articlesTypes/updateArticlePart`, model);
	}

	// assign article part
	public assignArticlePart(catalogArticlePartId: number, employeeId: number)
	{
		return this.http.post(this.baseUrl + `api/technologicalresources/articlesTypes/assignArticlePart`, { catalogArticlePartId: catalogArticlePartId, employeeId: employeeId });
	}

	// remove article part assignation
	public removeArticlePartAssignation(catalogArticlePartId: number)
	{
		return this.http.post(this.baseUrl + `api/technologicalresources/articlesTypes/removeArticlePartAssignation/${catalogArticlePartId}`, null);
	}

	// delete article part
	public deleteArticlePart(id: number)
	{
		return this.http.delete(this.baseUrl + `api/technologicalresources/articlesTypes/deleteArticlePart/${id}`);
	}

	// identify resource
	public identifyResource(qrCode: string, rfidCode: string): Observable<IdentifiedArticle>
	{
		return this.http.post<IdentifiedArticle>(this.baseUrl + `api/technologicalresources/articlesTypes/identifyResource`, { qrCode: qrCode, rfidCode: rfidCode });
	}

	// get multiple assignment article details
	public getMultipleAssignmentArticleDetails(articleTypeId: number): Observable<MultipleAssociationArticleDetails>
	{
		return this.http.get<MultipleAssociationArticleDetails>(this.baseUrl + 'api/technologicalresources/articlesTypes/getMultipleAssignmentArticleDetails/' + articleTypeId);
	}

	// article part multiple assignment
	public articlePartsMultipleAssignment(model)
	{
		return this.http.post(this.baseUrl + `api/technologicalresources/articlesTypes/articlePartsMultipleAssignment`, model);
	}
}