import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class OfficesService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get single
	public getSingle(id: number)
	{
		return this.http.get(this.baseUrl + 'api/registries/offices/getSingle?Id=' + id);
	}

	// insert/update
	public insertUpdate(office)
	{
		return this.http.post(this.baseUrl + 'api/registries/offices/insertUpdate', office);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/registries/offices/delete?Id=' + id);
	}

	// update attachment
	public getAttachments(model)
	{
		return this.http.post(this.baseUrl + 'api/registries/offices/getAttachments', model);
	}

	// update attachment
	public updateAttachment(model)
	{
		return this.http.post(this.baseUrl + 'api/registries/offices/updateAttachment', model);
	}
}