import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class pCodeDateAdapter extends NativeDateAdapter
{
	constructor(@Inject(LOCALE_ID) public locale: string)
	{
		super();
	}

	getFirstDayOfWeek(): number
	{
		return 1;
	}

	parse(value: any): Date | null
	{
		if (this.locale === 'it')
		{
			if ((typeof value === 'string') && (value.indexOf('/') > -1))
			{
				const str = value.split('/');

				const year = Number(str[2]);
				const month = Number(str[1]) - 1;
				const date = Number(str[0]);

				return new Date(year, month, date);
			}
			const timestamp = typeof value === 'number' ? value : Date.parse(value);
			return isNaN(timestamp) ? null : new Date(timestamp);
		}

		return super.parse(value);
	}

	format(date: Date, displayFormat: Object): string
	{
		if (this.locale === 'it')
		{
			date = new Date(Date.UTC(
				date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
				date.getMinutes(), date.getSeconds(), date.getMilliseconds()));

			displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });

			const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
			return dtf.format(date).replace(/[\u200e\u200f]/g, '');
		}
		else
		{
			return super.format(date, displayFormat);
		}
	}
}