<div class="modal fade" bsModal #catalogArticleLogsModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" style="min-width:1400px; max-width:98%;">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title float-left">
					<i class="zmdi zmdi-time-restore m-r-10"></i>
					<span>Storico operazioni risorse tecnologiche</span>
				</h4>
			</div>
			<div class="modal-body">

				<!--search filters-->
				<div class="pc-form-filters">
					<form [formGroup]="_searchForm" (ngSubmit)="search()">
						<div class="row">
							<div class="col-lg-3">
								<mat-form-field>
									<mat-label>Periodo</mat-label>
									<mat-date-range-input [rangePicker]="insertDatePicker">
										<input matStartDate placeholder="gg/mm/aaaa" formControlName="startDate" (dateChange)="onDateChange('startDate', $event)" />
										<input matEndDate placeholder="gg/mm/aaaa" formControlName="endDate" (dateChange)="onDateChange('endDate', $event)" />
									</mat-date-range-input>
									<mat-datepicker-toggle matIconSuffix [for]="insertDatePicker"></mat-datepicker-toggle>
									<mat-date-range-picker touchUi="true" #insertDatePicker></mat-date-range-picker>
								</mat-form-field>
							</div>
							<div class="col-lg-2">
								<mat-form-field>
									<mat-label>Operazione</mat-label>
									<mat-select formControlName="operationAlias">
										<mat-option>Tutte...</mat-option>
										<mat-option [value]="'INSERT'">Inserimento</mat-option>
										<mat-option [value]="'UPDATE'">Modifica</mat-option>
										<mat-option [value]="'DELETE'">Eliminazione</mat-option>
										<mat-option [value]="'ADD_ASSIGNATION'">Assegnazione</mat-option>
										<mat-option [value]="'REMOVE_ASSIGNATION'">Rimozione assegnazione</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-lg-2">
								<mat-form-field>
									<mat-label>Dipendente</mat-label>
									<input type="text" matInput formControlName="employeeFullName" />
								</mat-form-field>
							</div>
							<div class="col-lg-2">
								<mat-form-field>
									<mat-label>Utente</mat-label>
									<input type="text" matInput formControlName="userFullName" />
								</mat-form-field>
							</div>
							<div class="col d-flex align-items-center">
								<button type="submit" mat-raised-button color="accent">
									<mat-icon>search</mat-icon>
									<span>{{'Commons.Actions.Search' | translate}}</span>
								</button>
								<button type="button" mat-icon-button class="ml-4" (click)="resetFilter()" matTooltip="Rimuovi tutti i filtri" matTooltipPosition="above">
									<mat-icon>undo</mat-icon>
								</button>
							</div>
						</div>
					</form>
				</div>

				<!--table-->
				<div class="table-responsive">
					<table datatable *ngIf="_showTable" [dtOptions]="_dtOptions" class="table row-border hover"></table>
				</div>

			</div>
			<div class="modal-footer">
				<button type="button" mat-button class="mr-2" (click)="catalogArticleLogsModal.hide()">
					{{'Commons.Actions.Close' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>