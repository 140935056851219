<div class="modal fade" bsModal #newArticleModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true" [config]="{ backdrop: 'static' }" *ngIf="createModal">
	<div class="modal-dialog" style="width: 1200px; max-width:98%;">
		<div class="modal-content" *ngIf="article">

			<div class="modal-header">
				<h4 class="modal-title float-left">
					<i class="zmdi zmdi-playlist-plus m-r-10"></i>
					<span>
						Caricamento magazzino&nbsp;-&nbsp;
						<ng-container *ngIf="article.catalogArticleClassificationTypeId === 1">Attrezzatura</ng-container>
						<ng-container *ngIf="article.catalogArticleClassificationTypeId === 2">Codice QR</ng-container>
						<ng-container *ngIf="article.catalogArticleClassificationTypeId === 3">Codice RFID</ng-container>
					</span>
				</h4>
			</div>

			<div class="modal-body">

				<form [formGroup]="form">

					<!--stepper-->
					<mat-stepper #formStepper [linear]="true" (selectionChange)="stepChange($event)" class="scanner_stepper">

						<!--step 1: basic info-->
						<mat-step formGroupName="step1">

							<!--step title-->
							<ng-template matStepLabel>Anagrafica</ng-template>

							<!--intro panel-->
							<div class="alert alert-info" *ngIf="!articleId">
								Inserisci le informazioni comuni a tutti gli articoli in creazione.
							</div>

							<div class="row">

								<!--left column-->
								<div class="col-md-6">

									<!--registry-->
									<div>
										<h4 class="m-t-20 m-l-10">Anagrafica</h4>
										<div class="form_container">

											<!--receive date-->
											<div class="form-group">
												<mat-form-field>
													<mat-label>Data ricevimento</mat-label>
													<input matInput [matDatepicker]="receivedDatePicker" formControlName="receivedDate" />
													<mat-datepicker-toggle matSuffix [for]="receivedDatePicker"></mat-datepicker-toggle>
													<mat-datepicker touchUi="true" #receivedDatePicker></mat-datepicker>
													<mat-error *ngIf="formStep1_receivedDate.errors?.required && formStep1_receivedDate.touched">
														{{'Commons.Validation.Required' | translate : { field: "Data ricevimento" } }}
													</mat-error>
												</mat-form-field>
											</div>

											<!--condition-->
											<div class="form-group">
												<mat-form-field>
													<mat-label>Condizioni</mat-label>
													<mat-select class="block" formControlName="catalogArticleConditionId">
														<mat-option *ngFor="let condition of articleConditions" [value]="condition.id">
															{{ condition.name }}
														</mat-option>
													</mat-select>
													<mat-error *ngIf="formStep1_catalogArticleConditionId.errors?.required && formStep1_catalogArticleConditionId.touched">
														{{'Commons.Validation.Required' | translate : { field: "Condizioni" } }}
													</mat-error>
												</mat-form-field>
											</div>

											<!--status-->
											<div class="form-group">
												<mat-form-field>
													<mat-label>Stato</mat-label>
													<mat-select class="block" formControlName="catalogArticleStatusId">
														<mat-option *ngFor="let status of articleStatus" [value]="status.id">
															{{ status.name }}
														</mat-option>
													</mat-select>
													<mat-error *ngIf="formStep1_catalogArticleStatusId.errors?.required && formStep1_catalogArticleStatusId.touched">
														{{'Commons.Validation.Required' | translate : { field: "Stato" } }}
													</mat-error>
												</mat-form-field>
											</div>

											<!--location-->
											<div class="form-group">
												<mat-form-field>
													<mat-label>Collocazione</mat-label>
													<input type="text" matInput formControlName="location" rows="4" maxlength="256" />
												</mat-form-field>
											</div>

											<!--notes-->
											<div class="form-group">
												<mat-form-field>
													<mat-label>Note</mat-label>
													<textarea type="text" matInput formControlName="notes" rows="4" maxLength="1000"></textarea>
												</mat-form-field>
											</div>

										</div>

									</div>

								</div>

								<!--right column-->
								<div class="col-md-6">

									<!--stock: qrcode-->
									<ng-container *ngIf="article.catalogArticleClassificationTypeId === 1 || article.catalogArticleClassificationTypeId === 2">
										<h4 class="m-t-20 m-l-10">Quantità</h4>
										<div class="form_container">
											<div class="form-group">
												<mat-form-field>
													<mat-label>Indicare la quantità di articoli in caricamento</mat-label>
													<input type="number" min="1" max="100" step="1" matInput formControlName="stock" appAutofocus />
													<mat-error *ngIf="formStep1_stock.errors?.required && formStep1_stock.touched">
														{{'Commons.Validation.Required' | translate : { field: "Quantità" } }}
													</mat-error>
												</mat-form-field>
											</div>
										</div>
									</ng-container>

									<!--attributes-->
									<div *ngIf="formStep1_attributes['controls'].length > 0">
										<h4 class="m-t-20 m-l-10">Attributi</h4>
										<div class="form_container">
											<ul class="attributes_list" *ngIf="article && formStep1_attributes['controls'].length > 0">
												<li formArrayName="attributes" *ngFor="let attribute of formStep1_attributes['controls']; let i = index;">
													<ng-container [formGroupName]="i">
														<strong>{{attribute.get('name').value}}:</strong>

														<!--switch attribute type-->
														<ng-container [ngSwitch]="attribute.get('catalogAttributeTypeId').value">

															<!--text-->
															<span *ngSwitchCase="1">{{attribute.get('stringValue').value}}</span>

															<!--textarea-->
															<span *ngSwitchCase="2">{{attribute.get('stringValue').value}}</span>

															<!--numeric-->
															<span *ngSwitchCase="3">{{attribute.get('numericValue').value}}</span>

															<!--select-->
															<span *ngSwitchCase="4">{{getElementValueName(attribute)}}</span>

															<!--boolean-->
															<span *ngSwitchCase="5">{{(attribute.get('numericValue').value === true ? 'Sì' : 'No')}}</span>

															<!--datetime-->
															<span *ngSwitchCase="6">{{attribute.get('datetimeValue').value | date:"dd/MM/yyyy"}}</span>

														</ng-container>

													</ng-container>
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>

							<!--step actions-->
							<div class="actions_box">

								<!--cancel-->
								<button type="button" mat-button class="mr-2" (click)="closeModal()">
									<mat-icon class="m-r-10">close</mat-icon>
									<span>Annulla</span>
								</button>

								<!--confirm-->
								<button type="button" mat-raised-button color="accent" class="ml-auto" (click)="confirmStep1()" [class.disabled]="!form.get('step1').valid">
									<mat-icon class="m-r-10">arrow_forward</mat-icon>
									<span>Avanti</span>
								</button>
							</div>

						</mat-step>

						<!--step 2: read-->
						<mat-step formGroupName="step2" *ngIf="article.catalogArticleClassificationTypeId === 3">

							<ng-container></ng-container>

							<!--step title-->
							<ng-template matStepLabel>Acquisizione RFID</ng-template>

							<!--step content-->
							<ng-container *ngIf="rfidScanning">

								<!--total read count-->
								<div class="mb-3">
									<div class="reading_alert">
										<mat-icon class="blink">radio_button_checked</mat-icon>
										<span>Acquisizione in corso, posiziona gli articoli per la lettura...</span>
										<div class="reading_alert_count">
											{{formStep2_readItems.controls.length}}
										</div>
									</div>
								</div>

								<!--legend-->
								<ul class="read_articles_legend" *ngIf="formStep2_readItems['controls'].length > 0">
									<li>
										<mat-icon>label</mat-icon>
										<span>Nuova etichetta</span>
									</li>
									<li>
										<mat-icon class="invalid">label</mat-icon>
										<span>Etichetta già caricata</span>
									</li>
								</ul>

								<!--read articles-->
								<ul class="read_articles_list" *ngIf="formStep2_readItems['controls'].length > 0">
									<li formArrayName="readItems" *ngFor="let readItem of formStep2_readItems['controls']; let index = index;"
										 [title]="readItem.get('existingArticleType').value ? ('Articolo già esistente in: ' + readItem.get('existingArticleType').value) : 'Nuovo articolo'"
										 [class.invalid]="readItem.get('valid').value === false"
										 [class.check]="readItem.get('valid').value === null">
										<div [formGroupName]="index">
											<mat-icon *ngIf="readItem.get('valid').value !== null">label</mat-icon>
											<mat-icon class="rotating" *ngIf="readItem.get('valid').value === null">sync</mat-icon>
											<span>{{readItem.get('rfidCode').value}}</span>
										</div>
									</li>
								</ul>

								<!--rfid input-->
								<textarea #rfidInput formControlName="rfidInput" rows="5"
											 (blur)="rfidInputBlur($event)"
											 class="rfid_input">
								</textarea>

							</ng-container>

							<!--step actions-->
							<div class="actions_box">

								<!--back-->
								<button type="button" mat-button class="mr-2" matStepperPrevious>
									<mat-icon class="m-r-10">arrow_back</mat-icon>
									<span>Indietro</span>
								</button>

								<!--cancel-->
								<button type="button" mat-button class="mr-2" (click)="closeModal()">
									<mat-icon class="m-r-10">close</mat-icon>
									<span>Annulla</span>
								</button>

								<!--stop scan-->
								<button type="button" mat-raised-button color="accent" class="ml-auto" (click)="stopRfidScan()" *ngIf="rfidScanning"
										  [class.disabled]="!form.get('step2').valid"
										  [disabled]="formStep2_readItems.controls.length === 0">
									Termina scansione
								</button>

							</div>

						</mat-step>

						<!--step 3: summary and confirm-->
						<mat-step formGroupName="step3">

							<!--step title-->
							<ng-template matStepLabel>Riepilogo e conferma</ng-template>

							<!--step content-->
							<div>

								<div class="alert alert-warning" *ngIf="formStep3_parts['controls'].length === 0">
									Nessuna parte da importare
								</div>

								<h3 *ngIf="formStep3_parts['controls'].length > 0">
									{{formStep3_parts['controls'].length}} part{{formStep3_parts['controls'].length > 1 ? 'i' : 'e'}} in caricamento
								</h3>

								<!--read articles-->
								<ul class="edit_articles_list">
									<li formArrayName="parts" *ngFor="let formPart of formStep3_parts['controls']; let index = index;">

										<!--index-->
										<div class="index">{{(1 + index)}}</div>

										<!--details-->
										<div class="row" [formGroupName]="index">
											<ul class="edit_articles_attributes">

												<!--registry-->
												<li *ngIf="article.catalogArticleClassificationTypeId === 3">
													<strong>RFID: </strong>
													<span>{{formPart.get('rfidCode').value}}</span>
												</li>
												<li *ngIf="formStep1_receivedDate.value">
													<strong>Data ricevimento: </strong>
													<span>{{formStep1_receivedDate.value | date:"dd/MM/yyyy"}}</span>
												</li>
												<li>
													<strong>Condizioni: </strong>
													<span>{{getArticleConditionName(formStep1_catalogArticleConditionId.value)}}</span>
												</li>
												<li>
													<strong>Stato: </strong>
													<span>{{getArticleStatusName(formStep1_catalogArticleStatusId.value)}}</span>
												</li>
												<li>
													<strong>Collocazione: </strong>
													<span>{{formStep1_location.value}}</span>
												</li>
												<li>
													<strong>Note: </strong>
													<span>{{formStep1_notes.value}}</span>
												</li>

												<!--attributes-->
												<li class="attribute_item" *ngFor="let attribute of formStep1_attributes['controls']; let i = index;">
													<strong>{{attribute.get('name').value}}: </strong>
													<ng-container [ngSwitch]="attribute.get('catalogAttributeTypeId').value">
														<span *ngSwitchCase="1">{{attribute.get('stringValue').value}}</span>
														<span *ngSwitchCase="2">{{attribute.get('stringValue').value}}</span>
														<span *ngSwitchCase="3">{{attribute.get('numericValue').value}}</span>
														<span *ngSwitchCase="4">{{getElementValueName(attribute)}}</span>
														<span *ngSwitchCase="5">{{attribute.get('booleanValue').value ? 'Sì' : 'No'}}</span>
														<span *ngSwitchCase="6">{{attribute.get('datetimeValue').value | date:"dd/MM/yyyy"}}</span>
													</ng-container>
												</li>

											</ul>

										</div>
									</li>
								</ul>

							</div>

							<!--step actions-->
							<div class="actions_box">

								<!--back-->
								<button type="button" mat-button class="mr-2" matStepperPrevious>
									<mat-icon class="m-r-10">arrow_back</mat-icon>
									<span>Indietro</span>
								</button>

								<!--cancel-->
								<button type="button" mat-button class="mr-2" (click)="closeModal()">
									<mat-icon class="m-r-10">close</mat-icon>
									<span>Annulla</span>
								</button>

								<!--start scan-->
								<button type="button" mat-raised-button color="accent" class="ml-auto" (click)="articlesBulkInsert()"
										  [class.disabled]="!form.get('step3').valid"
										  [disabled]="formStep3_parts['controls'].length === 0">
									<mat-icon class="m-r-10">done_all</mat-icon>
									<span>Conferma e inserisci parti</span>
								</button>

							</div>

						</mat-step>

					</mat-stepper>
				</form>
			</div>
		</div>
	</div>
</div>