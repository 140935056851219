import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';

@Component({
	selector: 'employee-selector',
	templateUrl: './selector.component.html'
})

export class EmployeeSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('modalAutofocusRef') modalAutofocusRef: ElementRef;

	// I/O
	@Output() employeeSelectionCallback: EventEmitter<any> = new EventEmitter();

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean = false;

	// input filter
	public inputFilters: any;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			firstLastName: [''],
			businessAreaId: [[]]
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get employee list
	getList()
	{
		this._showTable = false;

		const _filters = $.extend({}, this._searchForm.value, this.inputFilters);

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 5,
			Url: this._baseUrl + 'api/humanresources/employees/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Cognome', data: 'lastName', className: 'all' },
					{ title: 'Nome', data: 'firstName', className: 'all' },
					{ title: 'Matricola', data: 'badgeNumber', width: '20', className: 'min-tablet' },
					{
						title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo dipendente" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.employeeSelectionCallback.emit({
						id: data['id'],
						firstName: data['firstName'],
						lastName: data['lastName'],
						adrExpirationDate: data['adrExpirationDate']
					});
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.modalAutofocusRef.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search employee
	search()
	{
		this.getList();
	}
}