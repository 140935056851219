import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DigifranTimeLine } from './timeline.models';

@Component({
	selector: 'timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.scss']
})

export class DigifranTimeLineComponent implements OnInit
{
	@Input() timeline: DigifranTimeLine;
	@Input() eventTemplate: TemplateRef<any>;

	// constructor
	constructor()
	{

	}

	// init
	ngOnInit(): void
	{

	}
}