<!--title-->
<h2 mat-dialog-title>
	<mat-icon class="mr-3">new_releases</mat-icon>
	<span>Avvisi</span>
	<mat-icon class="ml-3">new_releases</mat-icon>
</h2>

<!--content-->
<mat-dialog-content>

	<ng-container *ngIf="userAlerts && userAlerts.length > 0">

		<!--list-->
		<ng-container *ngIf="!activeAlert">
			<ul class="alerts-list">
				<li *ngFor="let alert of userAlerts">
					<div>
						<h3>{{alert.title}}</h3>
						<small>
							<mat-icon>arrow_right_alt</mat-icon>
							{{alert.insertDate | date: "dd/MM/yyyy HH:mm"}}
						</small>
					</div>
					<button type="button" (click)="showAlertDetails(alert)" mat-ripple class=" btn btn-primary">
						Dettagli
					</button>
				</li>
			</ul>
		</ng-container>
		
		<!--alert details-->
		<ng-container *ngIf="activeAlert">
			<div class="alert-details-container">
				<h3>{{activeAlert.title}}</h3>
				<p>{{activeAlert.description}}</p>
				<div class="mt-5 text-right">
					<button type="button" mat-button class="mr-2" (click)="hideAlertDetails()">
						<mat-icon class="mr-2">clear</mat-icon>
						<span>Ricorda più tardi</span>
					</button>
					<button type="button" mat-raised-button color="accent" (click)="acknowledgment(activeAlert)">
						<mat-icon class="mr-2">done</mat-icon>
						<span>Presa visione</span>
					</button>
				</div>
			</div>
			
		</ng-container>
	</ng-container>

	<!--no alerts-->
	<ng-container *ngIf="userAlerts && userAlerts.length === 0">
		<p class="mt-2 text-center">
			Nessun avviso
		</p>
	</ng-container>

</mat-dialog-content>

<mat-dialog-actions *ngIf="!activeAlert">
	<button type="button" mat-button mat-dialog-close>
		<mat-icon class="mr-2">clear</mat-icon>
		<span>Chiudi</span>
	</button>
</mat-dialog-actions>