<div class="card-header card-default">
    <ul class="actions top-left">
        <li>
            <mat-checkbox (click)="toggleTask($event)" [(ngModel)]="task.isDone"
                          *ngIf="!toggleTaskProcessing"></mat-checkbox>
            <i class="zmdi-hc-li zmdi zmdi-refresh zmdi-hc-spin" *ngIf="toggleTaskProcessing"></i>
        </li>
    </ul>
    <ul class="actions icons top-right">
        <li>
            <a href="javascript:void(0)" (click)="deleteTask()"><i class="material-icons">delete_outline</i></a>
        </li>
    </ul>
</div>
<div class="card-block">
    <span [ngClass]="task.isDone ? 'task-done' : 'task'" (click)="enableEditing()" *ngIf="!editable"
          style="cursor:pointer;">
        {{task.name}}
    </span>
    <form novalidate *ngIf="editable" [formGroup]="form" (submit)="editName(form)">
        <div class="form-group"
             [ngClass]="form.get('updatedTask').hasError('required') && isSubmitted ? 'has-danger' : ''">
            <input type="text" class="form-control input-sm" id="task" placeholder="Add a task"
                   formControlName="updatedTask" (blur)="disableEditing()" focus />
            <div class="form-control-feedback" *ngIf="form.get('updatedTask').hasError('required') && isSubmitted">
                Task
                name is required
            </div>
        </div>
    </form>
</div>
