import { Component, OnInit } from '@angular/core';
import { DarkSkyResult } from './weather.models';
import { WeatherService } from './weather.service';

@Component({
	selector: 'local-forecast',
	templateUrl: './weather.component.html',
	styleUrls: ["./weather.component.scss"],
})
export class WeatherComponent implements OnInit
{
	public currentDate: Date = null;
	public weatherInfo: DarkSkyResult = null;

	constructor(private weather: WeatherService)
	{
		this.currentDate = new Date();
	}

	ngOnInit()
	{
		//if (navigator.geolocation)
		//{
		//	navigator.geolocation.getCurrentPosition(position =>
		//	{
		//		this.getForecast(position.coords.latitude, position.coords.longitude);
		//	});
		//}
		//else
		//{
		//	this.getForecast(45.464203, 9.189982);
		//}
	}

	getForecast(lat: number, lng: number)
	{
		this.weather.getCurrentForecast(lat, lng).subscribe((result: DarkSkyResult) =>
		{
			this.weatherInfo = result;
		});
	}

	getWeatherIcon(icon)
	{
		switch (icon)
		{
			case 'clear-day':
				return 'wi-day-sunny'
			case 'clear-night':
				return 'wi-night-clear'
			case 'rain':
				return 'wi-day-rain'
			case 'snow':
				return 'wi-snow'
			case 'sleet':
				return 'wi-sleet'
			case 'wind':
				return 'wi-strong-wind'
			case 'fog':
				return 'wi-fog'
			case 'cloudy':
				return 'wi-cloudy'
			case 'partly-cloudy-day':
				return 'wi-day-cloudy'
			case 'partly-cloudy-night':
				return 'wi-night-alt-cloudy'
			default:
				return `wi-day-sunny`
		}
	}

	getCelsius(fahrenheit: number): number
	{
		return (fahrenheit - 32) / 1.8;
	}
}