import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PurgeNoteRegistry, PurgeNoteTimeLineEvent, PurgeNoteWorkTeamTimeLine, PurgeNoteWorkTeamTimeLineEvent } from '../purgenotes.models';
import { PurgeNotesService } from '../purgenotes.service';

@Component({
	selector: 'purge-note-logs-dialog',
	templateUrl: './purge-note-logs-dialog.component.html',
	styleUrls: ["./purge-note-logs-dialog.component.scss"]
})

export class PurgeNoteLogsDialogComponent
{
	// input data
	public inputData: { id: number };

	// variables
	public purgeNoteRegistry: PurgeNoteRegistry;
	public purgeNoteTimeline: PurgeNoteWorkTeamTimeLine;
	public showRegistryLogs = false;

	// constructor
	constructor
		(
			private purgeNotesService: PurgeNotesService,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.loadPurgeNoteTimeline();
	}

	// load purge note timeline
	loadPurgeNoteTimeline()
	{
		this.showRegistryLogs = false;

		this.purgeNotesService.getPurgeNoteLogs(this.inputData.id).subscribe(result =>
		{
			this.purgeNoteRegistry = result;

			this.purgeNoteTimeline = new PurgeNoteWorkTeamTimeLine();
			this.purgeNoteTimeline.options =
			{
				dateFormat: 'dd/MM/yy HH:mm:ss'
			};

			// events data
			this.purgeNoteRegistry.purgeNoteLogs.forEach(log =>
			{
				const digifranTimeLineEvents = new PurgeNoteTimeLineEvent();

				// base properties
				digifranTimeLineEvents.dateTime = log.insertDate;
				digifranTimeLineEvents.icon = log.purgeNoteLogActionIcon;
				digifranTimeLineEvents.iconColor = log.purgeNoteLogActionIconColor;

				// custom properties
				digifranTimeLineEvents.title = log.purgeNoteLogActionTitle;
				digifranTimeLineEvents.description = log.purgeNoteLogActionDescription;
				digifranTimeLineEvents.message = log.description;
				digifranTimeLineEvents.user = log.insertUserFullName;

				this.purgeNoteTimeline.events.push(digifranTimeLineEvents);
			});

			this.showRegistryLogs = true;
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}