import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { DarkSkyResult } from './weather.models';
import { Observable } from 'rxjs';

@Injectable()
export class WeatherService
{
	// variables
	readonly ROOT_URL = '';

	// constructor
	constructor(private _http: HttpClient, private router: Router, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get current forecast
	public getCurrentForecast(lat: number, lng: number): Observable<DarkSkyResult>
	{
		const _urlParts = this.router.url.split('/');
		const _area = _urlParts[1] || null;
		const _controller = _urlParts[2] || null;
		const _userLang = localStorage.getItem("user_lang") || 'it';

		const _httpOptions = {
			params: new HttpParams()
				.set('CurrentArea', _area)
				.set('CurrentController', _controller)
		};

		return this._http.get<DarkSkyResult>(`${this.baseUrl}api/shared/shared/GetLocalWeather?Latitude=${lat}&Longitude=${lng}&Lang=${_userLang}`, _httpOptions);
	}
}