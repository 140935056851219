import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { PCodeServices } from '../../../../../../app/shared/services/pcode/pcode.service';


@Component({
	selector: 'customer-offer-selector',
	templateUrl: './selector.component.html'
})

export class CustomersOffersSelectorComponent
{
	@ViewChild('listModal') listModal;
	@ViewChild('customerBusinessName') customerBusinessName: ElementRef;

	@Output() customerOfferSelectionCallback: EventEmitter<any> = new EventEmitter();

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable = false;

	public _modalTitle = "Seleziona offerta cliente"
	public _checkBlacklist = true;

	public _searchForm: FormGroup = this.fb.group(
		{
			businessName: [null]
		}
	);
	get businessName() { return this._searchForm.get('businessName'); }

	constructor(
		@Inject('BASE_URL') private _baseUrl: string,
		private pcodeServices: PCodeServices,
		private fb: FormBuilder,
		public datepipe: DatePipe
	)
	{

	}

	showModal(customTitle?: string)
	{
		// custom title
		if (customTitle)
			this._modalTitle = customTitle;

		this.getList();
	}

	// get customers list
	getList()
	{
		this._showTable = false;

		const _filters =
		{
			customer: this.businessName.value || null,
		}

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 10,
			Url: this._baseUrl + 'api/offers/customersoffers/getList',
			Order: [2, 'desc'],
			Columns:
				[
					{
						title: 'Stato', data: 'offerStatusName', width: '20', className: 'sts-col all', render: (data, type, row) =>
						{
							switch (row['offerStatusId'])
							{
								case 1:
									return '<i class="material-icons m-r-10" style="color:#eeeeee;">radio_button_checked</i>';
								case 2:
									return '<i class="material-icons text-warning m-r-10">radio_button_checked</i>';
								case 3:
									return '<i class="material-icons text-success m-r-10">radio_button_checked</i>';
								case 4:
									return '<i class="material-icons text-danger m-r-10">radio_button_checked</i>';
								case 5:
									return '<i class="material-icons text-info m-r-10">radio_button_checked</i>';
							}

							return null;
						}
					},
					{ title: 'Tipologia', data: 'offerTypeName', width: '20', className: 'min-tablet' },
					{
						title: 'Data', data: 'date', width: '100', className: 'all', render: (data, type, row) =>
						{
							return this.datepipe.transform(data, 'dd/MM/yyyy')
						}
					},
					{
						title: 'Data&nbsp;scadenza', data: 'expirationDate', width: '100', orderable: false, className: 'min-tablet', render: (data, type, row) =>
						{
							if (data)
								return this.datepipe.transform(data, 'dd/MM/yyyy');

							return null;
						}
					},
					{ title: 'Numero', data: 'number', width: '20', className: 'all' },
					{
						title: 'Cliente', data: 'customerBusinessName', className: 'min-tablet', render: (data, type, row) =>
						{
							if (row['customerBusinessName'] === row['historicisedCustomer'] || !row['historicisedCustomer'])
								return data;
							else
								return `${data} (${row['historicisedCustomer']})`
						}
					},

					{ title: 'Descrizione', data: 'description', className: 'min-tablet' },
					{ title: 'Memo', data: 'memo', className: 'none' },
					{ title: 'Note finali', data: 'notes', className: 'none' },
					{
						title: 'Rinnovo', data: 'doNotRenew', className: 'none', render: (data, type, row) =>
						{
							return data ? 'Non rinnovare' : 'Rinnovare';
						}
					},
					{
						title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actionsList = '';

							// select
							actionsList += '<a href="#" data-action="select" title="Seleziona questa offerta" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actionsList;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.customerOfferSelectionCallback.emit({
						id: data['id'],
						customerBusinessName: data['customerBusinessName']
					});
					_self.listModal.hide();
				});
			},
			DrawCallback: (settings) =>
			{
				this.listModal.show();
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search customer
	search()
	{
		this.getList();
	}
}