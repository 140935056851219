import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AjaxLoaderService } from '../ajaxLoader/ajaxLoader.service';

@Injectable()
export class PCodeServices implements OnInit
{
	_accessToken: string;
	_urlParts: string[];
	_area?: string;
	_controller?: string;
	_httpOptions: {};

	// constructor
	constructor
		(
			private http: HttpClient,
			private translateService: TranslateService,
			private router: Router,
			private toastr: ToastrService,
			@Inject('BASE_URL') private baseUrl: string,
			private ajaxLoader: AjaxLoaderService
		)
	{

	}

	ngOnInit()
	{

	}

	// init datatable
	// ====================================================================================

	public initDatatable(TableConfig: TableConfig)
	{
		let _accessToken = sessionStorage.getItem("access_token");
		let _userLang = localStorage.getItem("user_lang") || 'it';

		// ajax loader
		this.ajaxLoader.show();

		let _dtOptions =
		{
			destroy: true,
			serverSide: true,
			processing: true,
			searching: false,
			lengthChange: false,
			responsive: true,
			language: { url: '/assets/translate/shared/datatables/' + _userLang + '.json' },
			pagingType: TableConfig.PaginationType || 'simple_numbers',
			pageLength: TableConfig.PageSize || 25,
			order: TableConfig.Order || [0, 'asc'],
			stateSave: TableConfig.StateSaving || false,
			displayStart: TableConfig.DisplayStart || 0,
			ajax:
			{
				url: TableConfig.Url,
				type: "POST",
				contentType: 'application/json; charset=utf-8',
				data: (data) => { return JSON.stringify((<any>Object).assign({}, data, TableConfig.Filters)); },
				headers: { Authorization: 'Bearer ' + _accessToken },
				error: (xhr, error, thrown) =>
				{
					// forbidden
					if (xhr.status == 403)
					{
						this.router.navigate(['forbidden']);
					}

					// server error
					if (xhr.status == 500)
					{
						this.ajaxLoader.hide();

						$('.dataTables_processing').hide();

						// server error custom callback
						if (TableConfig.ServerErrorCallback)
							TableConfig.ServerErrorCallback(xhr);
					}
				}
			},

			columns: TableConfig.Columns,
			rowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				// external callback
				if (TableConfig.RowCallback)
				{
					TableConfig.RowCallback(row, data, index);
				}
			},
			drawCallback: (settings) =>
			{
				// ajax loader
				this.ajaxLoader.hide();

				// custom paginator class
				var paginationElement = $(settings.nTableWrapper).find('.dataTables_paginate');
				if (paginationElement.length)
					paginationElement.closest('.row').addClass('table_pagination_container');

				// external callback
				if (TableConfig.DrawCallback)
				{
					TableConfig.DrawCallback(settings);
				}
			},
			preDrawCallback: (settings) =>
			{
				// external callback
				if (TableConfig.PreDrawCallback)
				{
					TableConfig.PreDrawCallback(settings);
				}
			}
		};

		return _dtOptions;
	}

	// session properties
	// ====================================================================================
	public getSessionProperties()
	{
		this.setAuthRequest();
		return this.http.get(this.baseUrl + 'api/shared/shared/getSessionProperties', this._httpOptions);
	}

	// notify
	// ====================================================================================
	public notify(type: string, message?: string, title?: string)
	{
		let _options: Partial<IndividualConfig> =
		{
			progressBar: false,
			tapToDismiss: true,
			timeOut: 6000
		}

		let _title = title;

		if (!_title)
		{
			switch (type)
			{
				case 'success': {
					_title = this.translateService.instant('Commons.Notifications.Success.Title');
					break;
				}
				case 'error': {
					_title = this.translateService.instant('Commons.Notifications.Error.Title');
					break;
				}
				case 'info': {
					_title = this.translateService.instant('Commons.Notifications.Info.Title');
					break;
				}
				case 'warning': {
					_title = this.translateService.instant('Commons.Notifications.Warning.Title');
					break;
				}
				default: {
					_title = this.translateService.instant('Commons.Notifications.Info.Title');
					break;
				}
			}
		}

		switch (type)
		{
			case 'success': {
				this.toastr.success(message, _title, _options);
				break;
			}
			case 'error': {
				this.toastr.error(message, _title, _options);
				break;
			}
			case 'info': {
				this.toastr.info(message, _title, _options);
				break;
			}
			case 'warning': {
				this.toastr.warning(message, _title, _options);
				break;
			}
			default: {
				this.toastr.info(message, _title, _options);
				break;
			}
		}
	}

	// has permission
	// ====================================================================================
	public hasPermission(permissionAlias: string): Observable<boolean>
	{
		this.setAuthRequest();
		return this.http.get<boolean>(this.baseUrl + 'api/shared/shared/HasPermission?PermissionAlias=' + permissionAlias, this._httpOptions);
	}

	// is sysadmnin
	// ====================================================================================
	public isSysAdmin()
	{
		this.setAuthRequest();
		return this.http.get<boolean>(this.baseUrl + 'api/shared/shared/IsSysAdmin', this._httpOptions);
	}

	// set auth request
	// ====================================================================================
	private setAuthRequest()
	{
		this._accessToken = sessionStorage.getItem("access_token");

		this._urlParts = this.router.url.split('/');
		this._area = this._urlParts[1] || null;
		this._controller = this._urlParts[2] || null;

		this._httpOptions = {
			params: new HttpParams()
				.set('CurrentArea', this._area)
				.set('CurrentController', this._controller)
		};
	}
}

export class TableConfig
{
	Url: string;
	Filters?: any;
	Columns: any[];
	Order?: Array<(number | string)> | Array<Array<(number | string)>>;

	RowCallback?: any;
	DrawCallback?: any;
	PreDrawCallback?: any;
	ServerErrorCallback?: any;

	PageSize?: number;
	PaginationType?: string;
	DisplayStart?: number;
	StateSaving?: boolean;
}

export class DataTablesResponse
{
	data: any[];
	draw: number;
	recordsFiltered: number;
	recordsTotal: number;
}

export interface DataTableResult<T>
{
	items: T[];
	totalCount: number;
}