
<!--select user model-->
<div class="modal fade" bsModal #userModelsModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content" *ngIf="_userModels">
			<div class="modal-header">
				<h4 class="modal-title float-left">
					<i class="zmdi zmdi-format-list-bulleted m-r-10"></i>
					Modelli di export
				</h4>
				<button type="button" class="close pull-right" aria-label="Close" (click)="userModelsModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<button mat-raised-button color="accent" class="btn-xs mb-3" (click)="editUserModel(null)">
					Crea nuovo modello
				</button>
				<table class="table dataTable row-border hover m-t-20" *ngIf="_userModels.length > 0">
					<thead>
						<tr>
							<th>Nome</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let _userModel of _userModels">
							<td>{{_userModel.name}}</td>
							<td class="adv-col">
								<button type="button" class="btn btn-danger btn-fab btn-flat btn-fab-sm mr-2" (click)="deleteUserModel(_userModel)" title="Elimina">
									<mat-icon>delete_outline</mat-icon>
								</button>
								<button type="button" class="btn btn-fab btn-flat btn-fab-sm mr-2" (click)="editUserModel(_userModel)" data-action="edit" title="Modifica">
									<mat-icon>edit</mat-icon>
								</button>
								<button type="button" class="btn btn-fab btn-flat btn-fab-sm" (click)="selectUserModel(_userModel)" data-action="select" title="Seleziona">
									<mat-icon>arrow_forward</mat-icon>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!--insert/update user model-->
<div class="modal fade" bsModal #insertUpdateUserModelModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content" *ngIf="_currentUserModel">
			<form [formGroup]="_userModelForm" (ngSubmit)="insertUpdateUserModel()" class="form-horizontal">
				<div class="modal-header">
					<h4 class="modal-title float-left">
						<i class="zmdi zmdi-format-list-bulleted m-r-10"></i>
						{{id.value == 0 ? 'Nuovo modello di export' : 'Modifica modello di export'}}
					</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="cancelEditUserModel()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">

					<input type="hidden" formControlName="id" required />

					<mat-form-field>
						<mat-label>Nome</mat-label>
						<input type="text" matInput formControlName="name" maxLength="128" />
						<mat-error *ngIf="name.errors?.required && name.touched">
							{{'Commons.Validation.Required' | translate : { field: "Nome" } }}
						</mat-error>
					</mat-form-field>

					<div>
						<mat-label class="mat-label-external">Tipologie di allegati</mat-label>
						<ul class="checkbox-list mt-3">
							<li *ngFor="let _fileType of fileTypes">
								<mat-checkbox value="{{_fileType.id}}" [checked]="_selectedFileTypes.indexOf(_fileType.id) > -1" (change)="toggleFileType($event)">
									{{_fileType.name}}
								</mat-checkbox>
							</li>
						</ul>
					</div>
					
				</div>
				<div class="modal-footer">
					<button type="button" mat-button class="mr-2" (click)="cancelEditUserModel()">
						Annulla
					</button>
					<button type="submit" mat-raised-button color="accent" [disabled]="!_userModelForm.valid">
						<mat-icon>done</mat-icon>
						Conferma
					</button>
				</div>
			</form>
		</div>
	</div>
</div>