import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UserAlert } from "../alerts.models";
import { AlertsService } from "../alerts.service";

@Component({
	selector: "digifran-alerts-dialog",
	templateUrl: "./alerts-dialog.component.html",
	styleUrls: ["./alerts-dialog.component.scss"]
})
export class AlertsDialogComponent implements OnInit
{
	// variables
	userAlerts: UserAlert[];
	activeAlert: UserAlert;

	// constructor
	constructor
		(
			private dialogRef: MatDialogRef<AlertsDialogComponent>,
			private alertsService: AlertsService
		)
	{

	}

	// init
	ngOnInit()
	{
		this.loadUserAlerts();
	}

	// load user alerts
	loadUserAlerts()
	{
		this.alertsService.getUserAlerts().subscribe(result =>
		{
			this.userAlerts = result;
			this.activeAlert = null;

			if (this.userAlerts.length === 1)
				this.showAlertDetails(this.userAlerts[0]);

			if (this.userAlerts.length === 0)
				this.closeDialog();
		});
	}

	// show alert details
	showAlertDetails(alert: UserAlert)
	{
		this.activeAlert = alert;
	}

	// hide active alert
	hideAlertDetails()
	{
		this.activeAlert = null;

		if (this.userAlerts.length === 1)
			this.closeDialog();
	}

	// acknowledgment
	acknowledgment(alert: UserAlert)
	{
		this.alertsService.acknowledgment(alert.id).subscribe(result =>
		{
			this.loadUserAlerts();
		});
	}

	// close dialog
	closeDialog()
	{
		this.dialogRef.close();
	}
}
