<ng-container *ngIf="interventionData">
	<form [formGroup]="form" (ngSubmit)="changeInterventionStatus()">
		<div class="modal-header">
			<h4 class="modal-title float-left">
				<i class="zmdi zmdi-swap m-r-10"></i>
				{{interventionData.customerBusinessName}}
			</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

			<alert type="warning">
				Attenzione! Lo stato viene aggiornato automaticamente dagli operatori sul campo.
				<br />Utilizzare questa funzionalità solamente per correggere errori imprevisti o involontari.
			</alert>

			<div class="mt-10">
				<strong>Stato attuale:</strong> {{interventionData.interventionStatusDescription}}
			</div>

			<div class="mt-30">

				<!--current intervention-->
				<ul class="workteams_list">
                    <li>

                        <!--status-->
                        <mat-form-field>
                            <mat-label>Stato intervento</mat-label>
                            <mat-select formControlName="interventionStatusId" (selectionChange)="interventionStatusChange()">
                                <mat-option [value]="1">
                                    <i class="zmdi zmdi-edit text-disabled m-r-10"></i>
                                    <span>Da programmare</span>
                                </mat-option>
                                <mat-option [value]="2" *ngIf="interventionData.interventionStatusId > 1">
                                    <i class="zmdi zmdi-time-interval text-warning m-r-10"></i>
                                    <span>Programmato</span>
                                </mat-option>
                                <mat-option [value]="5" *ngIf="interventionData.interventionStatusId > 1">
                                    <i class="zmdi zmdi-check-all text-success m-r-10"></i>
                                    <span>Completato</span>
                                </mat-option>
                                <mat-option [value]="6">
                                    <i class="zmdi zmdi-close text-danger m-r-10"></i>
                                    <span>Annullato</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!--cancel notes-->
                        <mat-form-field *ngIf="interventionStatusId.value === 6 && interventionWorkTeams['controls'].length === 0">
                            <mat-label>Note di annullamento</mat-label>
                            <input matInput formControlName="cancelNotes" />
                            <mat-error align="right" *ngIf="cancelNotes.errors?.required && cancelNotes.touched">
                                Le note di annullamento sono obbligatorie
                            </mat-error>
                        </mat-form-field>

                        <!--only 'sopralluogo'-->
                        <ng-container *ngIf="interventionData.interventionTypeId === 3">

                            <!--offer code-->
                            <mat-form-field>
                                <mat-label>Codice offerta</mat-label>
                                <input matInput formControlName="offerCode" />
                            </mat-form-field>

                            <!--scheduling date-->
                            <mat-form-field>
                                <mat-label>Data programmazione</mat-label>
                                <input matInput [matDatepicker]="changeStatusSchedulingDatePicker" placeholder="gg/mm/aaaa" formControlName="scheduling" />
                                <mat-datepicker-toggle matSuffix [for]="changeStatusSchedulingDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker touchUi="true" #changeStatusSchedulingDatePicker></mat-datepicker>
                            </mat-form-field>

                        </ng-container>

                    </li>
				</ul>

				<!--intervention purge work teams-->
				<div class="row">
					<div class="col-lg-12" *ngIf="interventionWorkTeams['controls'].length > 0">

						<p class="mt-20 mb-10">
							Seleziona il nuovo stato dei singoli flussi di lavoro delle squadre sul campo:
						</p>

						<ul class="workteams_list">
							<li formArrayName="interventionWorkTeams" *ngFor="let workTeam of interventionWorkTeams['controls']; let workTeamIndex = index;">
								<div [formGroupName]="workTeamIndex">
									<mat-form-field>
										<mat-label>
											<ng-container>{{workTeam.get('teamLeaderOperatorFullName').value}}&nbsp;-&nbsp;</ng-container>
											Squadra {{(workTeam.get('lead').value ? 'principale' : 'di supporto')}}
										</mat-label>
										<mat-select formControlName="interventionPurgeWorkTeamStatusId" (selectionChange)="interventionPurgeWorkTeamStatusChange($event, workTeamIndex)">

											<!--intervention status: da programmare-->
											<ng-container *ngIf="interventionStatusId.value === 1">
												<mat-option [value]="1">
													<i class="zmdi zmdi-edit text-disabled m-r-10"></i>
													<span>Da programmare</span>
												</mat-option>
												<mat-option [value]="2" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 2">
													<i class="zmdi zmdi-time-interval text-warning m-r-10"></i>
													<span>Programmato</span>
												</mat-option>
												<mat-option [value]="3" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 3">
													<i class="zmdi zmdi-truck text-info m-r-10"></i>
													<span>Attivo</span>
												</mat-option>
												<mat-option [value]="4" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 4">
													<i class="zmdi zmdi-check-all text-success m-r-10"></i>
													<span>Completato</span>
												</mat-option>
												<mat-option [value]="5" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 5">
													<i class="zmdi zmdi-close text-danger m-r-10"></i>
													<span>Annullato</span>
												</mat-option>
												<mat-option [value]="6" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 6">
													<i class="zmdi zmdi-time text-warning m-r-10"></i>
													<span>In pausa</span>
												</mat-option>
												<mat-option [value]="7" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 7">
													<i class="zmdi zmdi-navigation text-info m-r-10"></i>
													<span>In viaggio</span>
												</mat-option>
												<mat-option [value]="8" *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 8">
													<i class="zmdi zmdi zmdi-pin-drop text-info m-r-10"></i>
													<span>Sul posto</span>
												</mat-option>
											</ng-container>

											<!--intervention status: programmato-->
											<ng-container *ngIf="interventionStatusId.value === 2">
												<mat-option>Seleziona...</mat-option>
												<mat-option [value]="2">
													<i class="zmdi zmdi-time-interval text-warning m-r-10"></i>
													<span>Programmato</span>
												</mat-option>
												<mat-option [value]="4">
													<i class="zmdi zmdi-check-all text-success m-r-10"></i>
													<span>Completato</span>
												</mat-option>
												<mat-option [value]="5">
													<i class="zmdi zmdi-close text-danger m-r-10"></i>
													<span>Annullato</span>
												</mat-option>
											</ng-container>

											<!--intervention status: completato-->
											<ng-container *ngIf="interventionStatusId.value === 5">
												<mat-option>Seleziona...</mat-option>
												<mat-option [value]="4">
													<i class="zmdi zmdi-check-all text-success m-r-10"></i>
													<span>Completato</span>
												</mat-option>
												<mat-option [value]="5">
													<i class="zmdi zmdi-close text-danger m-r-10"></i>
													<span>Annullato</span>
												</mat-option>
											</ng-container>

											<!--intervention status: annullato-->
											<ng-container *ngIf="interventionStatusId.value === 6">
												<mat-option>Seleziona...</mat-option>
												<mat-option [value]="5">
													<i class="zmdi zmdi-close text-danger m-r-10"></i>
													<span>Annullato</span>
												</mat-option>
											</ng-container>

										</mat-select>
										<mat-error *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').errors?.required && workTeam.get('interventionPurgeWorkTeamStatusId').touched">
											Il nuovo stato del flusso di lavoro è obbligatorio
										</mat-error>
									</mat-form-field>

									<!--cancel notes-->
									<mat-form-field *ngIf="workTeam.get('interventionPurgeWorkTeamStatusId').value === 5">
										<mat-label>Note di annullamento</mat-label>
										<input matInput formControlName="cancelNotes" />
										<mat-error align="right" *ngIf="workTeam.get('cancelNotes').errors?.required && workTeam.get('cancelNotes').touched">
											Le note di annullamento sono obbligatorie
										</mat-error>
									</mat-form-field>

								</div>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</div>

		<div class="modal-footer">
			<button type="button" mat-button class="mr-2" (click)="hideModal()">
				Annulla
			</button>
			<button type="submit" mat-raised-button color="accent" [class.disabled]="!form.valid">
				Conferma e aggiorna lo stato
			</button>
		</div>

	</form>
</ng-container>