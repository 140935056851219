import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class FilesService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get file types
	public getFileTypes(filter?: any[])
	{
		return this.http.post(this.baseUrl + 'api/shared/files/getFileTypes', { fileTypesFilter: filter });
	}

	// download
	download(fileId: number)
	{
		return this.http.get(this.baseUrl + 'api/shared/files/download?IdFile=' + fileId, { responseType: 'blob' });
	}

	// get file public url
	getFilePublicUrl(IdFile: string)
	{
		return this.http.get(this.baseUrl + 'api/shared/files/getFilePublicUrl?IdFile=' + IdFile);
	}

	// delete
	delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/shared/files/delete?id=' + id);
	}

	// update
	update(file: any)
	{
		return this.http.post(this.baseUrl + 'api/shared/files/update', file);
	}
}