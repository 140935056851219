import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PurgeWorkGroup } from './purgeworkgroups.models';

@Injectable()
export class PurgeWorkGroupsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get single
	public getSingle(id: number): Observable<PurgeWorkGroup>
	{
		return this.http.get<PurgeWorkGroup>(`${this.baseUrl}api/purges/purgeworkgroups/getSingle/${id}`);
	}

	// insert/update
	public insertUpdate(purgeWorkGroup)
	{
		return this.http.post(`${this.baseUrl}api/purges/purgeworkgroups/insertUpdate`, purgeWorkGroup);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(`${this.baseUrl}api/purges/purgeworkgroups/delete/${id}`);
	}

	// export daily sheet
	public exportDailySheet(model)
	{
		return this.http.post(this.baseUrl + 'api/purges/purgeworkgroups/exportDailySheet?', model, { responseType: 'blob' });
	}

	// get all employees
	public getAllEmployees(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(`${this.baseUrl}api/purges/purgeworkgroups/getAllEmployees`);
	}
}