import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';

@Component({
	selector: 'intermediary-selector',
	templateUrl: './selector.component.html'
})

export class IntermediarySelectorComponent implements OnInit
{
	// I/O
	@Output() intermediarySelectionCallback: EventEmitter<any> = new EventEmitter();

	// view children
	@ViewChild('businessName') businessName: ElementRef;

	// datatable
	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean = false;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			businessName: [null],
			fiscalVatCode: [null],
			address: [null],
			referent: [null],
			winWasteCode: [null]
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// search intermediary
	search()
	{
		this.getList();
	}

	// get intermediaries list
	getList()
	{
		this._showTable = false;

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: this._searchForm.value,
			PageSize: 10,
			Url: this._baseUrl + 'api/registries/intermediaries/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Ragione sociale', data: 'businessName', className: 'min-tablet' },
					{
						title: 'Cod.&nbsp;Fiscale&nbsp;/&nbsp;P.IVA', data: 'vatNumber', className: 'min-tablet', render: (data, type, row) =>
						{
							return row['vatNumber'] || row['fiscalCode']
						}
					},
					{ title: 'Indirizzo', data: 'mainAddress', className: 'min-tablet' },
					{ title: 'Provincia', data: 'mainProvince', width: '20', className: 'min-tablet' },
					{ title: 'Referente', data: 'referent', width: '120', className: 'none' },
					{
						title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo intermediario" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.intermediarySelectionCallback.emit(
						{
							id: data['id'],
							businessName: data['businessName'],
							vatNumber: data['vatNumber'],
							fiscalCode: data['fiscalCode']
						});

					_self.hideModal();
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.businessName.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}
}