import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';

@Component({
	selector: 'vehicle-selector',
	templateUrl: './selector.component.html'
})

export class VehicleSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('modalAutofocusRef') modalAutofocusRef: ElementRef;

	// I/O
	@Output() vehicleSelectionCallback: EventEmitter<any> = new EventEmitter();

	// input filter
	public inputFilters: any;

	// variables
	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			name: [''],
			licensePlate: [''],
			status: [true],
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit(): void
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get vehicles list
	getList()
	{
		this._showTable = false;

		const _filters =
		{
			...{
				name: this._searchForm.value["name"],
				licensePlateSiaNumberBarcode: this._searchForm.value["licensePlate"],
				status: this._searchForm.value["status"]
			},
			...this.inputFilters
		};

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 5,
			Url: this._baseUrl + 'api/maintenances/vehicles/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Targa', data: 'licensePlate', className: 'all' },
					{ title: 'Nome', data: 'name', className: 'min-tablet' },
					{
						title: 'Prossima&nbsp;manut.', data: 'nextMaintenanceScheduling', className: 'min-tablet', render: (data, type, row) =>
						{
							if (data)
								return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm')

							return '';
						}
					},
					{
						title: '', data: 'id', className: 'all adv-col adv-col-sm', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo automezzo" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.vehicleSelectionCallback.emit({

						// vehicle
						id: data['id'],
						name: data['name'],
						licensePlate: data['licensePlate'],
						siaNumber: data['siaNumber'],
						mounting: data['mounting'],
						adr: data['adr'],

						// maintenance
						nextMaintenanceId: data['nextMaintenanceId'],
						nextMaintenanceScheduling: data['nextMaintenanceScheduling'],

						// iot
						iotDeviceId: data['iotDeviceId'],
						purgeWorkTypeId: data['purgeWorkTypeId']
					});
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.modalAutofocusRef.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search vehicle
	search()
	{
		this.getList();
	}
}