import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { CatalogArticleAttributesSummary } from '../articletypes.models';
import { ArticleTypesService } from '../articletypes.service';

@Component({
	selector: 'article-logs-modal',
	templateUrl: './article-logs-modal.component.html',
	styleUrls: ['./article-logs-modal.component.scss']
})

export class CatalogArticleLogsModalComponent
{
	// viewchildren
	@ViewChild('catalogArticleLogsModal') catalogArticleLogsModal;
	public _dtOptions = {};

	// I/O
	@Input() catalogArticleTypeId?: number;
	@Input() catalogArticleId?: number;
	@Input() catalogArticlePartId?: number;
	@Input() employeeId?: number;
	@Input() userId?: string;

	// variables
	public _showTable = false;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			startDate: [null],
			endDate: [null],
			operationAlias: [null],
			employeeFullName: [null],
			userFullName: [null],
		}
	);
	get startDate() { return this._searchForm.get('startDate'); }
	get endDate() { return this._searchForm.get('endDate'); }

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private articleTypesService: ArticleTypesService,
			private fb: FormBuilder,
			public datepipe: DatePipe,
			private cd: ChangeDetectorRef
		)
	{
	}

	// modal operations
	showModal()
	{
		// check: default filters
		if (!this.catalogArticleTypeId && !this.catalogArticleId && !this.catalogArticlePartId)
		{
			this.startDate.setValue(new Date());
			this.endDate.setValue(new Date());
		}

		// show modal
		this.catalogArticleLogsModal.show();

		// get logs list
		setTimeout(() =>
		{
			this.getList();
		}, 200);
	}
	closeModal()
	{
		this.catalogArticleLogsModal.hide()
	}

	// search
	search(): void
	{
		this.getList();
	}

	// reset filter
	resetFilter()
	{
		// default values
		this._searchForm.reset();

		// check: default filters
		if (!this.catalogArticleTypeId && !this.catalogArticleId && !this.catalogArticlePartId)
		{
			this.startDate.setValue(new Date());
			this.endDate.setValue(new Date());
		}

		// getting list
		this.getList();
	}

	// get filtered list
	getList()
	{
		this._showTable = false;

		let _filters =
		{
			...this._searchForm.value,

			catalogArticleTypeId: this.catalogArticleTypeId,
			catalogArticleId: this.catalogArticleId,
			catalogArticlePartId: this.catalogArticlePartId,
			employeeId: this.employeeId,
			userId: this.userId
		};

		if (_filters.startDate)
			_filters.startDate = this.datepipe.transform(this.startDate.value, 'yyyy-MM-dd');
		if (_filters.endDate)
			_filters.endDate = this.datepipe.transform(this.endDate.value, 'yyyy-MM-dd');

		this._dtOptions = this.pcodeServices.initDatatable(
			{
				Filters: _filters,
				PageSize: 10,
				Url: this._baseUrl + 'api/technologicalresources/articlestypes/getCatalogArticlesLogsList',
				Order: [5, 'desc'],
				Columns:
					[
						{ title: 'Risorsa&nbsp;tecnologica', data: 'catalogArticleTypeName', orderable: false, className: 'all' },
						{
							title: 'Articolo', data: 'attributesSummary', orderable: false, className: 'all', render: (data, type, row) =>
							{
								var result = '';
								if (row['attributesSummary'])
								{
									var jsonData: CatalogArticleAttributesSummary = JSON.parse(row['attributesSummary']);
									jsonData.summary.forEach(attribute =>
									{
										let val = attribute.val;

										if (val)
										{
											switch (attribute.cat)
											{
												// numeric
												case 3:
													val = parseFloat(val).toString();
													break;
												// boolean
												case 5:
													val = val && JSON.parse(val) === 1 ? 'S�' : 'No';
													break;
											}

											result += `${attribute.can}: ${val}\n`;
										}
									});
								}

								return result;
							}
						},
						{
							title: 'Operazione', width: '180', data: 'logAction', orderable: false, className: 'all', render: (data, type, row) =>
							{
								switch (data)
								{
									case 'INSERT':
										return 'Inserimento';
									case 'UPDATE':
										return 'Aggiornamento';
									case 'DELETE':
										return 'Eliminazione';
									case 'ADD_ASSIGNATION':
										return 'Assegnazione';
									case 'REMOVE_ASSIGNATION':
										return 'Rimozione assegnazione';
								}
							}
						},
						{ title: 'Dipendente', data: 'employeeFullName', orderable: false, className: 'all' },
						{ title: 'Utente', data: 'userFullName', orderable: false, className: 'all' },
						{
							title: 'Data', data: 'insertDate', width: '120', orderable: false, className: 'all', render: (data, type, row) =>
							{
								return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm:ss')
							}
						},
						{ title: 'Messaggio', data: 'message', orderable: false, className: 'none' }
					],
				RowCallback: (row: Node, data: any[] | object, index: number) =>
				{

				},
				DrawCallback: (settings: any) =>
				{

				}
			});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// date change moment formatting
	onDateChange(controlName: string, event: any)
	{
		if (event.value)
			this._searchForm.get(controlName).setValue(moment(event.value));
		else
			this._searchForm.get(controlName).setValue(null);
	}
};