import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'waste-catalog-selector',
	templateUrl: './selector.component.html'
})

export class WasteCatalogSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('cerIdentifier') cerIdentifier: ElementRef;

	// I/O
	@Output() wasteCatalogSelectionCallback: EventEmitter<any> = new EventEmitter();

	// variables
	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean = false;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			cerIdentifier: [''],
			description: ['']
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get producers list
	getList()
	{
		this._showTable = false;

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: this._searchForm.value,
			PageSize: 10,
			Url: this._baseUrl + 'api/registries/wastecatalog/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Identificativo&nbsp;CER', data: 'cerIdentifier', className: 'all' },
					{ title: 'Descrizione', data: 'description', className: 'min-tablet' },
					{
						title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo rifiuto" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.wasteCatalogSelectionCallback.emit({
						id: data['id'],
						cerIdentifier: data['cerIdentifier'],
						description: data['description']
					});

					_self.hideModal();
				});
			},
			DrawCallback: (settings) =>
			{
				setTimeout(() => { this.cerIdentifier.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search waste catalog
	search()
	{
		this.getList();
	}
}