import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AttachmentsReportingService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// ==============================
	// employees
	// ==============================

	// get employee file types
	public getEmployeeFileTypes(): Observable<Array<any>>
	{
		return this.http.get<Array<any>>(this.baseUrl + 'api/shared/attachmentsreporting/getEmployeeFileTypes');
	}

	// get employees list
	public getEmployeesList()
	{
		return this.http.get<any[]>(this.baseUrl + 'api/shared/attachmentsreporting/getEmployeesList');
	}

	// ==============================
	// vehicles
	// ==============================

	// get vehicles file types
	public getVehiclesFileTypes(): Observable<Array<any>>
	{
		return this.http.get<Array<any>>(this.baseUrl + 'api/shared/attachmentsreporting/getVehiclesFileTypes');
	}

	// get vehicles maintenance types
	// get all catalog categories
	public getAllMaintenanceTypes(): Observable<[]> {
		return this.http.get<[]>(this.baseUrl + 'api/maintenances/vehicles/getVehicleTypes');
	}

	// get vehicles list
	public getVehiclesList()
	{
		return this.http.get<any[]>(this.baseUrl + 'api/shared/attachmentsreporting/getVehiclesList');
	}

	// ==============================
	// technological resources - elements
	// ==============================

	// get elements file types
	public getElementsFileTypes(): Observable<Array<any>> {
		return this.http.get<Array<any>>(this.baseUrl + 'api/shared/attachmentsreporting/getElementsFileTypes');
	}

	// get elements list
	public getElementsList() {
		return this.http.get<any[]>(this.baseUrl + 'api/shared/attachmentsreporting/getArticlesList');
	}

	// ==============================
	// commons reports
	// ==============================

	// get user reporting models
	public getUserReportingModels(reportingTypeId: number): Observable<Array<any>>
	{
		return this.http.get<Array<any>>(`${this.baseUrl}api/shared/attachmentsreporting/getUserReportingModels/${reportingTypeId}`);
	}

	// get reporting model
	public getReportingModel(id: number): Observable<any>
	{
		return this.http.get<any>(this.baseUrl + 'api/shared/attachmentsreporting/getReportingModel/' + id);
	}

	// insert/update reporting model
	public insertUpdateReportingModel(reportingTypeId: number, reportingModel: any): Observable<any>
	{
		return this.http.post<any>(`${this.baseUrl}api/shared/attachmentsreporting/insertUpdateReportingModel/${reportingTypeId}`, reportingModel);
	}

	// delete reporting model
	public deleteReportingModel(id: number): Observable<any>
	{
		return this.http.delete<any>(this.baseUrl + 'api/shared/attachmentsreporting/deleteReportingModel/' + id);
	}

	// download multiple
	public downloadMultiple(reportingTypeId: number, fileIds: Array<number>, exportType: number)
	{
		return this.http.post(`${this.baseUrl}api/shared/attachmentsreporting/downloadMultiple/${reportingTypeId}`,
			{
				fileIds: fileIds,
				exportType: exportType
			},
			{ responseType: 'blob' });
	}

	// get all catalog categories
	public getAllCatalogCategories(): Observable<[]> {
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllCatalogCategories');
	}

	// get all catalog categories
	public getAllArticleTypes(): Observable<[]> {
		return this.http.get<[]>(this.baseUrl + 'api/technologicalresources/articlesTypes/getAllArticleTypes');
	}

}