import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransportMission, TransportMissionActivityLog, TransportMissionActivityMaintenanceItem, TransportMissionListDetails, TransportMissionOffice, TransportMissionAuthorization, TransportMissionSystemLog } from './transportmissions.models';

@Injectable()
export class TransportMissionsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get filter operators
	public getFilterOperators(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + 'api/transport/transportmissions/getFilterOperators');
	}

	// get filter mission activity types
	public getFilterMissionActivityTypes(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + 'api/transport/transportmissions/getFilterMissionActivityTypes');
	}

	// get filter transport mission work not types
	public getFilterTransportMissionWorkNoteTypes(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + 'api/transport/transportmissions/getFilterTransportMissionWorkNoteTypes');
	}

	// get list item details
	public getListDetails(id: number): Observable<TransportMissionListDetails>
	{
		return this.http.get<TransportMissionListDetails>(this.baseUrl + 'api/transport/transportmissions/getListDetails?Id=' + id);
	}

	// get single
	public getSingle(id: number, missionTypeId: string, thirdParties: boolean, action: string, sourceId: number): Observable<TransportMission>
	{
		return this.http.get<TransportMission>(this.baseUrl + 'api/transport/transportmissions/getSingle?Id=' + id + '&TransportMissionTypeId=' + missionTypeId + '&ThirdParties=' + thirdParties + '&Action=' + action + '&SourceId=' + sourceId);
	}

	// get entity offices
	public getEntityOffices(producerId?: number, disposerId?: number, transporterId?: number, intermediaryId?: number,): Observable<TransportMissionOffice[]>
	{
		return this.http.post<TransportMissionOffice[]>(this.baseUrl + 'api/transport/transportmissions/getEntityOffices',
			{
				producerId: producerId,
				disposerId: disposerId,
				transporterId: transporterId,
				intermediaryId: intermediaryId,
			});
	}

	// update
	public insertUpdate(transportMission, schedule: boolean, callbackAction: string, callbackId: number): Observable<TransportMission>
	{
		return this.http.post<TransportMission>(this.baseUrl + 'api/transport/transportmissions/insertUpdate' +
			('?schedule=' + schedule) +
			(callbackAction ? '&callbackAction=' + callbackAction : '') +
			(callbackId ? '&callbackId=' + callbackId : ''), transportMission);
	}

	// change status
	public changeStatus(model)
	{
		return this.http.post(this.baseUrl + 'api/transport/transportmissions/changeStatus', model);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/transport/transportmissions/delete/' + id);
	}

	// get transport mission work note types
	public getTransportMissionWorkNoteTypes(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + 'api/transport/transportmissions/getTransportMissionWorkNoteTypes');
	}

	// get transport mission system logs
	public getTransportMissionSystemLog(id: number): Observable<TransportMissionSystemLog[]>
	{
		return this.http.get<TransportMissionSystemLog[]>(this.baseUrl + 'api/transport/transportmissions/getTransportMissionSystemLog/' + id);
	}

	// get office aauthorizations
	public getAuthorizations(officeId: number, disposerId: number, intermediaryId: number, transporterId: number): Observable<TransportMissionAuthorization[]>
	{
		return this.http.post<TransportMissionAuthorization[]>(this.baseUrl + 'api/transport/transportmissions/getAuthorizations',
			{
				officeId: officeId,
				disposerId: disposerId,
				intermediaryId: intermediaryId,
				transporterId: transporterId
			});
	}

	// get transport mission activity logs
	public getTransportMissionActivityLogs(missionId: number, activityId: number): Observable<TransportMissionActivityLog[]>
	{
		return this.http.get<TransportMissionActivityLog[]>(this.baseUrl + 'api/transport/transportmissions/getTransportMissionActivityLogs/' + missionId + '/' + activityId);
	}

	// get daily maintenances
	public getDailyMaintenances(date: string): Observable<TransportMissionActivityMaintenanceItem[]>
	{
		return this.http.get<TransportMissionActivityMaintenanceItem[]>(this.baseUrl + 'api/transport/transportmissions/getDailyMaintenances?Date=' + date);
	}

	// export report
	public exportReport(filters)
	{
		return this.http.post(this.baseUrl + `api/transport/transportmissions/exportReport`, filters, { responseType: 'blob' });
	}

	// update
	public changeDisposerTransporterStatus(model)
	{
		return this.http.post(this.baseUrl + 'api/transport/transportmissions/changeDisposerTransporterStatus', model);
	}
}