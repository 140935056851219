<form [formGroup]="form" class="form-horizontal" *ngIf="showComponent">

    <!--purge work teams-->
    <ng-container formArrayName="purgeWorkTeams">
        <div *ngFor="let purgeWorkTeam of purgeWorkTeamsFormArray.controls; let purgeWorkTeamIndex = index;" class="card card_work_team {{(purgeWorkTeam.get('lead').value ? 'card_work_team--lead' : '')}}">
            <div [formGroupName]="purgeWorkTeamIndex" class="form-horizontal">
                <header class="card-header card-default">
                    <h2 class="card-title d-flex align-items-center justify-content-between">
                        <span>
                            <ng-container *ngIf="purgeWorkTeam.get('lead').value">
                                <i class="zmdi zmdi-star m-r-10"></i>
                                <span>Squadra principale</span>
                            </ng-container>
                            <ng-container *ngIf="!purgeWorkTeam.get('lead').value">
                                <i class="zmdi zmdi-accounts-alt m-r-10"></i>
                                <span>Squadra di supporto <ng-container *ngIf="purgeWorkTeamsFormArray.controls.length > 2">{{purgeWorkTeamIndex}}</ng-container></span>
                            </ng-container>
                        </span>
                        <span class="d-flex" *ngIf="!readOnly">
                            <button mat-mini-fab color="warn" class="btn-card-action ml-3" (click)="deletePurgeWorkTeam(purgeWorkTeamIndex)" matTooltip="Elimina squadra di lavoro" *ngIf="!purgeWorkTeam.get('lead').value">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-mini-fab color="accent" class="btn-card-action ml-3" (click)="showVehicleSelectorDialog(purgeWorkTeamIndex)" matTooltip="Aggiungi automezzo" *ngIf="purgeWorkTeam.get('vehicles')['controls'].length < _maxVehiclesPerTeam">
                                <mat-icon>local_shipping</mat-icon>
                            </button>
                            <button mat-mini-fab color="accent" class="btn-card-action ml-3" (click)="showEmployeeSelectorDialog(purgeWorkTeamIndex)" matTooltip="Aggiungi operatore" *ngIf="purgeWorkTeam.get('employees')['controls'].length < _maxOperatorsPerTeam">
                                <mat-icon>person</mat-icon>
                            </button>
                        </span>
                    </h2>
                </header>
                <div class="card-block">

                    <!--employees and vehicles list-->
                    <div *ngIf="purgeWorkTeam.get('employees')['controls'].length > 0 || purgeWorkTeam.get('vehicles')['controls'].length > 0">
                        <ul class="work_team_items">

                            <!--employees-->
                            <ng-container formArrayName="employees">
                                <li *ngFor="let employee of purgeWorkTeam.get('employees')['controls']; let employeeIndex = index">
                                    <div [class.team_leader]="employee.get('teamLeader').value" [formGroupName]="employeeIndex" class="d-flex justify-content-between align-items-center">

                                        <!--employee-->
                                        <div class="d-flex align-items-center">
                                            <i class="zmdi zmdi-account-circle m-r-15"></i>
                                            <div class="work_team_item_subject">
                                                <strong>{{employee.get('lastName').value}} {{employee.get('firstName').value}}</strong>
                                                <small class="d-block font-weight-normal" *ngIf="employee.get('teamLeader').value">CAPOSQUADRA<ng-container *ngIf="purgeWorkTeam.get('lead').value"> E RESPONSABILE DEL GRUPPO</ng-container></small>
                                                <small class="d-block font-weight-normal" *ngIf="!employee.get('teamLeader').value">COLLABORATORE</small>
                                            </div>
                                        </div>

                                        <!--actions-->
                                        <div class="work_team_items--actions" *ngIf="!readOnly">
                                            <a href="#" (click)="setWorkTeamLeader(purgeWorkTeamIndex, employeeIndex)" *ngIf="!employee.get('teamLeader').value" class="work_team_item_action work_team_item_action--teamleader m-r-10" matTooltip="Imposta caposquadra">
                                                <i class="zmdi zmdi-dot-circle"></i>
                                            </a>
                                            <a href="#" (click)="removeEmployee(purgeWorkTeamIndex, employeeIndex)" class="work_team_item_action text-danger" matTooltip="Rimuovi dalla squadra">
                                                <i class="material-icons">delete_outline</i>
                                            </a>
                                        </div>

                                    </div>
                                </li>
                            </ng-container>

                            <!--vehicles-->
                            <ng-container formArrayName="vehicles">
                                <li *ngFor="let vehicle of purgeWorkTeam.get('vehicles')['controls']; let vehicleIndex = index">
                                    <div [formGroupName]="vehicleIndex" class="d-flex justify-content-between align-items-center">

                                        <!--vehicle-->
                                        <div class="d-flex flex-1 mr-3 align-items-center">
                                            <i class="zmdi zmdi-truck m-r-15"></i>
                                            <div class="work_team_item_subject">
                                                <strong>{{vehicle.get('licensePlate').value}}<ng-container *ngIf="vehicle.get('mounting').value"> - {{vehicle.get('mounting').value}}</ng-container></strong>
                                                <small class="d-block font-weight-normal" *ngIf="vehicle.get('name').value">{{vehicle.get('name').value}}</small>

                                                <!--iot: purge work type-->
                                                <div class="mt-2" *ngIf="layout === 1 && vehicle.get('purgeWorkTypeId').value">
                                                    <mat-form-field>
                                                        <mat-label>Tipo di lavoro</mat-label>
                                                        <mat-select formControlName="purgeWorkTypeOptionId">
                                                            <mat-option class="work_type_option" *ngFor="let purgeWorkTypeOption of vehicle.get('purgeWorkTypeOptions')['controls']" [value]="purgeWorkTypeOption.get('id').value">
                                                                <span>{{purgeWorkTypeOption.get('name').value}} <small>({{purgeWorkTypeOption.get('description').value}})</small></span>
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error>Seleziona la tipologia di lavoro</mat-error>
                                                    </mat-form-field>
                                                </div>

                                            </div>
                                        </div>

                                        <!--actions-->
                                        <div class="work_team_items--actions" *ngIf="!readOnly">
                                            <a href="#" (click)="removeVehicle(purgeWorkTeamIndex, vehicleIndex)" class="work_team_item_action text-danger" title="Rimuovi dalla squadra">
                                                <i class="material-icons">delete_outline</i>
                                            </a>
                                        </div>

                                    </div>
                                </li>
                            </ng-container>

                            <!--interventions info-->
                            <ng-container *ngIf="layout === 1">

                                <!--work team notes-->
                                <li *ngIf="!readOnly || purgeWorkTeam.get('notes').value">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex w-100p align-items-center">
                                            <i class="zmdi zmdi-assignment m-r-15"></i>
                                            <div class="work_team_item_subject">
                                                <strong>NOTE PER LA SQUADRA DI LAVORO</strong>
                                                <span class="d-block" *ngIf="readOnly">
                                                    {{purgeWorkTeam.get('notes').value}}
                                                </span>
                                                <mat-form-field *ngIf="!readOnly">
                                                    <input matInput formControlName="notes" maxlength="1024" class="d-block" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <!--worksheet details-->
                                <ng-container *ngIf="showCustomLayoutDetails">
                                    <li><hr /></li>

                                    <!--worksheet notes-->
                                    <li>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex w-100p align-items-center">
                                                <i class="zmdi zmdi-assignment m-r-15"></i>
                                                <div class="work_team_item_subject">
                                                    <strong>NOTE DAGLI OPERATORI SUL CAMPO</strong>
                                                    <span class="d-block">{{purgeWorkTeam.get('worksheetNotes').value || 'Nessuna nota'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!--worksheet pipe notes-->
                                    <li>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex w-100p align-items-center">
                                                <mat-icon class="m-r-15">insights</mat-icon>
                                                <div class="work_team_item_subject">
                                                    <strong>MT. LINEARI TUBI</strong>
                                                    <span class="d-block">{{purgeWorkTeam.get('worksheetPipeNotes').value || 'Nessuna indicazione'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!--worksheet time-->
                                    <li>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex w-100p align-items-center">
                                                <mat-icon class="m-r-15">timer</mat-icon>
                                                <div class="work_team_item_subject">
                                                    <strong>TEMPO DI LAVORO</strong>
                                                    <span class="d-block" *ngIf="purgeWorkTeam.get('worksheetWorkTimeHours').value || purgeWorkTeam.get('worksheetWorkTimeMinutes').value">
                                                        <ng-container *ngIf="purgeWorkTeam.get('worksheetWorkTimeHours').value && purgeWorkTeam.get('worksheetWorkTimeHours').value > 0">{{purgeWorkTeam.get('worksheetWorkTimeHours').value}} or{{(purgeWorkTeam.get('worksheetWorkTimeHours').value === 1 ? 'a' : 'e')}}</ng-container>
                                                        <ng-container *ngIf="purgeWorkTeam.get('worksheetWorkTimeMinutes').value && purgeWorkTeam.get('worksheetWorkTimeMinutes').value > 0">
                                                            <ng-container *ngIf="purgeWorkTeam.get('worksheetWorkTimeHours').value && purgeWorkTeam.get('worksheetWorkTimeHours').value > 0"> e </ng-container>
                                                            {{purgeWorkTeam.get('worksheetWorkTimeMinutes').value}} minuti
                                                        </ng-container>
                                                    </span>
                                                    <span class="d-block" *ngIf="!purgeWorkTeam.get('worksheetWorkTimeHours').value && !purgeWorkTeam.get('worksheetWorkTimeMinutes').value">
                                                        Nessuna indicazione
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ng-container>

                            </ng-container>

                            <!--purge notes info-->
                            <ng-container *ngIf="layout === 2">

                                <!--work team notes-->
                                <li *ngIf="!readOnly || purgeWorkTeam.get('notes').value">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex w-100p align-items-center">
                                            <i class="zmdi zmdi-assignment m-r-15"></i>
                                            <div class="work_team_item_subject">
                                                <strong>NOTE PER LA SQUADRA DI LAVORO</strong>
                                                <span class="d-block" *ngIf="readOnly">
                                                    {{purgeWorkTeam.get('notes').value}}
                                                </span>
                                                <mat-form-field *ngIf="!readOnly">
                                                    <input matInput formControlName="notes" maxlength="1024" class="d-block" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <!--worksheet details-->
                                <ng-container *ngIf="showCustomLayoutDetails">
                                    <li><hr /></li>

                                    <!--worksheet notes-->
                                    <li>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex w-100p align-items-center">
                                                <i class="zmdi zmdi-assignment m-r-15"></i>
                                                <div class="work_team_item_subject">
                                                    <strong>NOTE DAGLI OPERATORI SUL CAMPO</strong>
                                                    <span class="d-block">{{purgeWorkTeam.get('worksheetNotes').value || 'Nessuna nota'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ng-container>
                            </ng-container>

                        </ul>

                    </div>

                    <!--purge work team operators and vehicles validation-->
                    <div class="d-flex text-danger align-items-center mt-2" *ngIf="((purgeWorkTeam.get('employees').errors?.minArrayLength || purgeWorkTeam.get('employees').errors?.maxArrayLength) && purgeWorkTeam.get('employees').touched) || ((purgeWorkTeam.get('vehicles').errors?.minArrayLength || purgeWorkTeam.get('vehicles').errors?.maxArrayLength) && purgeWorkTeam.get('vehicles').touched)">
                        <ul class="empty_purge_work_team_help_list">
                            <li *ngIf="(purgeWorkTeam.get('employees').errors?.minArrayLength || purgeWorkTeam.get('employees').errors?.maxArrayLength) && purgeWorkTeam.get('employees').touched">
                                Seleziona
                                <ng-container *ngIf="_minOperatorsPerTeam === _maxOperatorsPerTeam">{{_minOperatorsPerTeam}} operator{{_minOperatorsPerTeam === 1 ? 'e' : 'i'}}.</ng-container>
                                <ng-container *ngIf="_minOperatorsPerTeam !== _maxOperatorsPerTeam">da {{_minOperatorsPerTeam}} a {{_maxOperatorsPerTeam}} operatori.</ng-container>
                            </li>
                            <li *ngIf="(purgeWorkTeam.get('vehicles').errors?.minArrayLength || purgeWorkTeam.get('vehicles').errors?.maxArrayLength) && purgeWorkTeam.get('vehicles').touched">
                                Seleziona
                                <ng-container *ngIf="_minVehiclesPerTeam === _maxVehiclesPerTeam">{{_minVehiclesPerTeam}} automezz{{_minVehiclesPerTeam === 1 ? 'o' : 'i'}}.</ng-container>
                                <ng-container *ngIf="_minVehiclesPerTeam !== _maxVehiclesPerTeam">da {{_minVehiclesPerTeam}} a {{_maxVehiclesPerTeam}} automezzi.</ng-container>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>

    <!--add purge work team-->
    <button mat-raised-button class="mr-3" color="accent" (click)="addPurgeWorkTeam()" *ngIf="showAddWorkTeamAction && purgeWorkTeamsFormArray.controls.length < maxPurgeWorkTeamsCount && purgeWorkTeamsFormArray.controls.length > 0">
        <mat-icon class="m-r-10">add</mat-icon>
        <span>Aggiungi squadra di lavoro</span>
    </button>

    <ng-container *ngIf="(layout === 1 || layout === 2) && showResetWorkTeamAction && purgeWorkTeamsFormArray.controls.length > 0">
        <button mat-raised-button (click)="resetWorkTeams()">
            <mat-icon class="m-r-10">undo</mat-icon>
            <span>Ricomincia</span>
        </button>
    </ng-container>

</form>