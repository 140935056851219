import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DeadlinesService {
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

	}

	// get single
	public getSingle(id: number, copyId?: number) {
		return this.http.get(this.baseUrl + 'api/timetable/deadlines/getSingle?Id=' + id + '&copyId=' + copyId);
	}

	// update
	public insertUpdate(operation: any) {
		return this.http.post(this.baseUrl + 'api/timetable/deadlines/insertUpdate', operation);
	}

	// delete
	public delete(id: number) {
		return this.http.delete(this.baseUrl + 'api/timetable/deadlines/delete?id=' + id);
	}


	// insert activity
	public insertActivity(activity: any) {
		return this.http.post(this.baseUrl + 'api/timetable/deadlines/insertActivity', activity);
	}

	// delete activity
	public deleteActivity(id: number) {
		return this.http.delete(this.baseUrl + 'api/timetable/deadlines/deleteDeadlineActivity?id=' + id);
	}

	// export
	public export(params: any) {
		return this.http.post(this.baseUrl + 'api/timetable/deadlines/export', params, { responseType: 'blob' });
	}
}