<aside class="sidebar-task">
    <h2 class="title">{{'Shared.Todo.Title' | translate}}</h2>
    <ul class="actions icons header-actions top-right">
        <li>
            <a href="javascript:void(0)" (click)="triggerToggleInput = !triggerToggleInput">
                <i class="zmdi zmdi-plus animate-fab"></i>
            </a>
        </li>
    </ul>
    <form novalidate [formGroup]="form" (ngSubmit)="onSubmit(form)" class="slider" [ngClass]="{'closed' : triggerToggleInput}">
        <div class="form-group" [ngClass]="form.get('newTask').hasError('required') && isSubmitted ? 'has-danger' : ''">
            <mat-form-field floatPlaceholder="auto" class="float-auto">
                <input matInput type="text" id="task" placeholder="{{'Shared.Todo.AddTask' | translate}}" formControlName="newTask" autocomplete="off">
            </mat-form-field>
        </div>
    </form>
    <section>
        <div class="card card-task-mini" *ngFor="let item of tasks; let i = index">
            <app-task [task]="item" (notify)="onNotify($event)"></app-task>
        </div>
    </section>
</aside>