import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InterventionsService } from '../../../../pages/purges/interventions/interventions.service';
import { DigifranTimeLine } from '../../../../shared/timeline/timeline.models';
import { InterventionRegistry, InterventionTimeLineEvent } from '../interventions.models';

@Component({
	selector: 'intervention-logs-dialog',
	templateUrl: './intervention-logs-dialog.component.html',
	styleUrls: ["./intervention-logs-dialog.component.scss"]
})

export class InterventionLogsDialogComponent
{
	// input data
	public inputData: { id: number };

	// variables
	public interventionRegistry: InterventionRegistry;
	public interventionTimeline: DigifranTimeLine;
	public showRegistryLogs = false;

	// constructor
	constructor
		(
			private interventionsService: InterventionsService,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.loadInterventionTimeline();
	}

	// load intervention timeline
	loadInterventionTimeline()
	{
		this.showRegistryLogs = false;

		this.interventionsService.getInterventionLogs(this.inputData.id).subscribe(result =>
		{
			this.interventionRegistry = result;

			this.interventionTimeline = new DigifranTimeLine();
			this.interventionTimeline.options =
			{
				dateFormat: 'dd/MM/yy HH:mm:ss'
			};

			// events data
			this.interventionRegistry.interventionLogs.forEach(log =>
			{
				const digifranTimeLineEvents = new InterventionTimeLineEvent();

				// base properties
				digifranTimeLineEvents.dateTime = log.insertDate;
				digifranTimeLineEvents.icon = log.interventionLogActionIcon;
				digifranTimeLineEvents.iconColor = log.interventionLogActionIconColor;

				// custom properties
				digifranTimeLineEvents.title = log.interventionLogActionTitle;
				digifranTimeLineEvents.description = log.interventionLogActionDescription;
				digifranTimeLineEvents.message = log.description;
				digifranTimeLineEvents.user = log.insertUserFullName;

				this.interventionTimeline.events.push(digifranTimeLineEvents);
			});

			this.showRegistryLogs = true;
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}