export class DigifranTimeLine
{
	// constructor
	constructor()
	{
		this.events = [];
		this.options =
		{
			dateFormat: 'dd/MM/yyyy HH:mm:ss'
		}
	}

	// events
	events: any[];

	// options
	options: DigifranTimeLineOptions;
}
export class DigifranTimeLineEvent
{
	dateTime: Date;
	icon: string;
	iconColor: string;
}
export interface DigifranTimeLineOptions
{
	dateFormat: string;
}