import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PCodeServices } from '../../../../shared/services/pcode/pcode.service';
import { DeadlinesService } from '../../../pages/timetable/deadlines/deadlines.service';

@Component({
	selector: 'edit-activity',
	templateUrl: './edit-activity.component.html'
})
export class EditActivityComponent implements OnInit
{
	@ViewChild('editActivityModal') editActivityModal;

	@Output() editActivityCallback: EventEmitter<any> = new EventEmitter();

	public _currentActivityDescription: any;
	public _currentActivityLastDate: any;
	public _currentActivityNextDate: any;

	// activity form
	public _activityForm: FormGroup = this.fb.group(
		{
			id: [0, [Validators.required]],
			deadlineId: [0, [Validators.required]],
			activityDate: [null, [Validators.required]],
			description: [null, [Validators.required]]
		}
	);
	get id_AC() { return this._activityForm.get('id'); }
	get deadlineId_AC() { return this._activityForm.get('deadlineId'); }
	get activityDate_AC() { return this._activityForm.get('activityDate'); }
	get description_AC() { return this._activityForm.get('description'); }

	// constructor
	constructor
		(
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			private deadlinesService: DeadlinesService,
			private datepipe: DatePipe
		)
	{ }

	// init
	ngOnInit()
	{

	}

	// show new office modal
	showNewActivityModal(deadlineId: number)
	{
		this.deadlinesService.getSingle(deadlineId).subscribe(result =>
		{

			this._currentActivityDescription = result["description"];
			this._currentActivityLastDate = result["lastActivityDate"];
			this._currentActivityNextDate = result["nextActivityDate"];

			console.log(result);
			// reset values
			this._activityForm.reset();

			this.id_AC.setValue(0);
			this.activityDate_AC.setValue(new Date());
			this.deadlineId_AC.setValue(deadlineId);

			// show modal
			this.editActivityModal.show();

		});
	}

	// insert activity
	insertActivity()
	{
		if (this._activityForm.valid)
		{
			let _formValue = this._activityForm.value;

			if (_formValue.activityDate)
			{
				_formValue.activityDate = this.datepipe.transform(_formValue.activityDate, 'yyyy-MM-dd');
			}

			this.deadlinesService.insertActivity(_formValue)
				.subscribe({
					next: success =>
					{
						this.editActivityCallback.emit();
						this.editActivityModal.hide();
					},

					error: error =>
					{
						this.pcodeServices.notify('error', error);
					}
				});
		}
		else
		{
			this.getFormValidationErrors();
		}
	}

	// get validation errors
	getFormValidationErrors()
	{
		Object.keys(this._activityForm.controls).forEach(key =>
		{

			const controlErrors: ValidationErrors = this._activityForm.get(key).errors;
			if (controlErrors != null)
			{
				Object.keys(controlErrors).forEach(keyError =>
				{
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}
}