import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../../app/shared/services/pcode/pcode.service';
import { CustomerSelectorInput } from './selector.models';

@Component({
	selector: 'customer-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
})

export class CustomerSelectorComponent implements OnInit
{
	// viewchildren
	@ViewChild('businessName') businessName: ElementRef;

	// I/O
	@Output() customerSelectionCallback: EventEmitter<any> = new EventEmitter();

	// input filter
	public inputFilters: CustomerSelectorInput;

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable = false;

	public _modalTItle = "Seleziona cliente"
	public _checkBlacklist = true;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			winWasteCod: [''],
			businessName: [''],
			address: [''],
			referent: [''],
			fiscalVatCode: [''],
			buildingAdministrator: [''],
			interim: [null],
			excludeBlacklisted: [null],
			mobilePhoneNumber: ['']
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		// custom title
		if (this.inputFilters.customTitle)
			this._modalTItle = this.inputFilters.customTitle;

		// check for blacklist
		if (this.inputFilters.checkBlacklist != null)
			this._checkBlacklist = this.inputFilters.checkBlacklist;

		// exclude blacklist
		if (this.inputFilters.excludeBlacklisted != null)
			this._searchForm.get('excludeBlacklisted').setValue(this.inputFilters.excludeBlacklisted);

		// autofocus
		setTimeout(() => { this.businessName.nativeElement.focus(); }, 500);
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// show modal
	showModal(customTitle?: string, checkBlacklist?: boolean, excludeBlacklisted?: boolean)
	{

	}

	// get customers list
	getList()
	{
		this._showTable = false;

		this._dtOptions = this.pcodeServices.initDatatable(
			{
				Filters: this._searchForm.value,
				PageSize: 10,
				Url: this._baseUrl + 'api/registries/customers/getList',
				Order: [0, 'asc'],
				Columns:
					[
						{
							title: 'Ragione sociale', data: 'businessName', className: 'min-tablet', render: (data, type, row) =>
							{
								let _subscriptionIcon = null;

								if (row['hasMultiSubscription'])
									_subscriptionIcon = '<i class="zmdi zmdi-rotate-left text-danger m-r-10" title="Multi-Abbonamento"></i>';
								else if (row['noSubscription'])
									_subscriptionIcon = '<i class="zmdi zmdi-rotate-left m-r-10" title="No-Abbonamento"></i>';
								else if (row['hasSubscription'])
									_subscriptionIcon = '<i class="zmdi zmdi-rotate-left text-info m-r-10" title="Abbonamento"></i>';

								if (_subscriptionIcon)
									return `<div class="customer-cell"><span>${_subscriptionIcon}</span>${data}</div>`;
								else
									return data;
							}
						},
						{
							title: 'Cod.&nbsp;Fiscale&nbsp;/&nbsp;P.IVA', data: 'vatNumber', className: 'min-tablet', render: (data, type, row) =>
							{
								return row['vatNumber'] || row['fiscalCode']
							}
						},
						{ title: 'Indirizzo', data: 'mainAddress', className: 'min-tablet' },
						{ title: 'Amministratore', data: 'buildingAdministratorBusinessName', className: 'min-tablet' },
						{ title: 'Referente', data: 'referent', width: '120', className: 'none' },
						{
							title: '', data: 'id', className: 'all adv-col', width: '20', orderable: false, render: (data, type, row) =>
							{
								let actions_list = '';

								let _selectAction = '<a href="#" data-action="select" title="Seleziona questo cliente" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

								if (this._checkBlacklist)
								{
									// check: blacklist
									switch (row['blacklistTypeId'])
									{
										// approvation
										case 1:
											actions_list += '<a href="#" data-action="select" title="E\' richiesta l\'approvazione prima di procedere su questo cliente" mat-ripple class="btn btn-warning btn-fab btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';
											break;

										// blacklist
										case 2:
											actions_list += '<a href="#" onclick="alert(\'Cliente in Blacklist. Impossibile selezionare.\')" title="Cliente in Blacklist. Impossibile selezionare." mat-ripple class="btn btn-danger btn-fab btn-fab-sm"><i class="zmdi zmdi-block"></i></a>';
											break;

										// blacklist
										default:
											actions_list += _selectAction;
											break;
									}
								}
								else
								{
									actions_list += _selectAction;
								}

								return actions_list;
							}
						},
					],
				RowCallback: (row: Node, data: any[] | object, index: number) =>
				{
					const _self = this;

					// select
					$('[data-action="select"]', row).on('click', function (e)
					{
						if (
							(_self._checkBlacklist && data['blacklistTypeId'] == 1 && confirm('Questo cliente richiede approvazione per procedere. Continuare?')) ||
							(_self._checkBlacklist && data['blacklistTypeId'] == null) ||
							!_self._checkBlacklist
						)
						{
							_self.customerSelectionCallback.emit(
								{
									id: data['id'],
									businessName: data['businessName'],
									vatNumber: data['vatNumber'],
									fiscalCode: data['fiscalCode'],
									winWasteCod: data['winWasteCod'] || null,
									blacklistTypeId: data['blacklistTypeId'],
									vehicleTypeId: data['vehicleTypeId'],
									customerType: {
										id: data['customerTypeId'],
										name: data['customerTypeName']
									},
									buildingAdministrator: {
										id: data['buildingAdministratorId'],
										businessName: data['buildingAdministratorBusinessName']
									}
								});
						}
					});
				}
			});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search customer
	search()
	{
		this.getList();
	}
}