import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { ListItemWorkTeam, PurgeWorkGroup } from '../../../../pages/purges/purgeworkgroups/purgeworkgroups.models';

@Component({
	selector: 'purge-work-group-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss']
})

export class PurgeWorkGroupSelectorComponent implements OnInit
{
	// I/O
	@Output() purgeWorkGroupSelectionCallback: EventEmitter<PurgeWorkGroup> = new EventEmitter();

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean = false;

	// input filter
	public inputFilters: any;

	// search form
	public searchForm: FormGroup = this.fb.group(
		{
			operatorFullName: [null],
			vehicleLicensePlate: [null]
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this.getList();
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// get purge work groups list
	getList()
	{
		this._showTable = false;

		const _filters = $.extend({}, this.searchForm.value, this.inputFilters);

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 10,
			Url: this._baseUrl + 'api/shared/globalselectors/purgeWorkGroupsList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Responsabile', data: 'purgeWorkGroupTeamLeaderFullName', width: "250", orderable: true, className: 'all' },
					{
						title: 'Squadre', data: 'purgeWorkGroupTeams', className: 'all', orderable: false, render: (data, type, row) =>
						{
							const workTeams: ListItemWorkTeam[] = JSON.parse(data);

							let result = '<ul class="work_group_teams">';

							workTeams.forEach((workTeam, workTeamIndex) =>
							{
								result += '<li>';

								// team lead
								result += `<strong>
													${(workTeam.lead ? '<i class="zmdi zmdi-star"></i>' : '')}
													<span>${workTeam.lead ? 'Squadra principale' : 'Squadra di supporto ' + workTeamIndex}</span>
											 </strong>`;

								// employees and vehicles
								result += '<ul>';

								// iterate employees
								if (workTeam.employees)
								{
									workTeam.employees.forEach(employee =>
									{
										result += `<li${(employee.teamLeader ? ' class="team_leader"' : '')}>
														<i class="zmdi ${(employee.teamLeader ? 'zmdi-account-circle' : 'zmdi-account')}"></i>
														<div>${employee.lastName} ${employee.firstName}</div>
												 </li>`;
									});
								}

								// iterate vehicles
								if (workTeam.vehicles)
								{
									workTeam.vehicles.forEach(vehicle =>
									{
										result += `<li>
														<i class="zmdi zmdi-truck"></i>
														<div>${vehicle.licensePlate}</div>
												 </li>`;
									});
								}

								result += '</ul>';
								result += '</li>';
							})

							result += '</ul>';

							return result;
						}
					},
					{
						title: '', data: 'id', className: 'all adv-col adv-col-4', width: '40', orderable: false, render: (data, type, row) =>
						{
							const actionsList = '<a href="#" data-action="select" title="Seleziona" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="material-icons">arrow_forward</i></a>';

							return actionsList;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					console.log(data['purgeWorkGroupTeams']);

					_self.purgeWorkGroupSelectionCallback.emit({
						id: data['id'],
						purgeWorkTeams: JSON.parse(data['purgeWorkGroupTeams'])
					});
				});
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search purge work groups
	search()
	{
		this.getList();
	}

	// reset filter
	resetFilter()
	{
		// reset search form
		this.searchForm.reset();

		// getting list
		this.getList();
	}
}