<!--header-->
<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-accounts-alt m-r-10"></i>
		<span>Seleziona gruppo di lavoro</span>
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body-->
<div class="modal-body">

	<!--filters-->
	<div class="pc-form-filters">
		<form [formGroup]="searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Operatore</mat-label>
						<input type="text" matInput formControlName="operatorFullName" appAutofocus />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Automezzo</mat-label>
						<input type="text" matInput formControlName="vehicleLicensePlate" />
					</mat-form-field>
				</div>
				<div class="col d-flex align-items-center">
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>search</mat-icon>
						<span>{{'Commons.Actions.Search' | translate}}</span>
					</button>
					<button type="button" mat-icon-button class="ml-4" (click)="resetFilter()" matTooltip="Rimuovi tutti i filtri" matTooltipPosition="above">
						<mat-icon>undo</mat-icon>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!--results-->
	<div class="table-responsive">
		<table datatable *ngIf="_showTable" [dtOptions]="_dtOptions" class="table table--selectorpurgeworkgroups row-border hover"></table>
	</div>

</div>