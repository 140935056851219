import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { TransportMission, TransportMissionActivity } from '../transportmissions.models';
import { TransportMissionsService } from '../transportmissions.service';

@Component({
	selector: 'change-mission-status-modal',
	templateUrl: './changestatusmodal.component.html',
	styleUrls: ['./changestatusmodal.component.scss']
})

export class TransportMissionChangeStatusComponent
{
	// viewchildren
	@ViewChild('changeTransportMissionStatusModal') changeTransportMissionStatusModal;

	// I/O
	@Input() public mission: TransportMission;
	@Output() changeMissionStatusCallback: EventEmitter<any> = new EventEmitter();

	// mission form
	public _form: FormGroup = this.fb.group(
		{
			id: [null, [Validators.required]],
			newStatusId: [null, [Validators.required]],

			// activities
			activities: this.fb.array([])
		}
	);
	get id() { return this._form.get('id'); }
	get newStatusId() { return this._form.get('newStatusId'); }
	get activities() { return this._form.get('activities') as UntypedFormArray; }

	// constructor
	constructor
		(
			private pcodeServices: PCodeServices,
			private transportMissionsService: TransportMissionsService,
			private fb: FormBuilder,
			public datepipe: DatePipe
		)
	{
	}

	// load mission
	loadMission()
	{
		// patching
		this.id.setValue(this.mission.id);
		this.newStatusId.setValue(this.mission.transportMissionStatusId);

		// load children activities
		this.activities.clear();
		this.mission.activities.forEach((item, index) =>
		{
			// push activity
			this.activities.push(this.createActivityItem(item));
		});

		// update validity controls
		this._form.updateValueAndValidity();
	}

	// create activity item
	createActivityItem(activity: TransportMissionActivity): FormGroup
	{
		const result = this.fb.group(
			{
				id: [activity.id],
				transportMissionActivityStatusId: [activity.transportMissionActivityStatusId],
				transportMissionActivityStatusName: [activity.transportMissionActivityStatusName],
				transportMissionActivityTypeId: [activity.transportMissionActivityTypeId],
				transportMissionActivityTypeName: [activity.transportMissionActivityTypeName],

				// scheduling
				schedulingDate: [activity.schedulingDate],
				schedulingHour: [activity.schedulingHour],
				schedulingMinute: [activity.schedulingMinute],

				newStatusId: [activity.transportMissionActivityStatusId, [Validators.required]]
			});

		return result;
	}

	// change transport mission status
	changeTransportMissionStatus()
	{
		this._form.markAllAsTouched();

		if (this._form.valid)
		{
			const _postValue = this._form.value;

			this.transportMissionsService.changeStatus(_postValue).subscribe(result =>
			{
				this.pcodeServices.notify('success', 'Stato della missione aggiornato');
				this.changeMissionStatusCallback.emit();
				this.changeTransportMissionStatusModal.hide();
			});
		}
	}

	// show modal
	showModal()
	{
		this.changeTransportMissionStatusModal.show();

		// load mission
		this.loadMission();
	}

	// new status id change
	newStatusIdChange()
	{
		this.checkActivityStatusControlsValidity();
	}

	// check activity status controls validity
	checkActivityStatusControlsValidity()
	{
		// reset
		this.activities.controls.forEach(item => { item.get('newStatusId').setValue(null); });

		// check: reapply original value if available
		this.activities.controls.forEach(item =>
		{
			var oldStatus = this.mission.activities.find(x => x.id === item.get('id').value).transportMissionActivityStatusId;

			switch (this.newStatusId.value)
			{
				// in creazione
				case 1:
					{
						item.get('newStatusId').setValue(oldStatus);
					}
					break;

				// programmata
				case 2:
					{
						item.get('newStatusId').setValue(oldStatus);
					}
					break;

				// programmata, completata, annullata
				default:
					{
						if (oldStatus === 3 || oldStatus === 4)
							item.get('newStatusId').setValue(oldStatus);
					}
					break;
			}
		});
	}
};