import { NativeDateAdapter } from "@angular/material/core";

export class DigifranDateAdapter extends NativeDateAdapter
{
	override parse(value: any): Date | null
	{
		if (typeof value === 'string')
		{
			const cleanedValue = value.replace(/[^\d]/g, '');

			// check: user input
			if (cleanedValue.length === 2)
			{
				const day = parseInt(cleanedValue.substring(0, 2), 10);
				const month = new Date().getMonth();
				const year = new Date().getFullYear();
				return new Date(year, month, day);
			}
			if (cleanedValue.length === 4)
			{
				const day = parseInt(cleanedValue.substring(0, 2), 10);
				const month = parseInt(cleanedValue.substring(2, 4), 10) - 1;
				const year = new Date().getFullYear();
				return new Date(year, month, day);
			}
			if (cleanedValue.length === 8)
			{
				const day = parseInt(cleanedValue.substring(0, 2), 10);
				const month = parseInt(cleanedValue.substring(2, 4), 10) - 1;
				const year = parseInt(cleanedValue.substring(4, 8), 10);
				return new Date(year, month, day);
			}
		}
		return super.parse(value);
	}

	override format(date: Date, displayFormat: any): string
	{
		if (date)
		{
			const day = date.getDate().toString().padStart(2, '0');
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const year = date.getFullYear();
			return `${day}/${month}/${year}`;
		}
		return '';
	}

	override getFirstDayOfWeek(): number
	{
		return 1;
	}
}