import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { SetupService } from '../../../../shared/services/app/setup.service';
import { PCodeServices } from '../../../../shared/services/pcode/pcode.service';
import { OfficesService } from '../../../pages/registries/offices/offices.service';
import { EditOfficeInput } from './edit-office.models';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FilesService } from '../../../../shared/services/app/files.service';
import { FileTypesService } from '../../../pages/configurations/filetypes/filetypes.service';
import { Actions } from '../../enums';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { UploadComponent } from '../../../../shared/components/upload/upload.component';
declare let google;

@Component({
	selector: 'edit-office',
	templateUrl: './edit-office.component.html'
})

export class EditOfficeComponent implements OnInit
{
	// I/O
	@Output() editOfficeCallback: EventEmitter<any> = new EventEmitter();
	@Output() hideModalCallback: EventEmitter<any> = new EventEmitter();

	// input filter
	public inputFilters: EditOfficeInput;

	public _nationsList: [];
	public _filteredNationsList: [];
	public _nationsSearchControl: FormControl = new FormControl();

	public _provincesList: [];
	public _filteredProvincesList: [];
	public _provincesSearchControl: FormControl = new FormControl();

	public _townsList: [];
	public _filteredTownsList: [];
	public _townsSearchControl: FormControl = new FormControl();

	// address form
	public _addressForm: FormGroup = this.fb.group(
		{
			id: [0, [Validators.required]],
			customerId: [null],
			supplierId: [null],
			producerId: [null],
			headOffice: [false, [Validators.required]],
			address: ['', [Validators.required]],
			streetNumber: ['', [Validators.required]],
			nationIsoCode: [null, [Validators.required]],
			provinceIstatCode: [null, [Validators.required]],
			townIstatCode: [null, [Validators.required]],
			postalCode: ['', [Validators.required]],
			winWasteProvince: [''],
			provinceName: [''],
			winWasteTown: [''],
			townName: [''],
			latitude: [null],
			longitude: [null],
			referent: [''],
			phoneNumber: [''],
			mobilePhoneNumber: [''],
			faxNumber: [''],
			email: [''],
			pecEmail: [''],
			notes: [''],
			highlightNotes: ['']
		}
	);
	get id_OF() { return this._addressForm.get('id'); }
	get customerId_OF() { return this._addressForm.get('customerId'); }
	get supplierId_OF() { return this._addressForm.get('supplierId'); }
	get producerId_OF() { return this._addressForm.get('producerId'); }
	get headOffice_OF() { return this._addressForm.get('headOffice'); }
	get address_OF() { return this._addressForm.get('address'); }
	get streetNumber_OF() { return this._addressForm.get('streetNumber'); }
	get nationIsoCode_OF() { return this._addressForm.get('nationIsoCode'); }
	get provinceIstatCode_OF() { return this._addressForm.get('provinceIstatCode'); }
	get townIstatCode_OF() { return this._addressForm.get('townIstatCode'); }
	get postalCode_OF() { return this._addressForm.get('postalCode'); }
	get townsList() { return this._addressForm.get('townsList'); }

	// constructor
	constructor
		(
			@Inject('BASE_URL') private baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			private officesService: OfficesService,
			private setupService: SetupService,
			private filesService: FilesService,
			private fileTypesService: FileTypesService,
			public bsModalRef: BsModalRef,
			private datepipe: DatePipe,
			private translateService: TranslateService
		)
	{

	}

	// init
	ngOnInit()
	{
		// init dropdown search
		this._nationsSearchControl.valueChanges.subscribe(() => { this.filterNations(); });
		this._provincesSearchControl.valueChanges.subscribe(() => { this.filterProvinces(); });
		this._townsSearchControl.valueChanges.subscribe(() => { this.filterTowns(); });

		const _nationsObs = this.setupService.getNations();
		const _provincesObs = this.setupService.getProvinces();

		forkJoin([_nationsObs, _provincesObs]).subscribe(results =>
		{
			this._nationsList = results[0] as [];
			this._filteredNationsList = results[0] as [];

			this._provincesList = results[1] as [];
			this._filteredProvincesList = results[1] as [];

			// preload office for edit
			if (this.inputFilters.officeId)
			{
				this.officesService.getSingle(this.inputFilters.officeId).subscribe(result =>
				{
					// loading into form
					this._addressForm.patchValue(result, { emitEvent: true });

					// loading town
					this.loadTowns();

					// update form validators
					this.updateFormValidators();

					// office attachments
					this.initOfficeAttachments();
					this.getOfficeAttachments();
				});
			}
			else
			{
				// reset values
				this._addressForm.reset();

				// initial values
				this.id_OF.setValue(0);
				this.headOffice_OF.setValue(0);
				this.nationIsoCode_OF.setValue('IT');

				if (this.inputFilters.customerId)
					this.customerId_OF.setValue(this.inputFilters.customerId);

				if (this.inputFilters.supplierId)
					this.supplierId_OF.setValue(this.inputFilters.supplierId);

				if (this.inputFilters.producerId)
					this.producerId_OF.setValue(this.inputFilters.producerId);
			}
		});
	}

	// hide modal
	hideModal()
	{
		// hide modal callback
		this.hideModalCallback.emit();

		// hide modal
		this.bsModalRef.hide();
	}

	// insert/update office
	insertUpdateOffice()
	{
		this._addressForm.markAllAsTouched();

		if (this._addressForm.valid)
		{
			this.officesService.insertUpdate(this._addressForm.value)
				.subscribe({
					next: success =>
					{
						this.editOfficeCallback.emit({
							id: success['id'],
							referent: success['referent'],
							phoneNumber: success['phoneNumber'],
							mobilePhoneNumber: success['mobilePhoneNumber'],
						});

						this.hideModal();
					},

					error: error =>
					{
						this.pcodeServices.notify('error', error);
					}
				});
		}
		else
		{
			this.getFormValidationErrors();
		}

	}

	// loading towns
	loadTowns()
	{
		// get towns
		this.setupService.getTowns(this.provinceIstatCode_OF.value).subscribe(result =>
		{
			this._townsList = result as [];
			this._filteredTownsList = result as [];
		});
	}

	// get validation errors
	getFormValidationErrors()
	{
		Object.keys(this._addressForm.controls).forEach(key =>
		{

			const controlErrors: ValidationErrors = this._addressForm.get(key).errors;
			if (controlErrors != null)
			{
				Object.keys(controlErrors).forEach(keyError =>
				{
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	// filter nations
	filterNations()
	{
		const search = this._nationsSearchControl.value;

		if (!search)
			this._filteredNationsList = this._nationsList;
		else
			this._filteredNationsList = (this._nationsList as any[]).filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1) as [];
	}

	// filter provinces
	filterProvinces()
	{
		const search = this._provincesSearchControl.value;

		if (!search)
			this._filteredProvincesList = this._provincesList;
		else
			this._filteredProvincesList = (this._provincesList as any[]).filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1) as [];
	}

	// filter towns
	filterTowns()
	{
		const search = this._townsSearchControl.value;

		if (!search)
			this._filteredTownsList = this._townsList;
		else
			this._filteredTownsList = this._townsList.filter(x => (x['name'] as string).toLowerCase().indexOf(search.toLowerCase()) > -1) as [];
	}

	// search address postal code
	searchAddressPostalCode()
	{
		// google geocoder
		const geocoder = new google.maps.Geocoder();

		let address = '';

		if (this.address_OF.value)
			address += `${this.address_OF.value}`;
		if (this.streetNumber_OF.value)
			address += ` ${this.streetNumber_OF.value}`;
		if (this.townIstatCode_OF.value)
			address += `, ${this._townsList.find(x => x['istatCode'] === this.townIstatCode_OF.value)['name']}`;
		if (this.provinceIstatCode_OF.value)
			address += ` (${this._provincesList.find(x => x['istatCode'] === this.provinceIstatCode_OF.value)['name']})`;

		if (address)
		{
			geocoder.geocode({ address }, (results, status) =>
			{
				if (status === google.maps.GeocoderStatus.OK && results.length > 0)
				{
					const addressComponents = results[0].address_components;
					const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));

					if (postalCodeComponent)
						this.postalCode_OF.setValue(postalCodeComponent.long_name);
					else
						this.pcodeServices.notify('error', 'CAP non trovato');
				}
				else
				{
					this.pcodeServices.notify('error', 'CAP non trovato');
				}
			});
		}
		else
		{
			this.pcodeServices.notify('error', 'Compila l\'indirizzo completo');
		}
	}

	// nation selection change
	nationSelectionChange()
	{
		this.updateFormValidators();
	}

	// update form validators
	updateFormValidators()
	{
		if (this.nationIsoCode_OF.value === 'IT')
		{
			this.streetNumber_OF.enable();
			this.provinceIstatCode_OF.enable();
			this.townIstatCode_OF.enable();
			this.postalCode_OF.enable();
		}
		else
		{
			this.streetNumber_OF.disable();
			this.provinceIstatCode_OF.disable();
			this.townIstatCode_OF.disable();
			this.postalCode_OF.disable();
		}
	}

	/* ==================================
	= Attachments
	================================== */

	// viewchildren
	@ViewChild('officeAttachmentsUploadModal') officeAttachmentsUploadModal;
	@ViewChild("officeAttachmentsUploader") officeAttachmentsUploader: UploadComponent;
	@ViewChild('updateFileModal') updateFileModal;

	public _dtOptionsAttachments = {};
	public _showTableAttachments: boolean = false;
	public _fileTypes: any;

	// update file form
	_updateFileForm: FormGroup = this.fb.group(
		{
			id: ['', [Validators.required]],
			name: ['', [Validators.required]],
			fileTypeId: ['', [Validators.required]],
			expiryDate: [null]
		});
	get idRN() { return this._updateFileForm.get('id'); }
	get nameRN() { return this._updateFileForm.get('name'); }
	get fileTypeIdRN() { return this._updateFileForm.get('fileTypeId'); }
	get expiryDateRN() { return this._updateFileForm.get('expiryDate'); }

	// initialization
	initOfficeAttachments()
	{
		// init uploader
		this.officeAttachmentsUploader.url = this.baseUrl + 'api/registries/offices/uploadOfficeAttachments';
		this.officeAttachmentsUploader.data =
		{
			OfficeId: this.inputFilters.officeId,
			ActionAlias: Actions.OfficeAttachments
		};
		this.officeAttachmentsUploader.initialize();
	}

	// get attachments
	getOfficeAttachments()
	{
		this._showTableAttachments = false;

		const _filters =
		{
			officeId: this.inputFilters.officeId
		}

		this._dtOptionsAttachments = this.pcodeServices.initDatatable(
			{
				Url: this.baseUrl + 'api/registries/offices/getAttachments',
				Filters: _filters,
				Order: [3, 'desc'],
				PageSize: 5,
				Columns:
					[
						{ title: 'Nome', data: 'name', className: 'all', orderable: false },
						{ title: 'Dimensione', data: 'size', width: '20', className: 'min-tablet', orderable: false },
						{ title: 'Formato', data: 'extension', width: '20', className: 'min-tablet', orderable: false },
						{
							title: 'Data&nbsp;inserimento', data: 'insertDate', width: '80', className: 'min-tablet', orderable: false, render: (data, type, row) =>
							{
								if (data)
									return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm:ss');
								else
									return '';
							}
						},
						{
							title: '', data: 'id', className: 'all adv-col adv-col-2', width: '120', orderable: false, render: (data, type, row) =>
							{
								let actions_list = '';

								let _editAction = '<a href="#" data-action="edit" title="Rinomina" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="material-icons">edit</i></a>';
								let _deleteAction = '<a href="#" data-action="delete" title="Elimina" mat-ripple class="btn btn-danger btn-fab btn-flat btn-fab-sm"><i class="material-icons">delete_outline</i></a>';
								let _downloadAction = '<a href="#" data-action="download" title="Scarica" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="material-icons">download</i></a>';

								actions_list += _deleteAction;
								actions_list += _downloadAction;
								actions_list += _editAction;

								return actions_list;
							}
						}
					],
				RowCallback: (row: Node, data: any[] | object, index: number) =>
				{
					const _self = this;

					// edit
					$('[data-action="edit"]', row).on('click', function (e)
					{
						_self.showUpdateOfficeAttachmentModal(data['id'], data['fileTypeId'], data['name'], data['expiryDate'])
					});

					// download
					$('[data-action="download"]', row).on('click', function (e)
					{
						_self.downloadOfficeAttachment(data['id'], data['name'] + '.' + data['extension']);
					});

					// delete
					$('[data-action="delete"]', row).on('click', function (e)
					{
						_self.deleteOfficeAttachment(data['id']);
					});
				}
			});

		setTimeout(() => { this._showTableAttachments = true }, 0);
	}

	// update office attachment
	showUpdateOfficeAttachmentModal(id: number, type: string, name: string, expiryDate?: Date)
	{
		this.fileTypesService.getByActionAlias(Actions.OfficeAttachments).subscribe(result =>
		{
			this._fileTypes = result;

			// patching form
			this.idRN.setValue(id);
			this.fileTypeIdRN.setValue(type);
			this.nameRN.setValue(name);

			this.updateFileModal.show();
		});
	}
	updateOfficeAttachment()
	{
		this.officesService.updateAttachment(
			{
				FileId: this.idRN.value,
				OfficeId: this.inputFilters.officeId,
				Name: this.nameRN.value,
				FileTypeId: this.fileTypeIdRN.value
			}
		).subscribe(
			{
				next: success =>
				{
					// reload office attachments
					this.getOfficeAttachments();

					// hide modal
					this.updateFileModal.hide();

					// notification
					this.pcodeServices.notify('success', 'Documento aggiornato con successo');
				},
				error: error =>
				{
					this.pcodeServices.notify('error', error.message);
				}
			});
	}

	// upload office attachments
	showOfficeAttachmentsUploadModal()
	{
		this.officeAttachmentsUploadModal.show();
	}
	uploadOfficeAttachmentCompleteCallback()
	{
		// reload attachments
		this.getOfficeAttachments();

		// hide upload modal
		this.officeAttachmentsUploadModal.hide();
	}

	// download
	downloadOfficeAttachment(fileId, fileName)
	{
		this.filesService.download(fileId).subscribe({
			next: blob => { saveAs(blob, fileName); },
			error: error => { }
		});
	}

	// delete office attachment
	deleteOfficeAttachment(id: number)
	{
		if (confirm(this.translateService.instant('Commons.Alerts.ConfirmDelete')))
		{
			this.filesService.delete(id).subscribe({
				next: success =>
				{
					// reload attachments
					this.getOfficeAttachments();

					// notification
					this.pcodeServices.notify('success', 'Documento eliminato con successo');
				},
				error: error =>
				{
					this.pcodeServices.notify('error', error.message);
				}
			});
		}
	}
}