import { DatePipe, KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InterventionsService } from '../../../../pages/purges/interventions/interventions.service';
import { PurgeWorkGroupsService } from '../../../../pages/purges/purgeworkgroups/purgeworkgroups.service';

@Component({
	selector: 'purge-work-group-daily-worksheet',
	templateUrl: './daily-worksheet.component.html',
	styleUrls: ["./daily-worksheet.component.scss"]
})

export class PurgeWorkGroupDailyWorksheetComponent
{
	// variables
	public employees: KeyValue<number, string>[];
	public _activities: any[];

	// search form
	public searchForm: FormGroup = this.fb.group(
		{
			worksheetDate: [new Date(), [Validators.required]],
			operatorId: [null, [Validators.required]],
		}
	);
	get worksheetDate() { return this.searchForm.get('worksheetDate'); }
	get operatorId() { return this.searchForm.get('operatorId'); }

	// constructor
	constructor
		(
			private purgeWorkGroupsService: PurgeWorkGroupsService,
			private interventionsService: InterventionsService,
			public datepipe: DatePipe,
			private fb: FormBuilder
		)
	{

	}

	// init
	ngOnInit()
	{
		this.purgeWorkGroupsService.getAllEmployees().subscribe(result =>
		{
			this.employees = result;
		});
	}

	// search
	search(): void
	{
		if (this.searchForm.valid)
			this.loadWorksheet();
	}

	// get worksheet
	loadWorksheet()
	{
		// deafault filters
		let _filters =
		{
			operatorId: this.operatorId.value,
			startDate: this.datepipe.transform(this.worksheetDate.value, 'yyyy-MM-dd'),
			endDate: this.datepipe.transform(this.worksheetDate.value, 'yyyy-MM-dd'),

			// sorting and paging
			Order: [{ Column: 6, Dir: 'asc' }],
			Start: 0,
			Length: 10000
		}

		this.interventionsService.getList(_filters).subscribe(result =>
		{
			this._activities = result['data'];
		});
	}
}