<div class="curr-date">
    <span class="curr-dd">{{currentDate | date:'EEEE' | titlecase}}</span>
    <span class="curr-mmmm-dd">{{currentDate | date:'dd MMMM' }}</span>
</div>
<!--<ng-container>
    <header class="curr-weather">
        <i class="wi {{getWeatherIcon(weatherInfo?.currently.icon)}} m-r-10"></i>
        <div class="curr-temp-wrapper">
            <span class="curr-temp">{{getCelsius(weatherInfo?.currently.temperature) | number: '1.0-0'}}</span>
            <sup class="curr-sup">°</sup>
            <span class="curr-f">C</span>
        </div>
        <h6>{{weatherInfo?.currently.summary}}</h6>
    </header>
    <ul class="forcast">
        <li *ngFor="let day of weatherInfo?.daily.data; let i = index" title="{{day.summary}}">
            <div *ngIf="i > 0" class="forcast-temp-wrapper">
                <div class="media">
                    <span class="forcast-day forcast-one">{{day.time * 1000 | date: 'EE d MMM'}}</span>
                    <div class="media-body">
                        <i class="wi {{getWeatherIcon(day.icon)}}"></i>
                        <span class="curr-temp">{{getCelsius(day.temperatureLow) | number: '1.0-0'}} ~ {{getCelsius(day.temperatureHigh) | number: '1.0-0'}}</span>
                        <sup class="curr-sup">°</sup>
                        <span class="curr-f">C</span>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</ng-container>-->