import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from '@angular-ex/uploader';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { FileTypesService } from '../../../admin/pages/configurations/filetypes/filetypes.service';
import { DigifranDateAdapter } from '../../directives/date-adapter.directive';
import { PCodeServices } from '../../services/pcode/pcode.service';

@Component({
	selector: 'pc-uploader',
	templateUrl: './upload.component.html',
	styleUrls: ["./upload.component.scss"],
	providers: [
		{ provide: DateAdapter, useClass: DigifranDateAdapter },
		{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
	]
})
export class UploadComponent implements OnInit
{
	@ViewChild('uploader') uploaderInput: ElementRef;

	// input parameters
	@Input() url: string;
	@Input() data: any = null;
	@Input() allowedContentTypes: string[] = null;
	@Input() applicationAlias: string = null;
	@Input() showExpiryDate: boolean = true;

	@Output() uploadCompleteCallback: EventEmitter<any> = new EventEmitter();

	formData: FormData;
	files: UploadFile[];
	uploadInput: EventEmitter<UploadInput>;
	humanizeBytes: Function;
	dragOver: boolean;
	options: UploaderOptions;
	uploading = false;

	_filetypes: any[];

	// upload form
	items: UntypedFormArray;
	public _form: FormGroup = this.fb.group(
		{
			items: this.fb.array([])
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private baseUrl: string,
			private pcodeService: PCodeServices,
			private translateService: TranslateService,
			private fileTypesService: FileTypesService,
			private fb: FormBuilder
		)
	{
		this.options =
		{
			concurrency: 1,
			maxUploads: 9999
		};
		this.files = [];
		this.uploadInput = new EventEmitter<UploadInput>();
		this.humanizeBytes = humanizeBytes;
	}

	// init
	ngOnInit()
	{

	}

	// call initialize to setup settings for this uploader
	initialize()
	{
		let _actionAlias = this.data.ActionAlias || null;
		let _fileTypesObs = this.fileTypesService.getByActionAlias(_actionAlias, true);
		this.options.allowedContentTypes = this.allowedContentTypes;

		forkJoin([_fileTypesObs]).subscribe(results =>
		{
			this._filetypes = results[0] as any[];
		});
	}

	// upload output
	onUploadOutput(output: UploadOutput): void
	{
		if (output.type === 'start' && typeof output.file !== 'undefined')
		{

		}

		if (output.type === 'allAddedToQueue')
		{
			//this.startUpload();
		}

		else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined')
		{
			this.files.push(output.file);

			const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);

			this.addItem(output.file, output.file.name, this._filetypes.length === 1 ? this._filetypes[0].id : null, null);
		}

		else if (output.type === 'uploading' && typeof output.file !== 'undefined')
		{
			this.uploading = true;

			const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);

			this.files[index] = output.file;
		}
		else if (output.type === 'cancelled' || output.type === 'removed')
		{
			this.files = this.files.filter((file: UploadFile) => file !== output.file);
		}
		else if (output.type === 'dragOver')
		{
			this.dragOver = true;
		}
		else if (output.type === 'dragOut')
		{
			this.dragOver = false;
		}
		else if (output.type === 'drop')
		{
			this.dragOver = false;
		}
		else if (output.type === 'rejected' && typeof output.file !== 'undefined')
		{
			this.pcodeService.notify('error', output.file.name + ': estensione o dimensione non consentita', 'Attenzione!')
			console.log(output.file.name + ' rejected');
		}

		this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);

		if (this.files.length == 0)
		{
			this.uploading = false;

			if (output.type === 'done')
			{
				this.pcodeService.notify('success', this.translateService.instant('Shared.Upload.Notifications.Success'));
				this.uploadCompleteCallback.emit();

				this.files = [];

				this.items = this._form.get('items') as UntypedFormArray;
				while (this.items.length !== 0)
					this.items.removeAt(0)

				this.uploaderInput.nativeElement.value = '';
			}
		}
	}

	// start upload
	startUpload(): void
	{
		this.markFormGroupTouched(this._form);

		if (this._form.valid)
		{
			// on upload: custom check
			this.onUploadCustomCheck();

			const _filesInfos = this._form.controls.items['controls'] as FormGroup[];

			this.files.forEach((_file, index) =>
			{
				const _fileInfo = _filesInfos[index];

				this.data.FileName = _fileInfo.value['fileName'];
				this.data.FileTypeId = _fileInfo.value['fileTypeId'];
				this.data.ExpiryDate = _fileInfo.value['expiryDate'];

				const event: UploadInput = {
					type: 'uploadFile',
					url: this.url,
					method: 'POST',
					headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('access_token') },
					data: { SerializedData: JSON.stringify(this.data) },
					file: _file
				};

				this.uploadInput.emit(event);
			});
		}
	}

	// on upload custom check
	onUploadCustomCheck()
	{
		if (this.applicationAlias !== null)
		{
			switch (this.applicationAlias)
			{
				// case: customer offers
				case 'customersoffers':
					this.onUploadCustomCheck_CustomerSupplierOffers();
					break;
				// case: supplier offers
				case 'suppliersoffers':
					this.onUploadCustomCheck_CustomerSupplierOffers();
					break;
			}
		}
	}

	// on upload custom check: customer offers
	onUploadCustomCheck_CustomerSupplierOffers()
	{
		const _defaultDate = (new Date()).getFullYear().toString() + "-12-31";
		let _existsEmptyDate = false;

		const _filesInfos = this._form.controls.items['controls'] as FormGroup[];

		_filesInfos.forEach((formGroup) =>
		{
			if (!formGroup.get('expiryDate').value)
				_existsEmptyDate = true;
		});

		// check: at least one empty date
		if (_existsEmptyDate)
		{
			if (confirm('Impostare le scadenze vuote al 31 Dicembre ' + (new Date()).getFullYear().toString() + '?'))
			{
				_filesInfos.forEach((formGroup) =>
				{
					if (!formGroup.get('expiryDate').value)
						formGroup.get('expiryDate').setValue(_defaultDate);
				});
			}
		}
	}

	// cancel upload
	cancelUpload(id: string): void
	{
		this.uploadInput.emit({ type: 'cancel', id: id });
	}

	// remove file
	removeFile(id: string): void
	{
		this.uploadInput.emit({ type: 'remove', id: id });
	}

	// remove all files
	removeAllFiles(): void
	{
		this.uploadInput.emit({ type: 'removeAll' });
	}

	// mark as touched
	markFormGroupTouched(formGroup: FormGroup)
	{
		(<any>Object).values(formGroup.controls).forEach(control =>
		{
			control.markAsTouched();

			if (control.controls)
			{
				this.markFormGroupTouched(control);
			}
		});
	}

	// adding custom info
	createItem(file: UploadFile, fileName: string, fileTypeId?: number, expiryDate?: Date): FormGroup
	{
		return this.fb.group({
			file: file,
			fileName: [fileName, Validators.required],
			fileTypeId: [fileTypeId, Validators.required],
			expiryDate: expiryDate,
		});
	}

	// add item
	addItem(file: UploadFile, fileName: string, fileTypeId?: number, expiryDate?: Date): void
	{
		this.items = this._form.get('items') as UntypedFormArray;
		this.items.push(this.createItem(file, fileName, fileTypeId, expiryDate));
	}
}