<!--header-->
<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-accounts-alt m-r-10"></i>Seleziona produttore
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body-->
<div class="modal-body">

	<!--filters-->
	<div class="pc-form-filters">
		<form [formGroup]="_searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Ragione sociale</mat-label>
						<input type="text" matInput formControlName="businessName" #businessName />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>C.F. / Partita IVA</mat-label>
						<input type="text" matInput formControlName="fiscalVatCode" maxlength="16" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Indirizzo</mat-label>
						<input type="text" matInput formControlName="address" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Referente</mat-label>
						<input type="text" matInput formControlName="referent" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Codice WinWaste</mat-label>
						<input type="text" matInput formControlName="winWasteCode" />
					</mat-form-field>
				</div>
				<div class="col d-flex align-items-center">
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>search</mat-icon>
						<span>{{'Commons.Actions.Search' | translate}}</span>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!--results-->
	<div class="table-responsive">
		<table datatable *ngIf="_showTable" [dtOptions]="_dtOptions" class="table row-border hover"></table>
	</div>

</div>