import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class pCodePaginatorIntl extends MatPaginatorIntl
{
	itemsPerPageLabel = 'Items per page';
	nextPageLabel = 'Next page';
	previousPageLabel = 'Previous page';
	lastPageLabel = 'Last page';
	firstPageLabel = "First page";

	constructor(private translate: TranslateService)
	{
		super();

		// on lang change
		this.translate.onLangChange.subscribe(() =>
		{
			this.loadLocalized();
		});

		// load localized
		this.loadLocalized();
	}

	// load localized
	loadLocalized()
	{
		this.itemsPerPageLabel = this.translate.instant('Commons.Pagination.ItemsPerPageLabel');
		this.nextPageLabel = this.translate.instant('Commons.Pagination.NextPageLabel');
		this.previousPageLabel = this.translate.instant('Commons.Pagination.PreviousPageLabel');
		this.lastPageLabel = this.translate.instant('Commons.Pagination.LastPageLabel');
		this.firstPageLabel = this.translate.instant('Commons.Pagination.FirstPageLabel');

		// update interfaces
		this.changes.next();
	}

	// get range label
	getRangeLabel = function (page, pageSize, length)
	{
		switch (this.locale)
		{
			case 'it':
				if (length === 0 || pageSize === 0)
					return '0 di ' + length;
				break;

			default:
				if (length === 0 || pageSize === 0)
					return '0 of ' + length;
				break;
		}

		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

		switch (this.locale)
		{
			case 'it':
				return startIndex + 1 + ' - ' + endIndex + ' di ' + length;

			default:
				return startIndex + 1 + ' - ' + endIndex + ' of ' + length;
		}
	};
}