import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';

@Component({
	selector: 'transport-working-group-selector',
	templateUrl: './selector.component.html',
	styleUrls: ["./selector.component.scss"],
})

export class TransportWorkingGroupSelectorComponent
{
	@ViewChild('listModal') listModal;
	@ViewChild('autofocus') autofocus: ElementRef;

	@Input() endpointUrl: string;
	@Input() workSheetDefaultdate: Date;
	@Input() scopeId: number;
	@Output() workingGroupselectionCallback: EventEmitter<any> = new EventEmitter();

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable = false;

	public _searchForm: FormGroup = this.fb.group(
		{
			operator: [''],
			vehicle: ['']
		}
	);
	get operator() { return this._searchForm.get('operator'); }
	get vehicle() { return this._searchForm.get('vehicle'); }

	constructor(
		@Inject('BASE_URL') private _baseUrl: string,
		private pcodeServices: PCodeServices,
		private fb: FormBuilder
	)
	{

	}

	// show selector modal
	showModal()
	{
		this.getList();
	}

	// get working groups filtered list
	getList()
	{
		this._showTable = false;

		const _filters =
		{
			scopeId: this.scopeId,
			operator: (this.operator.value ? this.operator.value : ''),
			vehicle: (this.vehicle.value ? this.vehicle.value : ''),
		}

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 10,
			Url: this._baseUrl + (this.endpointUrl || 'api/purges/workinggroups/getList'),
			Order: [1, 'asc'],
			Columns:
				[
					{ title: 'Operatore', data: 'operatorFullName', className: 'all' },
					{ title: 'Collaboratore', data: 'collaboratorFullName', className: 'all' },
					{ title: 'Mezzo principale', data: 'mainVehicleName', className: 'min-desktop' },
					{ title: 'Mezzo secondario', data: 'secondaryVehicleName', className: 'min-desktop' },
					{
						title: '', data: 'id', className: 'all adv-col', width: '100', orderable: false, render: (data, type, row) =>
						{
							let actionsList = '';

							// select action
							const _selectAction = '<a href="#" data-action="select" title="Seleziona questo gruppo di lavoro" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';
							actionsList += _selectAction;

							return actionsList;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.workingGroupselectionCallback.emit({
						id: data['id'],
						operator:
						{
							id: data['operatorId'],
							name: data['operatorFullName'],
							firstName: data['operatorFirstName'],
							lastName: data['operatorLastName'],
							adrExpirationDate: data['operatorAdrExpirationDate']
						},
						collaborator:
						{
							id: data['collaboratorId'],
							name: data['collaboratorFullName'],
							firstName: data['collaboratorFirstName'],
							lastName: data['collaboratorLastName'],
							adrExpirationDate: data['collaboratorAdrExpirationDate']
						},
						collaborator2:
						{
							id: data['collaboratorId2'],
							name: data['collaborator2FullName'],
							firstName: data['collaborator2FirstName'],
							lastName: data['collaborator2LastName'],
							adrExpirationDate: data['collaborator2AdrExpirationDate']
						},
						collaborator3:
						{
							id: data['collaboratorId3'],
							name: data['collaborator3FullName'],
							firstName: data['collaborator3FirstName'],
							lastName: data['collaborator3LastName'],
							adrExpirationDate: data['collaborator3AdrExpirationDate']
						},
						mainVehicle:
						{
							id: data['mainVehicleId'],
							name: data['mainVehicleName'],
							licensePlate: data['mainVehicleLicensePlate'],
							siaNumber: data['mainVehicleSiaNumber'],
						},
						secondaryVehicle:
						{
							id: data['secondaryVehicleId'],
							name: data['secondaryVehicleName'],
							licensePlate: data['secondaryVehicleLicensePlate'],
							siaNumber: data['secondaryVehicleSiaNumber'],
						},
						thirdVehicle:
						{
							id: data['thirdVehicleId'],
							name: data['thirdVehicleName'],
							licensePlate: data['thirdVehicleLicensePlate'],
							siaNumber: data['thirdVehicleSiaNumber'],
						}
					});
					_self.listModal.hide();
				});
			},
			DrawCallback: (settings) =>
			{
				this.listModal.show();

				setTimeout(() => { this.autofocus.nativeElement.focus(); }, 500);
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search
	search()
	{
		this.getList();
	}
}