import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { defaultMapStyle } from '../../shared.models';
import { GpsHistory } from './gps-history-map.models';

declare let google: any;

@Component({
	selector: 'gps-history-map',
	templateUrl: './gps-history-map.component.html',
	styleUrl: './/gps-history-map.component.scss'
})

export class GpsHistoryMapComponent implements OnInit
{
	// I/O
	@Input() locationHistory: GpsHistory[];
	@Input() highPrecision: boolean;
	@Input() additionalMarkers: any[];
	@Input() initializeOnInit: boolean = true;

	// viewchildren
	@ViewChild('navigationMap') mapElement: ElementRef;

	// variables
	public currentLocationIndex: number = 0;
	public interventionRegistryMapsSliders: number[] = [];
	private mainOfficeCoordinates = { lat: 45.648942, lng: 9.821770 };

	public totalDistance = 0;
	public maxSpeed = 0;

	// google map
	private googleMap;
	private currentPositionMarker;
	private mainOfficeMarker;
	private mapBounds = new google.maps.LatLngBounds();
	private directionsService = new google.maps.DirectionsService();
	private directionsRenderer = new google.maps.DirectionsRenderer();
	private polyline = new google.maps.Polyline();

	// constructor
	constructor
		(
			private cd: ChangeDetectorRef
		)
	{

	}

	// init
	ngOnInit()
	{
		if (this.initializeOnInit)
			this.initComponent();
	}

	// initialize component
	initComponent()
	{
		// check: high precision allows max 25 points
		if (this.highPrecision)
			this.locationHistory = this.getEquidistantElements(this.locationHistory, 25);

		// default current location is the last one
		this.currentLocationIndex = this.locationHistory && this.locationHistory.length > 0 ? this.locationHistory.length - 1 : 0;

		// initialize map
		this.initMap();
	}

	// intialize map
	initMap()
	{
		this.cd.detectChanges();

		// clear existing map and layers
		this.clearExistingMap();

		// google map
		if (this.mapElement)
		{
			this.googleMap = new google.maps.Map(this.mapElement.nativeElement,
				{
					// commons
					zoom: 15,
					center: this.mainOfficeCoordinates,
					mapTypeId: google.maps.MapTypeId.TERRAIN,
					fullscreenControl: false,

					// zoom
					zoomControl: true,
					zoomControlOptions: { position: google.maps.ControlPosition.LEFT_TOP },

					// street view
					streetViewControl: true,
					streetViewControlOptions: { position: google.maps.ControlPosition.LEFT_TOP },

					// pan
					panControl: true,
					panControlOptions: { position: google.maps.ControlPosition.LEFT_TOP },

					// map styles
					styles: defaultMapStyle
				});

			// insert main office marker
			this.insertMainOfficeMarker();

			// insert additional markers
			this.insertAdditionalMarkers();

			// update current marker
			this.updateCurrentMarker(true);

			// create history path
			this.createHistoryPath();
		}
	}

	// clear existing map
	clearExistingMap()
	{
		// clear main office marker
		if (this.mainOfficeMarker)
		{
			this.mainOfficeMarker.setMap(null);
			this.mainOfficeMarker = null;
		}

		// clear current marker
		if (this.currentPositionMarker)
		{
			this.currentPositionMarker.setMap(null);
			this.currentPositionMarker = null;
		}

		// clear existing rendered path
		if (this.directionsRenderer)
			this.directionsRenderer.setMap(null);

		// clear existing polyline
		if (this.polyline)
			this.polyline.setMap(null);

		// clear google map
		if (this.googleMap)
			this.googleMap = null;

		// clear map html element
		if (this.mapElement)
			this.mapElement.nativeElement.innerHtml = '';

		this.cd.detectChanges();
	}

	// stop drag scroll
	stopDragScroll(event: MouseEvent): void
	{
		event.stopPropagation();
	}

	// map slider navigation
	navigationMapSliderChange()
	{
		this.updateCurrentMarker();
	}
	navigationMapSliderPrev()
	{
		if (this.currentLocationIndex > 0)
		{
			this.currentLocationIndex--;
			this.updateCurrentMarker();
		}
	}
	navigationMapSliderNext()
	{
		if (this.currentLocationIndex < this.locationHistory.length - 1)
		{
			this.currentLocationIndex++;
			this.updateCurrentMarker();
		}
	}

	// insert main office marker
	insertMainOfficeMarker()
	{
		if (!this.mainOfficeMarker)
		{
			// main office marker
			this.mainOfficeMarker = new google.maps.Marker(
				{
					position: this.mainOfficeCoordinates,
					map: this.googleMap,
					title: 'Franchini Servizi Ecologici'.toUpperCase(),
					label:
					{
						text: 'F',
						fontSize: '14px',
						color: "#fff"
					},
					icon:
					{
						path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
						scale: 1.4,
						labelOrigin: new google.maps.Point(0, -28),
						fillColor: '#607188',
						fillOpacity: 1,
						strokeColor: '#ffffff',
						strokeWeight: 2
					}
				});

			// extend bounds
			this.mapBounds.extend(this.mainOfficeCoordinates);

			// fit bounds
			this.googleMap.fitBounds(this.mapBounds);
		}
	}

	// insert additional markers
	insertAdditionalMarkers()
	{
		if (this.additionalMarkers)
		{
			this.additionalMarkers.forEach(marker =>
			{
				marker.setMap(this.googleMap);
			})
		}
	}

	// update current marker
	updateCurrentMarker(fitBounds = false)
	{
		if (this.locationHistory && this.locationHistory.length > 0)
		{
			// current position coordinates
			let currentPositionCoordinates =
			{
				lat: this.locationHistory[this.currentLocationIndex].latitude,
				lng: this.locationHistory[this.currentLocationIndex].longitude
			};

			// current position marker
			if (!this.currentPositionMarker)
			{
				this.currentPositionMarker = new google.maps.Marker(
					{
						position: currentPositionCoordinates,
						map: this.googleMap,
						title: `Posizione corrente`,
						label:
						{
							text: `O`,
							fontSize: '14px',
							color: "#fff"
						},
						icon:
						{
							path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
							scale: 1.4,
							labelOrigin: new google.maps.Point(0, -28),
							fillColor: '#cc4bff',
							fillOpacity: 1,
							strokeColor: '#ffffff',
							strokeWeight: 2
						},
						zIndex: 99999999
					});
			}
			else
			{
				this.currentPositionMarker.setPosition(currentPositionCoordinates);
			}

			// extend bounds
			this.mapBounds.extend(currentPositionCoordinates);

			// fit bounds
			if (fitBounds)
				this.googleMap.fitBounds(this.mapBounds);

			// center map on current position
			this.googleMap.setCenter(currentPositionCoordinates);
		}
		else
		{
			this.googleMap.fitBounds(this.mapBounds);
		}
	}

	// insert history path
	createHistoryPath()
	{
		// check: high precision
		if (this.highPrecision)
		{
			// directions service
			this.directionsService = new google.maps.DirectionsService();
			this.directionsRenderer = new google.maps.DirectionsRenderer(
				{
					map: this.googleMap,
					suppressMarkers: true,
					preserveViewport: true,
					polylineOptions:
					{
						strokeColor: "#cc4bff",
						strokeOpacity: 0.75,
						strokeWeight: 3,
						icons: [
							{
								icon:
								{
									path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
									scale: 1.75
								},
								offset: '100%',
								repeat: '200px'
							}]
					}
				});

			// get waypoints from location history
			const waypoints = this.locationHistory
				.map(position => ({
					location: new google.maps.LatLng(position.latitude, position.longitude),
					stopover: true
				}));

			// get origin/destination
			const origin = new google.maps.LatLng(this.locationHistory[0].latitude, this.locationHistory[0].longitude);
			const destination = new google.maps.LatLng
				(
					this.locationHistory[this.locationHistory.length - 1].latitude,
					this.locationHistory[this.locationHistory.length - 1].longitude
				);

			// directions request
			const request =
			{
				origin: origin,
				destination: destination,
				travelMode: google.maps.DirectionsTravelMode.DRIVING,
				waypoints: waypoints
			};

			// route directions
			this.directionsService.route(request, (result, status) =>
			{
				if (status === google.maps.DirectionsStatus.OK)
				{
					this.directionsRenderer.setDirections(result);

					// calculate speed and distance between points
					const route = result.routes[0];

					for (let i = 0; i < route.legs.length - 1; i++)
					{
						const distance = route.legs[i].distance.value / 1000;
						const duration = route.legs[i].duration.value / 3600;

						// partial distance
						this.locationHistory[i].distance = route.legs[i].distance.value;

						// partial speed
						if (distance && duration)
							this.locationHistory[i].speed = (distance / duration);
						else
							this.locationHistory[i].speed = null;
					}

					// calculate totals
					this.calculateTotals();
				}
				else
					console.error(`Directions request failed due to ${status}`);
			});

			// extend bounds
			waypoints.forEach(point => this.mapBounds.extend(point.location));
		}

		// low precision
		else
		{
			// calculate speed and distance between points
			this.locationHistory.forEach((currentPoint, index) =>
			{
				if (index > 0)
				{
					const previousPoint = this.locationHistory[index - 1];

					// partial speed
					currentPoint.speed = this.calculateSpeeds(previousPoint, currentPoint);

					// partial distance
					currentPoint.distance = this.haversineDistance(previousPoint.latitude, previousPoint.longitude, currentPoint.latitude, currentPoint.longitude);
				}
				else
				{
					currentPoint.speed = 0;
					currentPoint.distance = 0;
				}
			});

			// calculate totals
			this.calculateTotals();

			// path
			const path = this.locationHistory.map(x => (
				{
					lat: x.latitude,
					lng: x.longitude
				}));

			// polyline
			this.polyline = new google.maps.Polyline(
				{
					map: this.googleMap,
					path: path,
					geodesic: true,
					strokeColor: "#cc4bff",
					strokeOpacity: 0.75,
					strokeWeight: 3,
					icons: [
						{
							icon:
							{
								path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
								scale: 1.75
							},
							offset: '100%',
							repeat: '200px'
						}]
				});

			// extend bounds
			path.forEach(point => this.mapBounds.extend(point));
		}

		// fit bounds
		this.googleMap.fitBounds(this.mapBounds);
	}

	// calculate totals
	calculateTotals()
	{
		// calculate total distance
		const pointsDistance = this.locationHistory.filter(x => x.distance).map(x => { return x.distance; });
		this.totalDistance = this.locationHistory.reduce((sum, current) => sum + current.distance, 0) / 1000;

		// calculate max speed
		const pointsSpeed = this.locationHistory.filter(x => x.speed).map(x => { return x.speed; });
		this.maxSpeed = Math.max(...pointsSpeed);
	}

	// calculate speed
	calculateSpeeds(prevPoint: GpsHistory, currentPoint: GpsHistory): number
	{
		// distance from haversine
		const distance = this.haversineDistance(prevPoint.latitude, prevPoint.longitude, currentPoint.latitude, currentPoint.longitude);

		// time elapsed
		const timeElapsed = (new Date(currentPoint.timestamp).getTime() - new Date(prevPoint.timestamp).getTime()) / 1000;

		// speed (m/s)
		const speed = distance / timeElapsed;

		// speed (km/h)
		return speed * 3.6;
	}

	// haversine distance
	haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number
	{
		const toRadians = (degree: number): number => degree * (Math.PI / 180);
		const R = 6371e3; // earth radius (m)

		const φ1 = toRadians(lat1);
		const φ2 = toRadians(lat2);
		const Δφ = toRadians(lat2 - lat1);
		const Δλ = toRadians(lon2 - lon1);

		const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) *
			Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const distance = R * c;
		return distance;
	}

	// get equidistant elements
	getEquidistantElements<T>(array: T[], maxElements: number): T[]
	{
		const totalElements = array.length;
		const interval = Math.ceil(totalElements / maxElements);

		const result = [];
		for (let i = 0; i < totalElements; i += interval)
		{
			result.push(array[i]);
			if (result.length === maxElements) break;
		}

		return result;
	}
}