<!--header-->
<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-accounts-alt m-r-10"></i>
		{{_modalTItle}}
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body-->
<div class="modal-body">

	<!--filters-->
	<div class="pc-form-filters">
		<form [formGroup]="_searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Ragione sociale</mat-label>
						<input type="text" matInput formControlName="businessName" #businessName />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Codice WinWaste</mat-label>
						<input type="text" matInput formControlName="winWasteCod" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Indirizzo</mat-label>
						<input type="text" matInput formControlName="address" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Amministratore</mat-label>
						<input type="text" matInput formControlName="buildingAdministrator" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Tipo cliente</mat-label>
						<mat-select formControlName="interim">
							<mat-option>Tutti...</mat-option>
							<mat-option [value]="1">Solo Digifran</mat-option>
							<mat-option [value]="0">Solo WinWaste</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col d-flex align-items-center">
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>search</mat-icon>
						<span>{{'Commons.Actions.Search' | translate}}</span>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!--legend-->
	<ul class="pc-form-filters-legend">
		<li><i class="zmdi zmdi-rotate-left text-info m-r-10"></i>Abbonamento</li>
		<li><i class="zmdi zmdi-rotate-left text-danger m-r-10"></i>Multi-Abbonamento</li>
		<li><i class="zmdi zmdi-rotate-left m-r-10"></i>No-Abbonamento</li>
	</ul>

	<div class="mt-30">

		<!--info-->
		<div *ngIf="!_showTable" class="info-box">
			<span>Inserisci i parametri di ricerca, quindi clicca il bottone CERCA per iniziare</span>
		</div>

		<!--results-->
		<ng-container *ngIf="_showTable">
			<div class="card overflow-hidden">
				<div class="card-block p-0">
					<div class="table-responsive customer-selector-table">
						<table datatable [dtOptions]="_dtOptions" class="table row-border hover"></table>
					</div>
				</div>
			</div>
		</ng-container>

	</div>

</div>