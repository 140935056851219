<div class="modal fade" bsModal #changeTransportMissionStatusModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" style="min-width:700px;">
		<div class="modal-content">
			<form [formGroup]="_form" (ngSubmit)="changeTransportMissionStatus()">
				<div class="modal-header">
					<h4 class="modal-title float-left">
						<i class="zmdi zmdi-swap m-r-10"></i>
						Cambia stato della missione
					</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="changeTransportMissionStatusModal.hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<alert type="warning">
						Attenzione! Lo stato viene aggiornato automaticamente dagli operatori.
						Utilizza questa funzionalità solamente per correggere errori imprevisti o involontari.
					</alert>

					<!--mission status-->
					<ul class="activities_list">
						<li>
							<div>
								<strong>Missione</strong>
								<small>Stato attuale: {{mission.transportMissionStatusName}}</small>
								<mat-form-field>
									<mat-select formControlName="newStatusId" (selectionChange)="newStatusIdChange()" placeholder="Seleziona nuovo stato...">
										<mat-option [value]="1">In creazione</mat-option>
										<mat-option [value]="2" *ngIf="!mission.thirdParties">Programmata</mat-option>
										<mat-option [value]="3">Completata</mat-option>
										<mat-option [value]="4">Annullata</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</li>
					</ul>

					<!--activities-->
					<div *ngIf="activities['controls'].length > 0 && !mission.thirdParties">

						<p class="mt-20 mb-10">
							Seleziona il nuovo stato delle singole attività.
						</p>

						<ul class="activities_list">
							<li id="activity{{activityIndex}}" formArrayName="activities" *ngFor="let activity of activities['controls']; let activityIndex = index;">
								<div [formGroupName]="activityIndex">
									<strong>{{ 1 + activityIndex}} - {{activity.get('transportMissionActivityTypeName').value}}</strong>
									<small>Stato attuale: {{activity.get('transportMissionActivityStatusName').value}}</small>
									<mat-form-field>
										<mat-select formControlName="newStatusId" placeholder="Seleziona nuovo stato...">
											<mat-option [value]="2" *ngIf="newStatusId.value < 3">Programmata</mat-option>
											<mat-option [value]="5" *ngIf="newStatusId.value < 3">In navigazione</mat-option>
											<mat-option [value]="6" *ngIf="newStatusId.value < 3">Giunto a destinazione</mat-option>
											<mat-option [value]="7" *ngIf="newStatusId.value < 3">Lavoro in corso</mat-option>
											<mat-option [value]="3" *ngIf="newStatusId.value > 1 || activity.get('transportMissionActivityStatusId').value === 3">Completata</mat-option>
											<mat-option [value]="4" *ngIf="newStatusId.value > 1 || activity.get('transportMissionActivityStatusId').value === 4">Annullata</mat-option>
										</mat-select>
										<mat-error *ngIf="activity.get('newStatusId').errors?.required && activity.get('newStatusId').touched">
											{{'Commons.Validation.Required' | translate : { field: "Data disidratazione" } }}
										</mat-error>
									</mat-form-field>
								</div>
							</li>
						</ul>
					</div>
					
				</div>
				<div class="modal-footer">
					<button type="button" mat-button class="mr-2" (click)="changeTransportMissionStatusModal.hide()">
						Annulla
					</button>
					<button type="submit" mat-raised-button color="accent" [class.disabled]="!_form.valid">
						Conferma e aggiorna lo stato
					</button>
				</div>
			</form>
		</div>
	</div>
</div>