import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PCodeServices } from '../../../../shared/services/pcode/pcode.service';
import { ArticleTypesService } from '../../../pages/technologicalresources/articletypes/articletypes.service';

@Component({
	selector: 'technologicalresourcetype-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
})

export class TechnologicalResourceTypeSelectorComponent implements OnInit
{
	// I/O
	@Output() technologicalResourceTypeSelectionCallback: EventEmitter<any> = new EventEmitter();

	// variables
	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable: boolean = false;

	// catalog categories
	public _catalogCategoriesFilterControl: FormControl = new FormControl();
	public _catalogCategories = [];
	public _filteredCatalogCategories = [];

	// catalog article types 
	public _catalogArticleTypesFilterControl: FormControl = new FormControl();
	public _catalogArticleTypes = [];
	public _filteredCatalogArticleTypes = [];

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			articleTypeId: [null],
			categoryId: [null],
			articleStatusId: [null],
			numberRegisterModel: [null]
		}
	);

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private articleTypesService: ArticleTypesService,
			private fb: FormBuilder,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		this._catalogCategoriesFilterControl.valueChanges.subscribe(() =>
		{
			this.filterCatalogCategories();
		});

		this._catalogArticleTypesFilterControl.valueChanges.subscribe(() =>
		{
			this.filterCatalogArticleTypes();
		});

		this.articleTypesService.getAllCatalogCategories().subscribe(results =>
		{

			// catalog categories
			this._catalogCategories = results;
			this._filteredCatalogCategories = results;

			// getting list
			this.getList();
		});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}

	// load article types
	loadArticleTypes()
	{
		this.articleTypesService.getAllArticleTypes(this._searchForm.get('categoryId').value).subscribe(results =>
		{
			this._catalogArticleTypes = results;
			this._filteredCatalogArticleTypes = results;
		});
	}

	// get list
	getList()
	{
		this._showTable = false;

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: this._searchForm.value,
			Url: this._baseUrl + 'api/technologicalresources/articlestypes/getArticles',
			Order: [0, 'asc'],
			Columns:
				[
					{
						title: ' ', data: "catalogArticleStatusId", width: '20px', className: "sts-col all", render: (data, type, row) =>
						{
							let _result = '';
							if (row['externalMaintenance'] === true)
								_result = '<i class="zmdi zmdi-wrench text-info blink" title="' + row['catalogArticleStatusName'] + '"></i>';
							else if (row['assignedTo'] !== null && row['assignedTo'] > 0)
								_result = '<i class="zmdi zmdi-account text-disabled" title="' + row['catalogArticleStatusName'] + '"></i>';
							else
							{
								switch (data)
								{
									// disponibile
									case 1:
										_result = '<i class="zmdi zmdi-check text-success" title="' + row['catalogArticleStatusName'] + '"></i>';
										break;

									// dismesso
									case 2:
										_result = '<i class="zmdi zmdi-close text-danger" title="' + row['catalogArticleStatusName'] + '"></i>';
										break;
								}
							}

							return _result;
						}
					},
					{ title: 'Numero', data: 'number', width: '20px', className: 'all text-center' },
					{ title: 'Matricola', data: 'registerNumber', className: 'all' },
					{ title: 'Modello', data: 'model', className: 'min-tablet' },
					{
						title: 'Data&nbsp;inserimento', data: 'insertDate', className: 'none', render: (data, type, row) =>
						{
							return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm:ss')
						}
					},
					{
						title: 'Ultimo&nbsp;aggiornamento', data: 'lastUpdateDate', className: 'none', render: (data, type, row) =>
						{
							return this.datepipe.transform(data, 'dd/MM/yyyy HH:mm:ss')
						}
					},
					{
						title: '', data: 'id', className: 'all adv-col', width: '20px', orderable: false, render: (data, type, row) =>
						{
							let actions_list = '';

							// select
							actions_list += '<a href="#" data-action="select" title="Seleziona questo elemento" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actions_list;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.technologicalResourceTypeSelectionCallback.emit({
						id: data['id'],
						registerNumber: data['registerNumber'],
						model: data['model'],
					});

					_self.hideModal();
				});
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search 
	search()
	{
		this.getList();
	}

	// filter catalog categories
	filterCatalogCategories()
	{
		const search = this._catalogCategoriesFilterControl.value;

		if (!search)
			this._filteredCatalogCategories = this._catalogCategories;
		else
			this._filteredCatalogCategories = (this._catalogCategories as any[]).filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
	}

	// filter catalog article types
	filterCatalogArticleTypes()
	{
		const search = this._catalogArticleTypesFilterControl.value;

		if (!search)
			this._filteredCatalogArticleTypes = this._catalogArticleTypes;
		else
			this._filteredCatalogArticleTypes = (this._catalogArticleTypes as any[]).filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
	}

	// reset filter
	resetFilter()
	{
		this._searchForm.reset();

		// getting list
		this.getList();
	}
}