<!--header-->
<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-accounts-alt m-r-10"></i>Seleziona risorsa tecnologica
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body-->
<div class="modal-body">

	<!--filters-->
	<div class="pc-form-filters">
		<form [formGroup]="_searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Categoria</mat-label>
						<mat-select formControlName="categoryId" (selectionChange)="loadArticleTypes()">
							<mat-option>
								<ngx-mat-select-search [formControl]="_catalogCategoriesFilterControl" placeholderLabel="Cerca..." noEntriesFoundLabel="Nessun risultato"></ngx-mat-select-search>
							</mat-option>
							<mat-option>Tutte...</mat-option>
							<mat-option *ngFor="let _catalogCategory of _filteredCatalogCategories" [value]="_catalogCategory.id">
								{{_catalogCategory.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Risorsa</mat-label>
						<mat-select formControlName="articleTypeId">
							<mat-option>
								<ngx-mat-select-search [formControl]="_catalogArticleTypesFilterControl" placeholderLabel="Cerca..." noEntriesFoundLabel="Nessun risultato"></ngx-mat-select-search>
							</mat-option>
							<mat-option>Tutte...</mat-option>
							<mat-option *ngFor="let _catalogArticleType of _filteredCatalogArticleTypes" [value]="_catalogArticleType.id">
								{{_catalogArticleType.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Matricola / modello / numero</mat-label>
						<input type="text" matInput formControlName="numberRegisterModel" appAutofocus />
					</mat-form-field>
				</div>
				<div class="col d-flex align-items-center">
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>search</mat-icon>
						<span>{{'Commons.Actions.Search' | translate}}</span>
					</button>
					<button type="button" mat-icon-button class="ml-4" (click)="resetFilter()" matTooltip="Rimuovi tutti i filtri" matTooltipPosition="above">
						<mat-icon>undo</mat-icon>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!--legend-->
	<ul class="articletypes-status-legend clearfix">
		<li><i class="zmdi zmdi-check text-success m-r-10"></i>Disponibile</li>
		<li><i class="zmdi zmdi-account text-disabled m-r-10"></i>Assegnato</li>
		<li><i class="zmdi zmdi-wrench text-info m-r-10"></i>In manutenzione</li>
		<li><i class="zmdi zmdi-close text-danger m-r-10"></i>Dismesso</li>
	</ul>

	<!--results-->
	<div class="table-responsive">
		<table datatable *ngIf="_showTable" [dtOptions]="_dtOptions" class="table row-border hover"></table>
	</div>

</div>