<div class="timeline">

	<!--events-->
	<div class="timeline_events_list">

		<!--line separator-->
		<div class="timeline_line"></div>

		<!--events-->
		<div class="timeline_event_wrapper" *ngFor="let event of timeline.events">

			<!--time-->
			<div class="timeline_event_time">
				<span>{{event.dateTime | date: timeline.options.dateFormat}}</span>
			</div>

			<!--icon-->
			<div class="timeline_event_separator" [style.border-color]="event.iconColor">
				<mat-icon [style.color]="event.iconColor">{{event.icon}}</mat-icon>
			</div>

			<!--content-->
			<div class="timeline_event_content">
				<div>
					<ng-container [ngTemplateOutlet]="eventTemplate"
									  [ngTemplateOutletContext]="{ event: event }">
					</ng-container>
				</div>
			</div>
		</div>

	</div>
</div>