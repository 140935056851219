import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FileTypesService
{
    // constructor
    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
    {

    }

    // get all
    public getAll(): Observable<Array<any>>
    {
        return this.http.get<Array<any>>(this.baseUrl + 'api/configurations/fileTypes/getAll');
    }

    // get by action alias
    public getByActionAlias(actionAlias: string, alphabeticalOrder: boolean = false): Observable<Array<any>>
    {
        return this.http.get<Array<any>>(this.baseUrl + 'api/configurations/fileTypes/GetByActionAlias?actionAlias=' + actionAlias + '&alphabeticalOrder=' + alphabeticalOrder);
    }

    // get single
    public getSingle(id: number)
    {
        return this.http.get(this.baseUrl + 'api/configurations/fileTypes/getSingle?Id=' + id);
    }

    // update
    public insertUpdate(fileType: any)
    {
        return this.http.post(this.baseUrl + 'api/configurations/fileTypes/insertUpdate', fileType);
    }

    // delete
    public delete(id: number)
    {
        return this.http.delete(this.baseUrl + 'api/configurations/fileTypes/delete?id=' + id);
    }

    // get applications actions
    public getApplicationsActions(): Observable<Array<any>>
    {
        return this.http.get<Array<any>>(this.baseUrl + 'api/configurations/fileTypes/getApplicationsActions');
    }

    // update
    public updateActionFileTypes(actionFileTypes: any)
    {
        return this.http.post(this.baseUrl + 'api/configurations/fileTypes/updateActionFileTypes', actionFileTypes);
    }

    // update
    public updateActionFileTypesPositions(actionId: number, fileTypes: Array<number>)
    {
        return this.http.post(this.baseUrl + 'api/configurations/fileTypes/updateActionFileTypesPositions', { actionId: actionId, fileTypes: fileTypes  });
    }
}