<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-info-outline m-r-10"></i>
		<span>Registro attività centrale operativa</span>
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body" *ngIf="showRegistryLogs">

	<!--timeline-->
	<ng-container *ngIf="interventionTimeline && interventionTimeline.events.length > 0">
		<timeline [timeline]="interventionTimeline" [eventTemplate]="timeLineEventTemplate"></timeline>
	</ng-container>

	<!--no activities-->
	<ng-container *ngIf="!interventionTimeline || interventionTimeline.events.length === 0">
		Nessuna attività per questo intervento.
	</ng-container>

	<!--timeline event template-->
	<ng-template let-event="event" #timeLineEventTemplate>
		<div class="timeline_event">
			<div class="timeline_event_user">
				{{event.user}}
			</div>
			<strong>{{event.title}}</strong>
			<div class="timeline_event_description">
				{{event.description}}
			</div>
			<div class="timeline_event_message" *ngIf="event.message">
				{{event.message}}
			</div>
		</div>
	</ng-template>

</div>
<div class="modal-footer">
	<button type="button" mat-button class="mr-2" (click)="hideModal()">
		Chiudi
	</button>
</div>