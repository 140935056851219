<ng-container *ngIf="purgeNoteData">
	<form [formGroup]="form" (ngSubmit)="changePurgeNoteStatus()">
		<div class="modal-header">
			<h4 class="modal-title float-left">
				<i class="zmdi zmdi-swap m-r-10"></i>
				Nota di lavoro
			</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

			<alert type="warning">
				Attenzione! Lo stato viene aggiornato automaticamente dagli operatori sul campo.
				<br />Utilizzare questa funzionalità solamente per correggere errori imprevisti o involontari.
			</alert>

			<div class="mt-10">
				<strong>Stato attuale:</strong> {{purgeNoteData.purgeNoteStatusDescription}}
			</div>

			<div class="mt-30">

				<!--current purge note-->
				<ul class="workteams_list">
					<li>
						<mat-form-field>
							<mat-label>Stato nota di lavoro</mat-label>
							<mat-select formControlName="purgeNoteStatusId" (selectionChange)="purgeNoteStatusChange()">
								<mat-option [value]="1">
									<i class="zmdi zmdi-edit text-disabled m-r-10"></i>
									<span>Da programmare</span>
								</mat-option>
								<mat-option [value]="2" *ngIf="purgeNoteData.purgeNoteStatusId > 1">
									<i class="zmdi zmdi-time-interval text-warning m-r-10"></i>
									<span>Programmata</span>
								</mat-option>
								<mat-option [value]="5" *ngIf="purgeNoteData.purgeNoteStatusId > 1">
									<i class="zmdi zmdi-check-all text-success m-r-10"></i>
									<span>Completata</span>
								</mat-option>
								<mat-option [value]="6">
									<i class="zmdi zmdi-close text-danger m-r-10"></i>
									<span>Annullata</span>
								</mat-option>
							</mat-select>
						</mat-form-field>
					</li>
				</ul>

				<!--purge note work teams-->
				<div class="row">
					<div class="col-lg-12" *ngIf="purgeNoteWorkTeams['controls'].length > 0">

						<p class="mt-20 mb-10">
							Seleziona il nuovo stato dei singoli flussi di lavoro delle squadre sul campo:
						</p>

						<ul class="workteams_list">
							<li formArrayName="purgeNoteWorkTeams" *ngFor="let workTeam of purgeNoteWorkTeams['controls']; let workTeamIndex = index;">
								<div [formGroupName]="workTeamIndex">
									<mat-form-field>
										<mat-label>
											<ng-container *ngIf="workTeam.get('teamLeaderOperatorFullName').value">{{workTeam.get('teamLeaderOperatorFullName').value}}&nbsp;-&nbsp;</ng-container>
											Squadra {{(workTeam.get('lead').value ? 'principale' : 'di supporto')}}
										</mat-label>
										<mat-select formControlName="purgeNotePurgeWorkTeamStatusId" (selectionChange)="purgeNotePurgeWorkTeamStatusChange($event, workTeamIndex)">

											<!--purge note status: da programmare-->
											<ng-container *ngIf="purgeNoteStatusId.value === 1">
												<mat-option [value]="1">
													<i class="zmdi zmdi-edit text-disabled m-r-10"></i>
													<span>Da programmare</span>
												</mat-option>
											</ng-container>

											<!--purge note status: programmata-->
											<ng-container *ngIf="purgeNoteStatusId.value === 2">
												<mat-option [value]="2">
													<i class="zmdi zmdi-time-interval text-warning m-r-10"></i>
													<span>Programmata</span>
												</mat-option>
											</ng-container>

											<!--purge note status: completato-->
											<ng-container *ngIf="purgeNoteStatusId.value === 5">
												<mat-option [value]="4">
													<i class="zmdi zmdi-check-all text-success m-r-10"></i>
													<span>Completata</span>
												</mat-option>
											</ng-container>

											<!--purge note status: annullato-->
											<ng-container *ngIf="purgeNoteStatusId.value === 6">
												<mat-option [value]="5">
													<i class="zmdi zmdi-close text-danger m-r-10"></i>
													<span>Annullata</span>
												</mat-option>
											</ng-container>

										</mat-select>
										<mat-error *ngIf="workTeam.get('purgeNotePurgeWorkTeamStatusId').errors?.required && workTeam.get('purgeNotePurgeWorkTeamStatusId').touched">
											Il nuovo stato del flusso di lavoro è obbligatorio
										</mat-error>
									</mat-form-field>

									<!--cancel notes-->
									<mat-form-field *ngIf="workTeam.get('purgeNotePurgeWorkTeamStatusId').value === 5">
										<mat-label>Note di annullamento</mat-label>
										<input matInput formControlName="cancelNotes" />
										<mat-error align="right" *ngIf="workTeam.get('cancelNotes').errors?.required && workTeam.get('cancelNotes').touched">
											Le note di annullamento sono obbligatorie
										</mat-error>
									</mat-form-field>

								</div>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</div>

		<div class="modal-footer">
			<button type="button" mat-button class="mr-2" (click)="hideModal()">
				Annulla
			</button>
			<button type="submit" mat-raised-button color="accent" [class.disabled]="!form.valid">
				Conferma e aggiorna lo stato
			</button>
		</div>

	</form>
</ng-container>