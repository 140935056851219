import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetupService } from '../../../../../shared/services/app/setup.service';
import { InterventionWorkTeam } from '../../../../pages/purges/interventions/interventions.models';
import { PurgeNoteWorkTeam } from '../../../../pages/purges/purgenotes/purgenotes.models';
import { VehicleSelectorComponent } from '../../../maintenances/vehicles/selector/selector.component';
import { EmployeeSelectorComponent } from '../../../registries/employees/selector/selector.component';
import { BusinessAreas, PurgeWorkType } from '../../../shared.models';
import { PurgeWorkTeam, PurgeWorkTeamEmployee, PurgeWorkTeamLayout, PurgeWorkTeamVehicle } from '../purgeworkgroups.models';

@Component({
	selector: 'purge-work-teams',
	templateUrl: './purge-work-teams.component.html',
	styleUrls: ["./purge-work-teams.component.scss"]
})

export class PurgeWorkTeamsComponent implements OnDestroy
{
	// I/O
	@Input() purgeWorkTeams: PurgeWorkTeam[] | InterventionWorkTeam[] | PurgeNoteWorkTeam[];
	@Input() maxPurgeWorkTeamsCount: number = 5;
	@Input() layout: PurgeWorkTeamLayout = 0;
	@Input() showCustomLayoutDetails: boolean = false;

	// minimum operators per team
	public _minOperatorsPerTeam: number = 1;
	private minOperatorsPerTeam$ = new Subject<number>();
	@Input() set minOperatorsPerTeam(value: number)
	{
		this._minOperatorsPerTeam = value;
		this.minOperatorsPerTeam$.next(value);
	}

	// maximum operators per team
	@Input() _maxOperatorsPerTeam: number = 3;
	private maxOperatorsPerTeam$ = new Subject<number>();
	@Input() set maxOperatorsPerTeam(value: number)
	{
		this._maxOperatorsPerTeam = value;
		this.maxOperatorsPerTeam$.next(value);
	}

	// minimum vehicles per team
	public _minVehiclesPerTeam: number = 1;
	private minVehiclesPerTeam$ = new Subject<number>();
	@Input() set minVehiclesPerTeam(value: number)
	{
		this._minVehiclesPerTeam = value;
		this.minVehiclesPerTeam$.next(value);
	}

	// maximum vehicles per team
	public _maxVehiclesPerTeam: number = 2;
	private maxVehiclesPerTeam$ = new Subject<number>();
	@Input() set maxVehiclesPerTeam(value: number)
	{
		this._maxVehiclesPerTeam = value;
		this.maxVehiclesPerTeam$.next(value);
	}

	// destroy
	private destroy$ = new Subject<void>();

	// commons
	@Input() showAddWorkTeamAction: boolean;
	@Input() showResetWorkTeamAction: boolean;
	@Input() readOnly: boolean;

	// viewchildren
	@ViewChild("employeeSelector") employeeSelector: EmployeeSelectorComponent;

	// modal
	private bsModalRef: BsModalRef;

	// variables
	private activePurgeWorkTeamIndex: number;
	public showComponent: boolean = false;
	private employeeSelectorFilterInput =
		{
			businessAreaId: [BusinessAreas.Spurghi]
		};
	public workTimeHours: number[];
	public workTimeMinutes: number[];
	public purgeWorkTypes: PurgeWorkType[];

	// working group form
	public form: FormGroup = this.fb.group(
		{
			purgeWorkTeams: this.fb.array([], [this.minArrayLengthValidator(1)])
		}
	);
	get purgeWorkTeamsFormArray() { return this.form.get('purgeWorkTeams') as UntypedFormArray; }

	// constructor
	constructor
		(
			private fb: FormBuilder,
			private modalService: BsModalService,
			private setupService: SetupService
		)
	{
		// work time hours
		this.workTimeHours = new Array<number>();
		for (var i = 0; i <= 23; i++)
			this.workTimeHours.push(i);

		// work time minutes
		this.workTimeMinutes = new Array<number>();
		for (var i = 0; i < 60; i += 5)
			this.workTimeMinutes.push(i);
	}

	// destroy
	ngOnDestroy()
	{
		this.destroy$.next();
		this.destroy$.complete();
	}

	// initialize component
	initializeComponent()
	{
		const purgeWorkTypesObs = this.setupService.getPurgeWorkTypes();

		forkJoin([purgeWorkTypesObs]).subscribe(results =>
		{
			this.purgeWorkTypes = results[0];

			// creating work team form groups
			this.purgeWorkTeams.forEach((x: PurgeWorkTeam | InterventionWorkTeam | PurgeNoteWorkTeam) =>
			{
				switch (this.layout)
				{
					// purge work group
					case PurgeWorkTeamLayout.PurgeWorkGroup:
						this.purgeWorkTeamsFormArray.push(this.createPurgeWorkGroupTeamItem(x as PurgeWorkTeam));
						break;

					// intervention work team
					case PurgeWorkTeamLayout.Intervention:
						this.purgeWorkTeamsFormArray.push(this.createInterventionTeamItem(x as InterventionWorkTeam));
						break;

					// purge note work team
					case PurgeWorkTeamLayout.PurgeNote:
						this.purgeWorkTeamsFormArray.push(this.createPurgeNoteTeamItem(x as PurgeNoteWorkTeam));
						break;
				}
			});

			// loading into form
			this.form.patchValue(this.purgeWorkTeams);

			// initialize subjects for input changes detect
			this.initializeSubjects();

			// trigger validators
			this.form.markAllAsTouched();

			// show component
			this.showComponent = true;

			// check: readonly
			if (this.readOnly === true)
				this.setReadOnly();
		});
	}

	// initialize subjects
	initializeSubjects()
	{
		// miminum operators
		this.minOperatorsPerTeam$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() =>
			{
				this.purgeWorkTeamsFormArray.controls.forEach(item =>
				{
					item.get('employees').setValidators([this.minArrayLengthValidator(this._minOperatorsPerTeam), this.maxArrayLengthValidator(this._maxOperatorsPerTeam)]);
					item.get('employees').updateValueAndValidity();
				});
			});

		// maximum operators
		this.maxOperatorsPerTeam$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() =>
			{
				this.purgeWorkTeamsFormArray.controls.forEach(item =>
				{
					item.get('employees').setValidators([this.minArrayLengthValidator(this._minOperatorsPerTeam), this.maxArrayLengthValidator(this._maxOperatorsPerTeam)]);
					item.get('employees').updateValueAndValidity();
				});
			});

		// minimum vehicles
		this.minVehiclesPerTeam$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() =>
			{
				this.purgeWorkTeamsFormArray.controls.forEach(item =>
				{
					item.get('vehicles').setValidators([this.minArrayLengthValidator(this._minVehiclesPerTeam), this.maxArrayLengthValidator(this._maxVehiclesPerTeam)]);
					item.get('vehicles').updateValueAndValidity();
				});
			});

		// maximum vehicles
		this.maxVehiclesPerTeam$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() =>
			{
				this.purgeWorkTeamsFormArray.controls.forEach(item =>
				{
					item.get('vehicles').setValidators([this.minArrayLengthValidator(this._minVehiclesPerTeam), this.maxArrayLengthValidator(this._maxVehiclesPerTeam)]);
					item.get('vehicles').updateValueAndValidity();
				});
			});
	}

	// creating form structure
	createPurgeWorkGroupTeamItem(purgeWorkTeam: PurgeWorkTeam)
	{
		const result = this.fb.group(
			{
				// work team fields
				id: [purgeWorkTeam.id, [Validators.required]],
				lead: [purgeWorkTeam.lead, [Validators.required]],
				employees: this.fb.array([], [this.minArrayLengthValidator(this._minOperatorsPerTeam), this.maxArrayLengthValidator(this._maxOperatorsPerTeam)]),
				vehicles: this.fb.array([], [this.minArrayLengthValidator(this._minVehiclesPerTeam), this.maxArrayLengthValidator(this._maxVehiclesPerTeam)])
			});

		// creating work team employees
		if (purgeWorkTeam.employees)
			purgeWorkTeam.employees.forEach(x => { (result.get('employees') as UntypedFormArray).push(this.createPurgeWorkTeamEmployeeItem(x)); });

		// creating work team vehicles
		if (purgeWorkTeam.vehicles)
			purgeWorkTeam.vehicles.forEach(x => { (result.get('vehicles') as UntypedFormArray).push(this.createPurgeWorkTeamVehicleItem(x)); });

		return result;
	}
	createInterventionTeamItem(purgeWorkTeam: InterventionWorkTeam)
	{
		const result = this.fb.group(
			{
				// work team fields
				id: [purgeWorkTeam.id, [Validators.required]],
				lead: [purgeWorkTeam.lead, [Validators.required]],
				employees: this.fb.array([], [this.minArrayLengthValidator(this._minOperatorsPerTeam), this.maxArrayLengthValidator(this._maxOperatorsPerTeam)]),
				vehicles: this.fb.array([], [this.minArrayLengthValidator(this._minVehiclesPerTeam), this.maxArrayLengthValidator(this._maxVehiclesPerTeam)]),

				// intervention fields
				interventionPurgeWorkTeamStatusId: [purgeWorkTeam.interventionPurgeWorkTeamStatusId],
				notes: [purgeWorkTeam.notes],
				worksheetNotes: [purgeWorkTeam.worksheetNotes],
				worksheetPipeNotes: [purgeWorkTeam.worksheetPipeNotes],
				worksheetWorkTimeHours: [purgeWorkTeam.worksheetWorkTimeHours],
				worksheetWorkTimeMinutes: [purgeWorkTeam.worksheetWorkTimeMinutes],
				worksheetTravelTimeHours: [purgeWorkTeam.worksheetTravelTimeHours],
				worksheetTravelTimeMinutes: [purgeWorkTeam.worksheetTravelTimeMinutes]
			});

		// creating work team employees
		if (purgeWorkTeam.employees)
			purgeWorkTeam.employees.forEach(x => { (result.get('employees') as UntypedFormArray).push(this.createPurgeWorkTeamEmployeeItem(x)); });

		// creating work team vehicles
		if (purgeWorkTeam.vehicles)
			purgeWorkTeam.vehicles.forEach(x => { (result.get('vehicles') as UntypedFormArray).push(this.createPurgeWorkTeamVehicleItem(x)); });

		return result;
	}
	createPurgeNoteTeamItem(purgeWorkTeam: PurgeNoteWorkTeam)
	{
		const result = this.fb.group(
			{
				// work team fields
				id: [purgeWorkTeam.id, [Validators.required]],
				lead: [purgeWorkTeam.lead, [Validators.required]],
				employees: this.fb.array([], [this.minArrayLengthValidator(this._minOperatorsPerTeam), this.maxArrayLengthValidator(this._maxOperatorsPerTeam)]),
				vehicles: this.fb.array([], [this.minArrayLengthValidator(this._minVehiclesPerTeam), this.maxArrayLengthValidator(this._minVehiclesPerTeam)]),

				// purge note fields
				purgeNotePurgeWorkTeamStatusId: [purgeWorkTeam.purgeNotesPurgeWorkTeamStatusId],
				notes: [purgeWorkTeam.notes],
				worksheetNotes: [purgeWorkTeam.worksheetNotes]
			});

		// creating work team employees
		purgeWorkTeam.employees.forEach(x => { (result.get('employees') as UntypedFormArray).push(this.createPurgeWorkTeamEmployeeItem(x)); });

		// creating work team vehicles
		purgeWorkTeam.vehicles.forEach(x => { (result.get('vehicles') as UntypedFormArray).push(this.createPurgeWorkTeamVehicleItem(x)); });

		return result;
	}
	createPurgeWorkTeamEmployeeItem(purgeWorkTeamEmployee: PurgeWorkTeamEmployee)
	{
		const result = this.fb.group(
			{
				id: [purgeWorkTeamEmployee.id, [Validators.required]],
				teamLeader: [purgeWorkTeamEmployee.teamLeader, [Validators.required]],
				firstName: [purgeWorkTeamEmployee.firstName],
				lastName: [purgeWorkTeamEmployee.lastName]
			});

		return result;
	}
	createPurgeWorkTeamVehicleItem(purgeWorkTeamVehicle: PurgeWorkTeamVehicle)
	{
		const result = this.fb.group(
			{
				// vehicle
				id: [purgeWorkTeamVehicle.id, [Validators.required]],
				licensePlate: [purgeWorkTeamVehicle.licensePlate, [Validators.required]],
				name: [purgeWorkTeamVehicle.name, [Validators.required]],
				mounting: [purgeWorkTeamVehicle.mounting],

				// iot
				iotDeviceId: [purgeWorkTeamVehicle.iotDeviceId],
				purgeWorkTypeId: [purgeWorkTeamVehicle.purgeWorkTypeId],
				purgeWorkTypeOptionId:
					[
						purgeWorkTeamVehicle.purgeWorkTypeOptionId,
						(this.layout === PurgeWorkTeamLayout.Intervention && purgeWorkTeamVehicle.purgeWorkTypeId) ? [Validators.required] : null
					],

				// helpers
				purgeWorkTypeOptions: purgeWorkTeamVehicle.purgeWorkTypeId ?
					this.fb.array(this.purgeWorkTypes.find(x => x.id == purgeWorkTeamVehicle.purgeWorkTypeId).options.map(x => { return this.fb.group({ id: [x.id], name: [x.name], description: [x.description] }); })) :
					this.fb.array([])
			});

		return result;
	}

	// purge work teams
	addPurgeWorkTeam()
	{
		// creating new work team
		const newPurgeWorkTeamItem =
		{
			id: 0,
			employees: [],
			vehicles: [],
			lead: false
		};

		switch (this.layout)
		{
			case PurgeWorkTeamLayout.PurgeWorkGroup:
				{
					// creating new purge work team form group
					const newPurgeWorkTeamFormarrayItem = this.createPurgeWorkGroupTeamItem(newPurgeWorkTeamItem as PurgeWorkTeam);

					// push new item in formarray
					this.purgeWorkTeamsFormArray.push(newPurgeWorkTeamFormarrayItem);
				}
				break;

			case PurgeWorkTeamLayout.Intervention:
				{
					// creating new purge work team form group
					const newPurgeWorkTeamFormarrayItem = this.createInterventionTeamItem(newPurgeWorkTeamItem as InterventionWorkTeam);

					// push new item in formarray
					this.purgeWorkTeamsFormArray.push(newPurgeWorkTeamFormarrayItem);
				}
				break;

			case PurgeWorkTeamLayout.PurgeNote:
				{
					// creating new purge work team form group
					const newPurgeWorkTeamFormarrayItem = this.createPurgeNoteTeamItem(newPurgeWorkTeamItem as PurgeNoteWorkTeam);

					// push new item in formarray
					this.purgeWorkTeamsFormArray.push(newPurgeWorkTeamFormarrayItem);
				}
				break;
		}

		// trigger validators
		this.form.markAllAsTouched();
	}
	deletePurgeWorkTeam(purgeWorkTeamIndex: number)
	{
		if (confirm('Rimuovere questa squadra di lavoro?'))
		{
			// remove item from formarray
			this.purgeWorkTeamsFormArray.removeAt(purgeWorkTeamIndex);
		}
	}

	// employees
	showEmployeeSelectorDialog(purgeWorkTeamIndex: number)
	{
		// set active purge work team
		this.activePurgeWorkTeamIndex = purgeWorkTeamIndex;

		// show employee selector modal
		const initialState =
		{
			inputFilters: this.employeeSelectorFilterInput
		};

		this.bsModalRef = this.modalService.show(EmployeeSelectorComponent,
			{
				initialState,
				class: 'modal-lg',
				ignoreBackdropClick: false,
				animated: true
			});

		// selection callback
		this.bsModalRef.content.employeeSelectionCallback.subscribe((data: string) =>
		{
			this.bsModalRef.hide();

			this.employeeSelectionCallback(data);
		});
	}
	employeeSelectionCallback(employee)
	{
		// get current work team form control
		const currentWorkTeam = this.purgeWorkTeamsFormArray.controls[this.activePurgeWorkTeamIndex];

		// get all work group employees ids
		var allWorkGroupEmployeesIds: number[] = this.purgeWorkTeamsFormArray.controls
			.map(x => { return x.get('employees').value; })
			.reduce((acc, val) => acc.concat(val), [])
			.map(x => { return x.id });

		// check: employee not exists in entire workgroup
		if (allWorkGroupEmployeesIds.indexOf(employee.id) < 0)
		{
			// get work team employees
			const currentWorkTeamEmployeesFormArray = (currentWorkTeam.get('employees') as UntypedFormArray);

			// creating new employee
			const newEmployeeItem =
				{
					id: employee.id,
					firstName: employee.firstName,
					lastName: employee.lastName,
					teamLeader: currentWorkTeamEmployeesFormArray.controls.length === 0
				} as PurgeWorkTeamEmployee;

			// creating new employee form group
			const newEmployeeFormarrayItem = this.createPurgeWorkTeamEmployeeItem(newEmployeeItem);

			// push new item in formarray
			currentWorkTeamEmployeesFormArray.push(newEmployeeFormarrayItem);
		}
		else
		{
			alert('Operatore già presente in questo gruppo di lavoro.');
		}
	}
	removeEmployee(purgeWorkTeamIndex: number, employeeIndex: number)
	{
		if (confirm('Rimuovere questo operatore dalla squadra di lavoro?'))
		{
			// get current work team form control
			const currentWorkTeam = this.purgeWorkTeamsFormArray.controls[purgeWorkTeamIndex];

			// get employees formarray
			const employeesFormArray = (currentWorkTeam.get('employees') as UntypedFormArray);

			// remove item from formarray
			employeesFormArray.removeAt(employeeIndex);

			// check: set automatic team leader if not exists
			if (employeesFormArray.controls.length > 0 && employeesFormArray.controls.filter(x => x.get('teamLeader').value).length === 0)
				employeesFormArray.at(0).get('teamLeader').setValue(true);
		}
	}
	setWorkTeamLeader(purgeWorkTeamIndex: number, employeeIndex: number)
	{
		// get current work team form control
		const currentWorkTeam = this.purgeWorkTeamsFormArray.controls[purgeWorkTeamIndex];

		// get current work team employees formarray
		const employeesFormArray = currentWorkTeam.get('employees') as UntypedFormArray;

		// remove team leader from all items
		employeesFormArray.controls.forEach(control =>
		{
			control.get('teamLeader').setValue(false);
		});

		// set new team leader
		employeesFormArray.at(employeeIndex).get('teamLeader').setValue(true);

		// sorting formarray (team leader first)
		this.fb.array(employeesFormArray.controls.sort((a, b) => (a.get('teamLeader').value < b.get('teamLeader').value) ? 1 : ((b.get('teamLeader').value < a.get('teamLeader').value) ? -1 : 0)));
	}

	// vehicles
	showVehicleSelectorDialog(purgeWorkTeamIndex: number)
	{
		// set active purge work team
		this.activePurgeWorkTeamIndex = purgeWorkTeamIndex;

		// show vehicle selector modal
		this.bsModalRef = this.modalService.show(VehicleSelectorComponent,
			{
				class: 'modal-lg',
				ignoreBackdropClick: false,
				animated: true
			});

		// selection callback
		this.bsModalRef.content.vehicleSelectionCallback.subscribe((data: string) =>
		{
			this.bsModalRef.hide();

			this.vehicleSelectionCallback(data);
		});
	}
	vehicleSelectionCallback(vehicle)
	{
		// get current work team form control
		const currentWorkTeam = this.purgeWorkTeamsFormArray.controls[this.activePurgeWorkTeamIndex];

		// get all work group vehicles ids
		var allWorkGroupVehiclesIds: number[] = this.purgeWorkTeamsFormArray.controls
			.map(x => { return x.get('vehicles').value; })
			.reduce((acc, val) => acc.concat(val), [])
			.map(x => { return x.id });

		// check: vehicle not exists in entire workgroup
		if (allWorkGroupVehiclesIds.indexOf(vehicle.id) < 0)
		{
			// get work team vehicles
			const currentWorkTeamVehiclesFormArray = (currentWorkTeam.get('vehicles') as UntypedFormArray);

			// creating new vehicle
			const newVehicleItem =
				{
					id: vehicle.id,
					licensePlate: vehicle.licensePlate,
					name: vehicle.name,
					mounting: vehicle.mounting,

					// iot
					iotDeviceId: vehicle.iotDeviceId,
					purgeWorkTypeId: vehicle.purgeWorkTypeId

				} as PurgeWorkTeamVehicle;

			// creating new vehicle form group
			const newVehicleFormarrayItem = this.createPurgeWorkTeamVehicleItem(newVehicleItem);

			// push new item in formarray
			currentWorkTeamVehiclesFormArray.push(newVehicleFormarrayItem);
		}
		else
		{
			alert('Automezzo già presente in questo gruppo di lavoro.');
		}
	}
	removeVehicle(purgeWorkTeamIndex: number, vehicleIndex: number)
	{
		if (confirm('Rimuovere questo automezzo dalla squadra di lavoro?'))
		{
			// get current work team form control
			const currentWorkTeam = this.purgeWorkTeamsFormArray.controls[purgeWorkTeamIndex];

			// remove item from formarray
			(currentWorkTeam.get('vehicles') as UntypedFormArray).removeAt(vehicleIndex);
		}
	}

	// reset work teams
	resetWorkTeams()
	{
		this.purgeWorkTeams = [];
		this.purgeWorkTeamsFormArray.clear();
	}

	// min formarray length validator
	minArrayLengthValidator(minLength: number): ValidatorFn
	{
		return (control: AbstractControl): ValidationErrors | null =>
		{
			const array = control as UntypedFormArray;

			if (minLength > 0)
				return array.length >= minLength ? null : { minArrayLength: { requiredLength: minLength, actualLength: array.length } };
			else
				return null;
		};
	}
	maxArrayLengthValidator(maxLength: number): ValidatorFn
	{
		return (control: AbstractControl): ValidationErrors | null =>
		{
			const array = control as UntypedFormArray;

			if (maxLength > 0)
				return array.length <= maxLength ? null : { maxArrayLength: { requiredLength: maxLength, actualLength: array.length } };
			else
				return null;
		};
	}

	// set readonly
	setReadOnly(readOnlY = true)
	{
		if (readOnlY)
			this.form.disable();
		else
			this.form.enable();
	}

	// get all form errors
	getFormValidationErrors()
	{
		const result = [];
		Object.keys(this.form.controls).forEach(key =>
		{
			const controlErrors = this.form.get(key).errors;
			if (controlErrors != null)
			{
				Object.keys(controlErrors).forEach(keyError =>
				{
					result.push({
						control: key,
						error: keyError,
						value: controlErrors[keyError]
					});
				});
			}
		});

		this.collectErrors(this.form, result);

		return result;
	}
	collectErrors(form: FormGroup | UntypedFormArray, result: any[], parentKey: string = ''): void
	{
		Object.keys(form.controls).forEach(key =>
		{
			const control = form.get(key);
			const controlKey = parentKey ? `${parentKey}.${key}` : key;

			if (control instanceof FormGroup || control instanceof UntypedFormArray)
			{
				this.collectErrors(control, result, controlKey);
			} else
			{
				const controlErrors = control.errors;
				if (controlErrors != null)
				{
					Object.keys(controlErrors).forEach(keyError =>
					{
						result.push({
							control: controlKey,
							error: keyError,
							value: controlErrors[keyError]
						});
					});
				}
			}
		});
	}
}