<!--header-->
<div class="modal-header">
    <h4 class="modal-title float-left">
        {{id_OF.value > 0 ? 'Modifica' : 'Nuovo'}} indirizzo
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<!--body-->
<div class="modal-body p-0">

    <!--tabs-->
    <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms">

        <!--details-->
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex align-items-center text-secondary">
                    <mat-icon class="mr-2">place</mat-icon>
                    <span>Dettagli</span>
                </div>
            </ng-template>

            <!--tab content-->
            <div class="p-20">
                <form [formGroup]="_addressForm" (ngSubmit)="insertUpdateOffice()" class="form-horizontal">

                    <input type="hidden" formControlName="id" required />

                    <!--location-->
                    <div class="card">
                        <div class="form-horizontal">
                            <header class="card-header card-default">
                                <h2 class="card-title">
                                    <i class="zmdi zmdi-map m-r-10"></i>
                                    Località
                                </h2>
                            </header>
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Nazione</mat-label>
                                            <mat-select #nationIsoCode class="block" formControlName="nationIsoCode" (selectionChange)="nationSelectionChange()">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="_nationsSearchControl"
                                                                           placeholderLabel="Cerca..."
                                                                           noEntriesFoundLabel="Nessun risultato">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let _nation of _filteredNationsList" [value]="_nation.isoCode">
                                                    {{_nation.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="_addressForm.get('nationIsoCode').errors?.required && _addressForm.get('nationIsoCode').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "Nazione" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div [class.col-md-4]="nationIsoCode.value === 'IT'" [class.col-md-8]="nationIsoCode.value !== 'IT'">
                                        <mat-form-field>
                                            <mat-label *ngIf="nationIsoCode.value === 'IT'">Indirizzo</mat-label>
                                            <mat-label *ngIf="nationIsoCode.value !== 'IT'">Indirizzo completo</mat-label>
                                            <input type="text" matInput formControlName="address" maxlength="128" />
                                            <mat-error *ngIf="_addressForm.get('address').errors?.required && _addressForm.get('address').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "Indirizzo" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2" *ngIf="nationIsoCode.value === 'IT'">
                                        <mat-form-field>
                                            <mat-label>N° civico</mat-label>
                                            <input type="text" matInput formControlName="streetNumber" maxlength="16" />
                                            <mat-error *ngIf="_addressForm.get('streetNumber').errors?.required && _addressForm.get('streetNumber').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "N° civico" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row" *ngIf="nationIsoCode.value === 'IT'">
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Provincia</mat-label>
                                            <mat-select class="block" formControlName="provinceIstatCode" (selectionChange)="loadTowns()">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="_provincesSearchControl"
                                                                           placeholderLabel="Cerca..."
                                                                           noEntriesFoundLabel="Nessun risultato">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let _province of _filteredProvincesList" [value]="_province.istatCode">
                                                    {{_province.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="_addressForm.get('provinceIstatCode').errors?.required && _addressForm.get('provinceIstatCode').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "Provincia" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field>
                                            <mat-label>Comune</mat-label>
                                            <mat-select class="block" formControlName="townIstatCode" [disabled]="!provinceIstatCode_OF.value">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="_townsSearchControl"
                                                                           placeholderLabel="Cerca..."
                                                                           noEntriesFoundLabel="Nessun risultato">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let _town of _filteredTownsList" [value]="_town.istatCode">
                                                    {{_town.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint *ngIf="!provinceIstatCode_OF.value">
                                                Seleziona prima la provincia
                                            </mat-hint>
                                            <mat-error *ngIf="_addressForm.get('townIstatCode').errors?.required && _addressForm.get('townIstatCode').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "Comune" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-form-field>
                                            <mat-label>CAP</mat-label>
                                            <input type="text" matInput formControlName="postalCode" maxLength="5" />
                                            <button type="button" mat-icon-button matSuffix (click)="searchAddressPostalCode()" title="Cerca con Google">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                            <mat-error *ngIf="_addressForm.get('postalCode').errors?.required && _addressForm.get('postalCode').touched">
                                                {{'Commons.Validation.Required' | translate : { field: "CAP" } }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--contacts-->
                    <div class="card">
                        <div class="form-horizontal">
                            <header class="card-header card-default">
                                <h2 class="card-title">
                                    <i class="zmdi zmdi-accounts-list m-r-10"></i>
                                    Informazioni
                                </h2>
                            </header>
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Telefono Amministratore</mat-label>
                                            <input type="text" matInput formControlName="phoneNumber" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Referente</mat-label>
                                            <input type="text" matInput formControlName="referent" maxlength="128" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Cellulare Referente</mat-label>
                                            <input type="text" matInput formControlName="mobilePhoneNumber" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>Fax</mat-label>
                                            <input type="text" matInput formControlName="faxNumber" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>E-mail</mat-label>
                                            <input type="text" matInput formControlName="email" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field>
                                            <mat-label>E-mail PEC</mat-label>
                                            <input type="text" matInput formControlName="pecEmail" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Note</mat-label>
                                            <input type="text" matInput formControlName="notes" maxlength="1024" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Note in evidenza</mat-label>
                                            <input type="text" matInput formControlName="highlightNotes" maxlength="1024" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--actions-->
                    <div class="clearfix text-right">
                        <button type="button" mat-button class="mr-2" (click)="hideModal()">
                            Annulla
                        </button>
                        <button type="submit" mat-raised-button color="accent">
                            <mat-icon>done</mat-icon>
                            Conferma
                        </button>
                    </div>

                </form>
            </div>
        </mat-tab>

        <!--attachments-->
        <mat-tab *ngIf="id_OF.value > 0">
            <ng-template mat-tab-label>
                <div class="d-flex align-items-center text-secondary">
                    <mat-icon class="mr-2">attach_file</mat-icon>
                    <span>Allegati</span>
                </div>
            </ng-template>
            <div class="p-20">
                <div class="card">
                    <header class="card-header card-default clearfix">
                        <div class="float-left">
                            <h2 class="card-title">
                                <i class="zmdi zmdi-attachment-alt m-r-10"></i>
                                <span>Allegati</span>
                            </h2>
                        </div>
                        <div class="float-right">
                            <button type="button" color="accent" mat-raised-button class="btn-xs" (click)="showOfficeAttachmentsUploadModal()">
                                Carica allegati
                            </button>
                        </div>
                    </header>
                    <div class="card-block p-0">
                        <div class="table-responsive">
                            <table datatable *ngIf="_showTableAttachments" [dtOptions]="_dtOptionsAttachments" class="table row-border hover"></table>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>

</div>

<!--upload file modal-->
<div class="modal fade" bsModal #officeAttachmentsUploadModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-left">
                    <i class="zmdi zmdi-cloud-upload m-r-10"></i>
                    Carica documenti indirizzo
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="officeAttachmentsUploadModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <pc-uploader #officeAttachmentsUploader (uploadCompleteCallback)="uploadOfficeAttachmentCompleteCallback()" [showExpiryDate]="false"></pc-uploader>
            </div>
        </div>
    </div>
</div>

<!--update file modal-->
<div class="modal fade" bsModal #updateFileModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <form [formGroup]="_updateFileForm" (ngSubmit)="updateOfficeAttachment()" class="form-horizontal">
                <div class="modal-header">
                    <h4 class="modal-title float-left">
                        Modifica allegato
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="updateFileModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-horizontal">
                        <input type="hidden" formControlName="id" />
                        <mat-form-field>
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name" />
                            <mat-error *ngIf="nameRN.errors?.required && nameRN.touched">
                                {{'Commons.Validation.Required' | translate : { field: "Nome" } }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Tipologia</mat-label>
                            <mat-select class="block" formControlName="fileTypeId">
                                <mat-option *ngFor="let _fileType of _fileTypes" [value]="_fileType.id">
                                    <span>{{ _fileType.name }}</span>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fileTypeIdRN.errors?.required && fileTypeIdRN.touched">
                                {{'Commons.Validation.Required' | translate : { field: "Tipologia" } }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" mat-button class="mr-2" (click)="updateFileModal.hide()">
                        {{'Commons.Actions.Cancel' | translate}}
                    </button>
                    <button type="submit" mat-raised-button color="accent" [disabled]="!_updateFileForm.valid">
                        {{ 'Commons.Actions.Confirm' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>