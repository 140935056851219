import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePurgeNoteStatusViewModel, PurgeNote, PurgeNoteRegistry, PurgeNoteWorkTeamRegistry } from './purgenotes.models';

@Injectable()
export class PurgeNotesService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get single
	public getSingle(id: number, copyId?: number): Observable<PurgeNote>
	{
		return this.http.get<PurgeNote>(this.baseUrl + 'api/purges/purgenotes/getSingle?Id=' + id + '&copyId=' + copyId);
	}

	// update
	public insertUpdate(purgenote)
	{
		return this.http.post(this.baseUrl + 'api/purges/purgenotes/insertUpdate', purgenote);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/purges/purgenotes/delete?id=' + id);
	}

	// get change status
	public getChangePurgeNoteStatus(id: number): Observable<ChangePurgeNoteStatusViewModel>
	{
		return this.http.get<ChangePurgeNoteStatusViewModel>(this.baseUrl + `api/purges/purgenotes/getChangePurgeNoteStatus/${id}`);
	}

	// change status
	public changePurgeNoteStatus(model)
	{
		return this.http.post(this.baseUrl + 'api/purges/purgenotes/changePurgeNoteStatus', model);
	}

	// get purge note logs
	public getPurgeNoteLogs(id: number): Observable<PurgeNoteRegistry>
	{
		return this.http.get<PurgeNoteRegistry>(this.baseUrl + `api/purges/purgenotes/getPurgeNoteLogs/${id}`);
	}

	// get purge note purge work teams logs
	public getPurgeNotePurgeWorkTeamsLogs(id: number): Observable<PurgeNoteWorkTeamRegistry>
	{
		return this.http.get<PurgeNoteWorkTeamRegistry>(this.baseUrl + `api/purges/purgenotes/getPurgeNotePurgeWorkTeamsLogs/${id}`);
	}

	// quick edit
	public quickEdit(id: number)
	{
		return this.http.post(this.baseUrl + 'api/purges/purgenotes/quickEdit/' + id, null);
	}
}