import { PurgeWorkTeam } from '../../../shared/purges/purgeworkgroups/purgeworkgroups.models';
import { DigifranTimeLine, DigifranTimeLineEvent } from '../../../shared/timeline/timeline.models';

export interface PurgeNote
{
	id: number;
	purgeNoteStatusId: number;
	pointOfInterestId: number;
	schedulingDate: Date;
	schedulingHour: number;
	schedulingMinute: number;
	title: string;
	description: string;
	operatorNotes: string;
	address: string;
	purgeNoteTypeData: string;

	// purge work teams
	purgeWorkTeams: PurgeNoteWorkTeam[];

	sendToDevice: boolean;
}
export interface PurgeNoteWorkTeam extends PurgeWorkTeam
{
	purgeNotesPurgeWorkTeamStatusId: number;
	notes: string;
	worksheetNotes: string;
}

export interface ChangePurgeNoteStatusViewModel
{
	id: number;
	purgeNoteStatusId: number;
	purgeNoteStatusDescription: string;
	purgeNoteTypeId: number;
	offerCode: string;
	scheduling?: Date;

	// work teams
	purgeNoteWorkTeams: ChangePurgeNoteStatusWorkTeamViewModel[];

	// helpers
	customerBusinessName: string;
}
export interface ChangePurgeNoteStatusWorkTeamViewModel
{
	id: number;
	lead: boolean;
	purgeNotePurgeWorkTeamStatusId: number;
	cancelNotes: string;
	teamLeaderOperatorFullName: string;
}

// purge note registry
export interface PurgeNoteRegistry
{
	purgeNoteId: number;

	// logs
	purgeNoteLogs: PurgeNoteLog[];
}

// purge note work team registry
export interface PurgeNoteWorkTeamRegistry
{
	purgeNoteId: number;
	purgeNotePurgeWorkTeams: PurgeNoteWorkTeamRegistryPurgeWorkTeam[];
}
export interface PurgeNoteWorkTeamRegistryPurgeWorkTeam
{
	id: number;
	lead: boolean;
	purgeNotePurgeWorkTeamStatusId: number;
	purgeNotePurgeWorkTeamStatusName: string;
	worksheetNotes: string;
	cancelNotes: string;

	// operators
	operators: PurgeNoteWorkTeamRegistryPurgeWorkTeamOperator[];

	// vehicles
	vehicles: PurgeNoteWorkTeamRegistryPurgeWorkTeamVehicle[];

	// logs
	purgeNotePurgeWorkTeamLogs: PurgeNoteLog[];
}
export interface PurgeNoteWorkTeamRegistryPurgeWorkTeamOperator
{
	id: number;
	lastName: string;
	firstName: string;
	teamLeader: boolean;
}
export interface PurgeNoteWorkTeamRegistryPurgeWorkTeamVehicle
{
	id: number;
	name: string;
	licensePlate: string;
	mounting: string;
}

export interface PurgeNoteLog
{
	id: number;
	description: string;
	purgeNoteLogActionDescription: string;
	purgeNoteLogActionId: number;
	purgeNoteLogActionTitle: string;
	purgeNoteLogActionIcon: string;
	purgeNoteLogActionIconColor: string;
	purgeNoteLogActionWebApp: boolean;
	insertUserFullName: string;
	insertDate: Date;
}

// timeline
export class PurgeNoteWorkTeamTimeLine extends DigifranTimeLine
{
	purgeNotePurgeWorkTeamStatusId: number;
	lead: boolean;
	operators: PurgeNoteWorkTeamTimeLineOperator[];
	vehicles: PurgeNoteWorkTeamTimeLineVehicle[];

	worksheetNotes: string;
	cancelNotes: string;
}
export interface PurgeNoteWorkTeamTimeLineOperator
{
	lastName: string;
	firstName: string;
	teamLeader: boolean;
}
export interface PurgeNoteWorkTeamTimeLineVehicle
{
	licensePlate: string;
}

export class PurgeNoteTimeLineEvent extends DigifranTimeLineEvent
{
	title: string;
	description: string;
	message: string;
	user: string;
}

export class PurgeNoteWorkTeamTimeLineEvent extends DigifranTimeLineEvent
{
	title: string;
	description: string;
	message: string;
}