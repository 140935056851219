import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetupService } from '../../../../shared/services/app/setup.service';
import { PCodeServices } from '../../../../shared/services/pcode/pcode.service';
import { AttachmentsReportingService } from '../../../shared/attachments-reporting/attachmentsreporting.service';

@Component({
	selector: 'attachments-reporting-user-models',
	templateUrl: './user-models.component.html',
	styleUrls: ['./user-models.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class AttachmentsReportingUserModelsComponent
{
	// I/O
	@Input() reportingTypeId: number;
	@Input() fileTypes: Array<any>;
	@Output() userModelSelected: EventEmitter<number> = new EventEmitter<number>();

	// viewchildren
	@ViewChild('userModelsModal', { static: true }) userModelsModal;
	@ViewChild('insertUpdateUserModelModal', { static: true }) insertUpdateUserModelModal;

	// variables
	public _userModels: Array<any>;
	public _currentUserModel: any;
	public _selectedFileTypes: Array<number>;

	// user model form
	public _userModelForm: FormGroup = this.fb.group(
		{
			id: [null, Validators.required],
			name: [null, Validators.required],
			fileTypesIds: [null]
		}
	);
	get id() { return this._userModelForm.get('id'); }
	get name() { return this._userModelForm.get('name'); }
	get fileTypesIds() { return this._userModelForm.get('fileTypesIds'); }

	// constructor
	constructor(
		private pcodeServices: PCodeServices,
		private attachmentsReportingService: AttachmentsReportingService,
		public datepipe: DatePipe,
		private fb: FormBuilder,
		private setupService: SetupService
	)
	{

	}

	// select user model
	selectUserModel(userModel: any)
	{
		this.userModelSelected.emit(userModel.fileTypesIds);
	}

	// insert/update export model
	insertUpdateUserModel()
	{
		// selected options
		this.fileTypesIds.setValue(this._selectedFileTypes);

		if (this._userModelForm.valid)
		{
			this.attachmentsReportingService.insertUpdateReportingModel(this.reportingTypeId, this._userModelForm.value)
				.subscribe({
					next: () =>
					{
						this.pcodeServices.notify('success', 'Modello di export salvato con successo');
						this.insertUpdateUserModelModal.hide();
						this.showSelectionModal();
					},

					error: () =>
					{
						this.pcodeServices.notify('error', 'Controllare i valori immessi nel form');
					}
				});
		}
	}

	// edit user model
	editUserModel(userModel: any)
	{
		// loading user Model
		if (userModel != null)
		{
			this.attachmentsReportingService.getReportingModel(userModel.id).subscribe(result =>
			{
				this._currentUserModel = result;

				// patching form
				this._userModelForm.reset();
				this._userModelForm.patchValue(this._currentUserModel);

				// selected options
				this._selectedFileTypes = new Array<number>();
				for (let _fileTypeId of this._currentUserModel.fileTypesIds)
					this._selectedFileTypes.push(_fileTypeId);

				this.userModelsModal.hide();

				setTimeout(() =>
				{
					this.insertUpdateUserModelModal.show();
				}, 200)

			});
		}
		else
		{
			this._currentUserModel =
			{
				id: 0
			};

			// patching form
			this._userModelForm.reset();
			this._userModelForm.patchValue(this._currentUserModel);

			this._selectedFileTypes = new Array<number>();

			this.userModelsModal.hide();
			this.insertUpdateUserModelModal.show();
		}
	}

	// delete user model
	deleteUserModel(userModel: any)
	{
		if (confirm('Confermare l\'eliminazione del modello?'))
		{
			this.attachmentsReportingService.deleteReportingModel(userModel.id).subscribe(result =>
			{
				this.showSelectionModal();
				this.pcodeServices.notify('success', 'Modello di export eliminato con successo');
			});
		}
	}

	// cancel edit user model
	cancelEditUserModel()
	{
		this.insertUpdateUserModelModal.hide();
		this.showSelectionModal();
	}

	// toggle file type
	toggleFileType($event)
	{
		const _fileTypeId = parseInt($event.source.value);
		if ($event.checked)
			this._selectedFileTypes.push(_fileTypeId);
		else
			this._selectedFileTypes.splice(this._selectedFileTypes.indexOf(_fileTypeId, 0), 1);
	}

	// show selection modal
	showSelectionModal()
	{
		this.attachmentsReportingService.getUserReportingModels(this.reportingTypeId).subscribe(result =>
		{
			this._userModels = result;
			this.userModelsModal.show();
		});
	}

	// hide selection modal
	hideSelectionModal()
	{
		this.userModelsModal.hide();
	}
};