<div class="modal fade" bsModal #editActivityModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<form [formGroup]="_activityForm" (ngSubmit)="insertActivity()" class="form-horizontal">
				<div class="modal-header">
					<h4 class="modal-title float-left">
						Nuova attività per scadenza
						<small class="d-block text-white">{{_currentActivityDescription}}</small>
					</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="editActivityModal.hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">

					<input type="hidden" formControlName="id" required />
					<input type="hidden" formControlName="deadlineId" required />

					<div class="row mb-4" *ngIf="_currentActivityLastDate">
						<div class="col-6 text-center">
							<mat-label class="mat-label-external">Ultima attività</mat-label>
							<strong>{{_currentActivityLastDate| date:'dd/MM/yyyy'}}</strong>
						</div>
						<div class="col-6 text-center">
							<mat-label class="mat-label-external">Prossima attività</mat-label>
							<strong>{{_currentActivityNextDate| date:'dd/MM/yyyy'}}</strong>
						</div>
					</div>

					<hr />

					<mat-form-field class="mt-3">
						<mat-label>Data</mat-label>
						<input matInput [matDatepicker]="activityDateDatePicker" placeholder="gg/mm/aaaa" formControlName="activityDate" />
						<mat-datepicker-toggle matSuffix [for]="activityDateDatePicker"></mat-datepicker-toggle>
						<mat-datepicker touchUi="true" #activityDateDatePicker></mat-datepicker>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Descrizione</mat-label>
						<textarea type="text" matInput formControlName="description" rows="3" #descriptionCharacterCount maxLength="1024"></textarea>
						<mat-hint align="right">
							{{descriptionCharacterCount.value.length}} / 1024
						</mat-hint>
						<mat-error *ngIf="description_AC.errors?.required && description_AC.touched">
							{{'Commons.Validation.Required' | translate : { field: "Descrizione" } }}
						</mat-error>
					</mat-form-field>

				</div>
				<div class="modal-footer">
					<button type="button" mat-button class="mr-2" (click)="editActivityModal.hide()">
						<mat-icon>arrow_back</mat-icon>
						Annulla
					</button>
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>done</mat-icon>
						Conferma
					</button>
				</div>
			</form>
		</div>
	</div>
</div>