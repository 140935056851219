import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { PCodeServices } from '../../../../../../app/shared/services/pcode/pcode.service';

@Component({
	selector: 'suppliers-offers-activity-selector',
	templateUrl: './selector.component.html'
})

export class SuppliersOffersActivitiesSelectorComponent
{
	@ViewChild('listModal') listModal;
	@ViewChild('activityName') activityName: ElementRef;

	@Output() activitySelectionCallback: EventEmitter<any> = new EventEmitter();

	public _dtElement: DataTableDirective;
	public _dtOptions = {};
	public _showTable = false;

	public _modalTitle = "Seleziona attivit�"

	public _searchForm: FormGroup = this.fb.group(
		{
			name: [null]
		}
	);
	get name() { return this._searchForm.get('name'); }

	// constructor
	constructor
		(
			@Inject('BASE_URL') private _baseUrl: string,
			private pcodeServices: PCodeServices,
			private fb: FormBuilder,
			public datepipe: DatePipe
		)
	{

	}

	showModal(customTitle?: string)
	{
		// custom title
		if (customTitle)
			this._modalTitle = customTitle;

		this.getList();
	}

	// get customers list
	getList()
	{
		this._showTable = false;

		const _filters =
		{
			name: this.name.value || null
		}

		this._dtOptions = this.pcodeServices.initDatatable({
			Filters: _filters,
			PageSize: 10,
			Url: this._baseUrl + 'api/offers/suppliersoffersactivities/getList',
			Order: [0, 'asc'],
			Columns:
				[
					{ title: 'Nome', data: 'name', className: 'min-tablet' },
					{
						title: '', data: 'id', className: 'all adv-col', width: '80', orderable: false, render: (data, type, row) =>
						{
							let actionsList = '';

							// select
							actionsList += '<a href="#" data-action="select" title="Seleziona questa attivit�" mat-ripple class="btn btn-fab btn-flat btn-fab-sm"><i class="zmdi zmdi-long-arrow-right"></i></a>';

							return actionsList;
						}
					},
				],
			RowCallback: (row: Node, data: any[] | object, index: number) =>
			{
				const _self = this;

				// select
				$('[data-action="select"]', row).on('click', function (e)
				{
					_self.activitySelectionCallback.emit({
						id: data['id'],
						name: data['name']
					});
					_self.listModal.hide();
				});
			},
			DrawCallback: (settings) =>
			{
				this.listModal.show();
			}
		});

		setTimeout(() => { this._showTable = true }, 0);
	}

	// search customer
	search()
	{
		this.getList();
	}
}