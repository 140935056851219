
// actions
export enum Actions
{
	EmployeeAttachments = "EmployeeAttachments",
	CustomerOfferAttachments = "CustomerOfferAttachments",
	SupplierOfferAttachments = "SupplierOfferAttachments",
	VehicleAttachments = "VehicleAttachments",
	ArticleTypeElementAttachments = "ArticleTypeElementAttachments",
	InterventionAttachments = "InterventionAttachments",
	OfficeAttachments = "OfficeAttachments",
}

export enum FileTypes
{
	InterventoOperatore = 97,
	InterventoCentraleOperativa = 98
}

export enum InterventionStatus
{
	Inserted = 1,
	Scheduled = 2,
	Deleted = 3,
	Active = 4,
	Completed = 5,
	Canceled = 6,
	Brea = 7,
	Travelling = 8,
	OnSite = 9
}

export enum InterventionPurgeWorkTeamStatus
{
	Inserted = 1,
	Scheduled = 2,
	Active = 3,
	Completed = 4,
	Canceled = 5,
	Break = 6,
	Travelling = 7,
	OnSite = 8
}
export enum PurgeNotePurgeWorkTeamStatus
{
	Inserted = 1,
	Scheduled = 2,
	Active = 3,
	Completed = 4,
	Canceled = 5,
	Break = 6,
	Travelling = 7,
	OnSite = 8
}