<!--uploader box-->
<div class="pc-uploader">

	<div class="upload-box">
		<div class="upload-box-content">

			<!--drop container-->
			<div [hidden]="uploading" class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
				<div class="inner">
					{{'Shared.Upload.DragText' | translate}}
					<input type="file" #uploader ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
				</div>
			</div>

			<!--files list-->
			<form [formGroup]="_form" *ngIf="!uploading && _form.get('items')['controls'].length > 0">
				<ul class="upload-file-list">
					<li formArrayName="items" *ngFor="let item of _form.get('items')['controls']; let i = index;">
						<div [formGroupName]="i">
							<div class="row">
								<div [class.col-lg-4]="showExpiryDate" [class.col-lg-8]="!showExpiryDate">
									<mat-form-field>
										<mat-label>Nome documento</mat-label>
										<input matInput formControlName="fileName" />
										<mat-error *ngIf="item.get('fileName').errors?.required && item.get('fileName').touched">
											{{'Commons.Validation.Required' | translate : { field: "Nome documento" } }}
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-lg-4">
									<mat-form-field class="filetype-select">
										<mat-label>Tipologia</mat-label>
										<mat-select formControlName="fileTypeId">
											<mat-option *ngIf="_filetypes && _filetypes.length > 1">Seleziona...</mat-option>
											<mat-option *ngFor="let _fileType of _filetypes" [value]="_fileType.id">{{_fileType.name}}</mat-option>
										</mat-select>
										<mat-error *ngIf="item.get('fileTypeId').errors?.required && item.get('fileTypeId').touched">
											{{'Commons.Validation.Required' | translate : { field: "Tipologia" } }}
										</mat-error>
									</mat-form-field>
								</div>
								<div class="col-lg-4" *ngIf="showExpiryDate">
									<mat-form-field>
										<mat-label>Data di scadenza</mat-label>
										<input matInput [matDatepicker]="datePicker_i" formControlName="expiryDate" class="expiry-datepicker" />
										<mat-datepicker-toggle matSuffix [for]="datePicker_i"></mat-datepicker-toggle>
										<mat-datepicker touchUi="true" #datePicker_i></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<div class="text-right m-t-30" *ngIf="_form.get('items')['controls'].length > 0">
					<button mat-raised-button color="accent" class="btn-block" type="button" (click)="startUpload()">
						<mat-icon>done</mat-icon>
						Conferma e carica documenti
					</button>
				</div>
			</form>

			<!--uploading progress-->
			<div *ngIf="uploading" class="upload-item--box">
				<div class="upload-item" *ngFor="let f of files; let i = index;">
					<div class="upload-item-content">
						<div class="filename">
							<div class="filename-left">
								<i class="ionicon ion-ios-copy"></i>
								<span>{{ f.name }}</span>
							</div>
							<div class="filename-right">
								<i class="ionicon ion-close-round" (click)="cancelUpload(f.id)"></i>
							</div>
						</div>
						<div class="progress-content">
							<div class="progress">
								<span class="bar" [style.width]="f?.progress?.data?.percentage + '%'" [class.is-done]="f?.progress?.data?.percentage === 100"></span>
							</div>
						</div>
						<div class="progress-text-content">
							<span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100">
								<span>{{ f.progress?.data?.percentage }}% </span>
								<span *ngIf="f.progress?.data?.percentage !== 100">{{'Shared.Upload.Uploading' | translate}}</span>
								<span *ngIf="f.progress?.data?.percentage === 100">{{'Shared.Upload.Done' | translate}}</span>
							</span>
							<span class="speed-and-eta-text" *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
								<span>{{ f.progress?.data?.speedHuman }} </span>
								<span>ETA {{ f.progress?.data?.etaHuman }}</span>
							</span>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>