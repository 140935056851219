<ng-container *ngIf="locationHistory && locationHistory.length > 0">

    <!--map-->
    <div class="navigation_map">
        <div #navigationMap
             (mousedown)="stopDragScroll($event)"
             (mousemove)="stopDragScroll($event)"
             (mouseup)="stopDragScroll($event)">
        </div>
    </div>

    <!--navigation slider-->
    <div class="navigation_slider" *ngIf="currentLocationIndex >= 0 && locationHistory[currentLocationIndex]">

        <!--slider-->
        <div class="navigation_slider_box" (mousedown)="stopDragScroll($event)">
            <button mat-icon-button (click)="navigationMapSliderPrev()">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <mat-slider #mapNavigationSlider [max]="locationHistory.length - 1">
                <input matSliderThumb (input)="navigationMapSliderChange()" [(ngModel)]="currentLocationIndex" />
            </mat-slider>
            <button mat-icon-button (click)="navigationMapSliderNext()">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>

        <!--current selection-->
        <div class="navigation_slider_current">
            <span>{{locationHistory[currentLocationIndex].timestamp | date: 'dd-MM-yyyy HH:mm:ss'}}</span>
            <span>&nbsp;-&nbsp;Velocità media: {{locationHistory[currentLocationIndex].speed | number: '1.0-0'}} km/h</span>
        </div>

    </div>
</ng-container>

<!--no location info-->
<div class="p-15 text-center" *ngIf="!locationHistory || locationHistory.length === 0">
    Nessuna informazione di posizione.
</div>

