<!--search filters-->
<div class="pc-form-filters">
	<form [formGroup]="searchForm" (ngSubmit)="search()">
		<div class="row">
			<div class="col-lg-4">
				<mat-form-field>
					<mat-label>Data</mat-label>
					<input matInput [matDatepicker]="worksheetDatePicker" formControlName="worksheetDate" placeholder="Data di riferimento" />
					<mat-datepicker-toggle matSuffix [for]="worksheetDatePicker"></mat-datepicker-toggle>
					<mat-datepicker touchUi="true" #worksheetDatePicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-lg-5">
				<mat-form-field>
					<mat-label>Operatore</mat-label>
					<mat-select class="block m-0" formControlName="operatorId">
						<mat-option *ngFor="let employee of employees" [value]="employee.key">
							{{ employee.value }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col d-flex align-items-center">
				<button type="submit" mat-raised-button color="accent" [disabled]="!searchForm.valid">
					<mat-icon>search</mat-icon>
					<span>{{'Commons.Actions.Search' | translate}}</span>
				</button>
			</div>
		</div>
	</form>
</div>

<!--activities list-->
<ul class="activities_list" *ngIf="_activities && _activities.length > 0">
	<li *ngFor="let _activity of _activities">
		<ul>
			<li>
				<strong>Orario:</strong>
				<span>{{_activity.scheduling | date:'HH:mm'}}</span>
			</li>
			<li>
				<strong *ngIf="_activity.entityTypeId === 1">Cliente:</strong>
				<strong *ngIf="_activity.entityTypeId === 2">Nota di lavoro:</strong>
				<span>{{_activity.customerBusinessName}}</span>
			</li>
			<li *ngIf="_activity.officeAddress">
				<strong>Indirizzo:</strong>
				<span>{{_activity.officeAddress}}</span>
			</li>
		</ul>
	</li>
</ul>

<!--no results-->
<alert *ngIf="_activities && _activities.length == 0" type="info">
	Nessun intervento per questa data e operatore.
</alert>